import { Checkbox, useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { entityData, topicData } from "../../../mock-data/retrievalUserDetails";
import { Autocomplete } from "../../common/AutoComplete";
import Button from "../../common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../common/Dialog";
import { Tab, Tabs } from "../../common/Tabs";
import TextButton from "../../common/TextButton";
import Typography from "../../common/Typography";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface Props {}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditRestrictedLabelsDialog = ({
  open,
  onClose,
  name,
  userLabels,
  onEditUserLabels,
  ...props
}) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("topic");
  const [labels, setLabels] = useState(topicData);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const styles = {
    dialogTitle: {
      fontSize: "18px",
    },
    dialogContent: {
      height: "300px",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  };

  const tabs = [
    {
      value: "topic",
      label: "Topics",
    },
    {
      value: "entity",
      label: "Entities",
    },
  ];

  useEffect(() => {
    if (userLabels) {
      setSelectedLabels(userLabels);
    }
  }, [userLabels]);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    //setActiveTabData(tabData.find((tab) => tab?.tabIndex === newValue) || {});
  };

  useEffect(() => {
    setLabels(activeTab === "topic" ? topicData : entityData);
  }, [activeTab]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle handleClose={onClose}>
        <Typography sx={styles.dialogTitle}>
          Restricted Labels for {name}
        </Typography>
        <Typography color={theme.palette.surface50.main} variant="caption">
          Labels chosen will be moved to {name}’s restricted label list.
        </Typography>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          {tabs.map((tab: { label: string }, index: number) => (
            <Tab {...tab} key={index} />
          ))}
        </Tabs>

        <Autocomplete
          label="Type"
          variant="standard"
          multiple
          options={labels}
          value={selectedLabels}
          getOptionLabel={(option) => option}
          onChange={(e: any, newValue: any) => {
            if (newValue) setSelectedLabels(newValue);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option}
            </li>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onEditUserLabels(selectedLabels);
            onClose();
          }}
        >
          Update
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRestrictedLabelsDialog;
