import { useTheme } from "@mui/material";
import MUITable, { TableProps } from "@mui/material/Table";
import MUITableBody, { TableBodyProps } from "@mui/material/TableBody";
import MUITableCell, { TableCellProps } from "@mui/material/TableCell";
import MUITableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import MUITableHead, { TableHeadProps } from "@mui/material/TableHead";
import MUITableRow, { TableRowProps } from "@mui/material/TableRow";

export const Table = (props: TableProps) => {
  return <MUITable stickyHeader {...props} />;
};
export const TableContainer = (props: TableContainerProps) => {
  return <MUITableContainer {...props} />;
};

export const TableBody = ({ sx, ...props }: TableBodyProps) => {
  const theme = useTheme();
  const styles = {
    tableBody: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: theme.palette.surface0.main,
      },
    },
  };
  return <MUITableBody sx={{ ...styles.tableBody, ...sx }} {...props} />;
};

export const TableHead = (props: TableHeadProps) => {
  const theme = useTheme();
  const styles = {
    tableHead: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        zIndex: 1,
        background: theme.palette.surface10.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
      },
    },
  };
  return <MUITableHead sx={styles.tableHead} {...props} />;
};

export const TableRow = ({ selected, sx, ...otherProps }: TableRowProps) => {
  const theme = useTheme();
  const styles = {
    tableRow: {
      "&.MuiTableRow-root": {
        backgroundColor: selected ? theme.palette.surface0.main : null,
        verticalAlign: "top",
      },
      "&.MuiTableRow-root .MuiTableCell-root:first-child": {
        borderLeft: `1px solid ${
          selected ? theme.palette.primaryBlue.main : "transparent"
        }`,
      },
    },
  };

  return <MUITableRow sx={{ ...styles.tableRow, ...sx }} {...otherProps} />;
};

interface TableCellCustomProps extends TableCellProps {
  borderBottom?: any;
}

export const TableCell = ({
  sx,
  borderBottom,
  ...props
}: TableCellCustomProps) => {
  const theme = useTheme();

  const styles = {
    tableCell: {
      "&.MuiTableCell-head": {
        paddingY: "6px",
      },
      "&.MuiTableCell-root": {
        paddingX: theme.spacing(2),
        border: "none",
      },
    },
  };

  return <MUITableCell sx={{ ...styles.tableCell, ...sx }} {...props} />;
};
