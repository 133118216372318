import { useEffect } from "react";
import CheckListComponent from "../../components/checklist/index";

const CheckList = ({}) => {
  useEffect(() => {
    document.title = "Daxa AI - Checklist";
  }, []);
  return (
    <div>
      <CheckListComponent />
    </div>
  );
};

export default CheckList;
