import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import integrationIcon from "../../../assets/img/icons/settings/integration.svg";
import { updateSlackIntegrationAsync } from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import Button from "../../common/Button";
import Box from "../../common/Box";

// Functional component for Add Integration.
const UpdateSlackIntegrationModal = ({
  handleModal,
  refreshIntegrations,
  card,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);

  // Formik
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter name."),
    }),
    onSubmit: (values) => {
      updateIntegration({ ...values });
    },
  });

  useEffect(() => {
    if (card) {
      formik.setFieldValue("name", card.name);
    }
  }, [card]);

  const handleCancel = () => {
    handleModal(false);
  };

  const updateIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      updateSlackIntegrationAsync({
        name: values.name,
        id: card._id,
      })
    );
    if (resp && resp.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "Integration updated successfully.");
      setShowLoader(false);
      refreshIntegrations();
      handleModal(false);
    } else {
      // Toaster(TOASTER_TYPES.ERROR, "Failed to add Integration.");
      setShowLoader(false);
      setConnectionFailed(true);
    }
  };

  useEffect(() => {}, []);

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <img src={integrationIcon} className="mr-3" />
                <span>Update Slack Integration</span>
              </div>
              <div className="modal-body">
                {showLoader && (
                  <img
                    src="static/img/loader-circle.svg"
                    className="modal-loading-img"
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div className="row">
                    <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                      <b className="form-field-name ">Name</b>
                      <span className="mandatory-icon">*</span>
                    </div>
                    <div className="col col-lg-6">
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter Name"
                        name="name"
                        maxLength={50}
                        className="modal-input-style px-2"
                        onChange={(e) => {
                          formik.setFieldValue("name", e.target.value);
                        }}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                      />

                      {formik.errors.name && formik.touched.name && (
                        <div className="error-message">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                      <b className="form-field-name ">Token</b>
                      <span className="mandatory-icon">*</span>
                    </div>
                    <div className="col col-lg-6">
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter Name"
                        name="name"
                        maxLength={50}
                        className="modal-input-style px-2"
                        onChange={(e) => {
                          formik.setFieldValue("name", e.target.value);
                        }}
                        value={"**************************************"}
                        onBlur={formik.handleBlur}
                      />

                      {formik.errors.name && formik.touched.name && (
                        <div className="error-message">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                      <b className="form-field-name ">Channel</b>
                      <span className="mandatory-icon">*</span>
                    </div>
                    <div className="col col-lg-6">
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter Name"
                        name="name"
                        maxLength={50}
                        className="modal-input-style px-2"
                        onChange={(e) => {
                          formik.setFieldValue("channel", e.target.value);
                        }}
                        value={"general"}
                        onBlur={formik.handleBlur}
                      />

                      {formik.errors.name && formik.touched.name && (
                        <div className="error-message">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 offset-md-3 pl-0 text-danger">
                      Something went wrong. Please try again.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Submit
                </Button>

                <span className="separator mx-3"></span>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateSlackIntegrationModal;
