import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchAssetsStats,
  fetchIdentitiesStats,
  fetchDatastoreStats,
  fetchIssueUberScore,
  fetchFirstAccountStatStatus,
  fetchIssuesStats,
  fetchRiskPlot,
  fetchDashboardStats,
  fetchDataFlows,
  fetchAchievements,
  fetchTrends,
  fetchDataNewFlows,
  manageDashboardView,
  fetchDatastoreRegions,
  fetchCloudIssues,
  fetchPolicyViolationFilters,
  fetchPolicyViolations,
  fetchFrameworkDetailsData,
  fetchFrameworkData,
} from "../apis/dashboardAPI";

const firstAccountStats = {
  cdRiskLabel: "LOW",
  cdRiskScore: 0,
  description: null,
  first_acct_classification_complete: null,
  first_acct_risk_complete: null,
};

const initialState = {
  datastoreStats: null,
  issuesStats: null,
  assetsStats: null,
  identitiesStats: null,
  riskPlot: null,
  dataflow: null,
  issueUberScore: null,
  sidebar: JSON.parse(localStorage.getItem("sidebarExpand")),
  dashboardIssueView: JSON.parse(localStorage.getItem("dashboardIssueView")),
  firstAccountStatus: firstAccountStats,
  isIssueViewVisible: JSON.parse(localStorage.getItem("view")),
  promptFilter: "week",
  apps: [],
};

// Async calls.
export const listDatastoreStats = createAsyncThunk(
  "dashboard/datastoreStats",
  async (params) => {
    const response = await fetchDatastoreStats(params);
    return response.data;
  }
);

export const listIssuesStats = createAsyncThunk(
  "dashboard/issuesStats",
  async (params) => {
    const response = await fetchIssuesStats(params);
    return response.data;
  }
);

export const listAssetsStats = createAsyncThunk(
  "dashboard/assetsStats",
  async (params) => {
    const response = await fetchAssetsStats(params);
    return response.data;
  }
);

export const listIdentitiesStats = createAsyncThunk(
  "dashboard/identitiesStats",
  async (params) => {
    const response = await fetchIdentitiesStats(params);
    return response.data;
  }
);

export const listRiskPlot = createAsyncThunk(
  "dashboard/riskplot",
  async (params) => {
    const response = await fetchRiskPlot(params);
    return response.data;
  }
);

export const firstAccountStatStatus = createAsyncThunk(
  "dashboard/firstAccountStatStatus",
  async (params) => {
    const response = await fetchFirstAccountStatStatus(params);
    return response.data;
  }
);

export const fetchDashboardStat = createAsyncThunk(
  "dashboard/fetchDashboardStat",
  async (params) => {
    const response = await fetchDashboardStats(params);
    return response.data;
  }
);

export const fetchDataFlowsSlice = createAsyncThunk(
  "dashboard/dataflow",
  async (params) => {
    const response = await fetchDataFlows(params);
    return response.data;
  }
);

export const fetchAchievementStats = createAsyncThunk(
  "dashboard/achievements",
  async (params) => {
    const response = await fetchAchievements(params);
    return response.data;
  }
);

export const fetchTrendsStats = createAsyncThunk(
  "dashboard/achievements",
  async (params) => {
    const response = await fetchTrends(params);
    return response.data;
  }
);

export const fetchDataFlowsNewSlice = createAsyncThunk(
  "dashboard/dataflowNew",
  async (params) => {
    const response = await fetchDataNewFlows(params);
    return response.data;
  }
);

export const fetchIssueUberScoreSlice = createAsyncThunk(
  "dashboard/issueUberScore",
  async (params) => {
    const response = await fetchIssueUberScore(params);
    return response.data;
  }
);

export const manageDashboardViewSlice = createAsyncThunk(
  "dashboard/issueUberScore",
  async (params) => {
    const response = await manageDashboardView(params);
    return response.data;
  }
);

export const fetchDatastoreRegionsSlice = createAsyncThunk(
  "dashboard/regions",
  async (params) => {
    const response = await fetchDatastoreRegions(params);
    return response.data;
  }
);

export const fetchCloudIssuesSlice = createAsyncThunk(
  "dashboard/cloud",
  async (params) => {
    const response = await fetchCloudIssues(params);
    return response.data;
  }
);

export const fetchPolicyViolationFiltersSlice = createAsyncThunk(
  "dashboard/policyviolations/filter",
  async (params) => {
    const response = await fetchPolicyViolationFilters(params);
    return response.data;
  }
);

export const fetchPolicyViolationsSlice = createAsyncThunk(
  "dashboard/policyviolations/filter",
  async (params) => {
    const response = await fetchPolicyViolations(params);
    return response.data;
  }
);

export const fetchFrameworkSlice = createAsyncThunk(
  "dashboard/frameworks",
  async (params) => {
    const response = await fetchFrameworkData(params);
    return response.data;
  }
);

export const fetchFrameworkDetailsSlice = createAsyncThunk(
  "dashboard/frameworks/details",
  async (params) => {
    const response = await fetchFrameworkDetailsData(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    expandSidebar: (state) => {
      state.sidebar = true;
      localStorage.setItem("sidebarExpand", true);
      console.log("====== Showing sidebar =====");
    },
    minimizeSidebar: (state) => {
      state.sidebar = false;
      localStorage.setItem("sidebarExpand", false);
    },
    issueViewVisible: (state) => {
      state.isIssueViewVisible = true;
      localStorage.setItem("view", true);
    },

    issueViewDisable: (state) => {
      state.isIssueViewVisible = false;
      localStorage.setItem("view", false);
    },
    setPromptTimeFilter: (state, action) => {
      const filter = action.payload;
      state.promptFilter = filter;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listDatastoreStats.fulfilled, (state, action) => {
        state.datastoreStats = action.payload;
      })
      .addCase(listDatastoreStats.rejected, (state) => {
        state.datastoreStats = {};
      })
      .addCase(listIssuesStats.fulfilled, (state, action) => {
        state.issuesStats = action.payload;
      })
      .addCase(listIssuesStats.rejected, (state) => {
        state.issuesStats = {};
      })

      .addCase(listAssetsStats.fulfilled, (state, action) => {
        state.assetsStats = action.payload;
      })
      .addCase(listAssetsStats.rejected, (state) => {
        state.assetsStats = {};
      })

      .addCase(listIdentitiesStats.fulfilled, (state, action) => {
        state.identitiesStats = action.payload;
      })
      .addCase(listIdentitiesStats.rejected, (state) => {
        state.identitiesStats = {};
      })

      .addCase(listRiskPlot.fulfilled, (state, action) => {
        state.riskPlot = action.payload;
      })
      .addCase(listRiskPlot.rejected, (state) => {
        state.riskPlot = {};
      })
      .addCase(fetchDataFlowsSlice.fulfilled, (state, action) => {
        state.dataflow = action.payload;
      })
      .addCase(fetchDataFlowsSlice.rejected, (state) => {
        state.dataflow = {};
      })
      .addCase(fetchIssueUberScoreSlice.fulfilled, (state, action) => {
        state.issueUberScore = action.payload;
      })
      .addCase(fetchIssueUberScoreSlice.rejected, (state) => {
        state.issueUberScore = {};
      })
      .addCase(firstAccountStatStatus.fulfilled, (state, action) => {
        // let test = { ...action.payload };
        // test.first_acct_classification_complete =
        //   action.payload.first_acct_classification_complete;
        // test.first_acct_classification_complete = null;
        // test.first_acct_risk_complete = null;
        // state.firstAccountStatus = test;

        state.firstAccountStatus = action?.payload?.err
          ? firstAccountStats
          : action.payload;
      })
      .addCase(firstAccountStatStatus.rejected, (state) => {
        state.firstAccountStatus = firstAccountStats;
      })
      .addCase(fetchFrameworkSlice.fulfilled, (state, action) => {
        state.apps = action?.payload?.data || [];
      });
  },
});
export const {
  expandSidebar,
  minimizeSidebar,
  issueViewDisable,
  issueViewVisible,
  setPromptTimeFilter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
