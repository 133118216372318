import { useTheme } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import Button from "../common/Button";
import TextButton from "../common/TextButton";
const DiscardLabelDialog = ({ open, onClose, onEdit, onDiscard }) => {
  const theme = useTheme();
  const styles = {};
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle handleClose={onClose}>
        Would you like to edit or discard your suggestions?
      </DialogTitle>
      <DialogContent
        sx={{
          paddingY: theme.spacing(4),
        }}></DialogContent>
      <DialogActions>
        <TextButton onClick={onClose}>Cancel</TextButton>
        <Button variant="outlined" color="error" onClick={onDiscard}>
          Discard
        </Button>
        <Button variant="contained" onClick={onEdit}>
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DiscardLabelDialog;
