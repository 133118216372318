export const frameworkData = [
  {
    name: "AcmeMortgageApp-1",
    policyViolations: 3,
    sensitiveMessages: 37,
    activeUsers: 3,
    retrievals: 37,
    labels: ["PII - SSN", "PII - CreditCard", "Intellectual Property", "Bias"],
    version: "0.2.16",
  },
  {
    name: "SupportApp-1",
    policyViolations: 1,
    sensitiveMessages: 36,
    activeUsers: 1,
    retrievals: 24,
    labels: ["ProductRoadmap"],
    version: "0.2.12",
  },
  {
    name: "HealthCareApp-1",
    policyViolations: 2,
    sensitiveMessages: 41,
    activeUsers: 1,
    retrievals: 12,
    labels: ["PHI - MRN", "MedicalAdvice"],
    version: "0.2.14",
  },
  {
    name: "ProductInsights",
    policyViolations: 1,
    sensitiveMessages: 41,
    activeUsers: 3,
    retrievals: 12,
    labels: ["ProductRoadmap"],
    version: "0.2.13",
  },
];

export const totalSensitiveMessageCount = 250;
export const totalActiveUsers = 5;
export const totalRetrievals = 100;
export const totalPolicyViolationCount = 9;

export const modelData = [
  {
    assetId:
      "projects:673251266950:locations:us-central1:models:2229534703222784000",
    policyViolations: 3,
  },
  {
    assetId:
      "arn:aws:sagemaker:us-east-1:099873896856:endpoint/hf-llm-falcon-7b-bf16",
    policyViolations: 4,
  },
  {
    assetId: "arn:aws:ec2:us-east-1:099873896856:instance/i-05f66db16bae61bb0",
    policyViolations: 1,
  },
  {
    assetId: "arn:aws:ec2:us-east-1:099873896856:instance/i-081f271c3fdd6a72e",
    policyViolations: 2,
  },
  {
    assetId: "arn:aws:ec2:us-east-1:099873896856:instance/i-04b488e9f94a0d175",
    policyViolations: 0,
  },
  {
    assetId:
      "arn:aws:sagemaker:us-east-1:099873896856:training-job/smjs-c-hf-llm-falcon-7b-bf16-20230727-013639",
    policyViolations: 4,
  },
];
