import { useEffect } from "react";
import Box from "./Box";
import * as d3 from "d3";
import Typography from "./Typography";
import { useTheme } from "@mui/material";
import Divider from "./Divider";
import { retrievalUserDetails } from "../../mock-data/retrievalUserDetails";
import { getStringFromArray } from "../../utils/commonUtils";

export interface TreeMapData {
  selectedUser: any;
  setSelectedUser: any;
  selectedFilter: any;
  setSelectedFilter: any;
  data: Array<{ name: string; parent: string; value: string }>;
  selectedTab: number;
  maxTwoValue: any;
  appName: string;
}

const TreeMap = ({
  selectedUser,
  setSelectedUser,
  selectedFilter,
  setSelectedFilter,
  data,
  selectedTab,
  maxTwoValue,
  appName,
}: TreeMapData) => {
  const theme = useTheme();
  const styles = {
    graphContainer: {
      width: "100%",
      height: "240px",
    },
    graphFooterContainer: {
      paddingTop: theme.spacing(1),
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    divider: {
      height: "13px",
    },
    minMax: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    colorIndicator: {
      height: "4px",
      width: "300px",
      backgroundImage: "linear-gradient(to right, #8C5DFA , #421F96)",
    },
    graphFooter: {},
  };

  var root = d3
    .stratify()
    .id(function (d: any) {
      return d.name;
    })
    .parentId(function (d: any) {
      return d.parent;
    })(data);

  root.sum(function (d: any) {
    return +d.value;
  });

  const values = data?.map((d) => (d?.value ? Number(d?.value) : NaN));
  const minValue = d3.min(values);
  const [min, max] = d3.extent(values);

  const colorScale = d3
    .scaleLinear()
    .domain([min, max] as any)
    .range(["#421F96", "#8C5DFA"] as any);

  const drawTreeMap = () => {
    const element = d3.selectAll(".treeMapContainer");
    element.selectAll("svg").remove();
    var margin = { top: 10, right: 0, bottom: 0, left: 0 },
      height = 200 - margin.top - margin.bottom;
    const parentWidth =
      document.getElementById("treemap-container")?.clientWidth || 1000;
    const width = parentWidth - margin.left - margin.right;

    var svg = d3
      .select("#treemap-container")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    d3.selectAll(".treemap-tooltip").remove();

    var tooltip = d3
      .select("#treemap-container")
      .append("div")
      .style("opacity", 0)
      .attr("class", "treemap-tooltip")
      .style("border-radius", "5px")
      .style("color", "white")
      .style("position", "absolute");

    d3.treemap().size([width, height]).padding(1)(root);

    var showTooltip = function (event, d, applyStyle = true) {
      if (applyStyle) {
        d3.select(event.target).attr("rx", 6).attr("ry", 6);
        d3.select(event.target).style("stroke", "white");
      }
      const xPos = event.clientX;
      const yPos = event.clientY;
      const userLength = d.data.user?.length || 0;
      let files = 0;

      const localLabels = localStorage.getItem("restrictedLabels");
      let retrievalUsers = localLabels ? JSON.parse(localLabels) : {};
      const name = d.data?.name;

      let labels = [];
      if (retrievalUsers[name]?.frameworkRestrictedLabels) {
        labels = retrievalUsers[name].frameworkRestrictedLabels[appName] || [];
      } else {
        labels =
          retrievalUserDetails.find((detail) => detail?.name === d.data.name)
            ?.frameworkRestrictedLabels[appName] || [];
      }
      if (selectedTab === 1 && d.data && d.data.files) {
        files = d.data?.files;
      }
      tooltip.transition().duration(200);
      tooltip
        .style("opacity", 1)
        .style("display", "block")
        .html(
          /*html*/ `<div class="treemap-tooltip">
            <div class="treemap-header">
            <div class="treemap-tooltip-file">${d.data.name}</div>
            <div style="font-size:19px">${d.data.value}</div>
            <div class="treemap-tooltip-content">Retrieval Count</div>
            <hr class="treemap-divider" />
            <div class="treemap-tooltip-content">${
              selectedTab === 1
                ? `Accessed <span class="tooltip-red">${
                    labels?.length > 0
                      ? getStringFromArray(
                          [...labels],
                          labels?.length >= 2 ? 2 : 1
                        )
                      : 0
                  }</span> topics.`
                : ""
            }</div>
            </div>
        <div>`
        )
        .style("left", xPos + 15 + "px")
        .style("top", yPos + "px");
    };

    const showTooltipForText = function (target, event, d) {
      d3.select(target).attr("rx", 6).attr("ry", 6);
      d3.select(target).style("stroke", "white");

      showTooltip(event, d, false);
    };

    var hideTooltip = function (event, d) {
      if (selectedUser !== d.id) {
        d3.select(event.target).attr("rx", 0).attr("ry", 0);
        d3.select(event.target).style("stroke", "none");
      }
      tooltip
        .transition()
        .duration(200)
        .style("opacity", 0)
        .style("display", "none");
    };

    var moveTooltip = function (event, d) {
      const xPos = event.clientX;
      const yPos = event.clientY;
      tooltip
        .style("left", xPos + 15 + "px")
        .style("top", yPos + "px")
        .style("display", "block");
    };

    const rects = svg
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("rect")
      .attr("id", (d: any) => `rect-${d.data.id}`)
      .attr("class", (d: any) =>
        Number(d.data.value) >= Number(maxTwoValue)
          ? "rect-text"
          : "rect-no-text"
      )
      .attr("x", function (d: any) {
        return d.x0;
      })
      .attr("y", function (d: any) {
        return d.y0;
      })
      .attr("width", function (d: any) {
        return d.x1 - d.x0;
      })
      .attr("height", function (d: any) {
        return d.y1 - d.y0;
      })
      .style("stroke", "black")
      .style("fill", (d: any) => colorScale(d.data.value))
      .on("mouseover", showTooltip)
      .on("mouseleave", hideTooltip)
      .on("mousemove", moveTooltip)
      .on("click", (event, d) => {
        if (d?.id === selectedUser) {
          setSelectedUser("");
          setSelectedFilter("");
        } else {
          setSelectedUser(d?.id);
          if (selectedTab === 1) {
            setSelectedFilter("user");
          } else {
            setSelectedFilter("file");
          }
        }
      });

    // and to add the text labels
    const texts = svg
      .selectAll("rect-text")
      .data(
        Object.values(root.leaves())?.filter(
          (data) => Number(data?.value) >= Number(maxTwoValue)
        )
      )
      .enter()
      .append("foreignObject")
      .on("mouseover", (event, d: any) => {
        const element = document.getElementById(`rect-${d.data.id}`);
        showTooltipForText(element, event, d);
      })
      //.on("mouseleave", hideTooltip)
      .on("mousemove", moveTooltip)
      .on("click", (event, d) => {
        if (d?.id === selectedUser) {
          setSelectedUser("");
          setSelectedFilter("");
        } else {
          setSelectedUser(d?.id);
          if (selectedTab === 1) {
            setSelectedFilter("user");
          } else {
            setSelectedFilter("file");
          }
        }
      })
      .attr("x", function (d: any) {
        return d.x0 + 10;
      }) // +10 to adjust position (more right)
      .attr("y", function (d: any) {
        return d.y0 + 10;
      }) // +20 to adjust position (lower)
      .attr("width", 200)
      .attr("height", 90)
      .append("xhtml:p")
      .style("fill", "black")
      .html((d: any) =>
        d.data.value >= Number(maxTwoValue)
          ? /*html*/ `<div class="treemap-rect">
        <div class="treemap-rect-header">
          ${d.data.violation ? '<div class="treemap-error-badge"></div>' : ""}
          <div>${
            selectedTab === 0 ? d.data?.name?.split("/")?.at(-1) : d.data.name
          }</div>
        </div>
        <div class="treemap-rect-value">${`${d.data.value <= 9 ? "0" : ""}${
          d.data.value
        }`}</div>
      </div>`
          : ""
      );
  };

  useEffect(() => {
    if (data?.length > 0) {
      drawTreeMap();
    }
  }, [data, selectedUser]);

  return (
    <Box sx={styles.graphContainer}>
      <div id="treemap-container" className="treeMapContainer"></div>
      <Box sx={styles.graphFooterContainer}>
        <Typography
          variant="caption"
          color={theme.palette.surface40.main}
          sx={styles.graphFooter}
        >
          Rectangle size & color represents the number of retrievals
        </Typography>
        <Divider orientation="vertical" sx={styles.divider} />
        <Box sx={styles.minMax}>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Max
          </Typography>
          <Box sx={styles.colorIndicator}></Box>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Min
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TreeMap;
