import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { getDataStoresApi, getDataStoresApiV2 } from "../../apis/index.js";
import NoDataContainer from "../../components/cards/no-data/index.jsx";
import Cards from "../../components/datastore/cards";
import Table from "../../components/datastore/table";
import Pagination from "../../components/pagination/Pagination";
import { LOADER_CONFIG, REACT_APP_DATASTORE_API } from "../../utils/Constants";
import { addIndex } from "../../utils/dataHandler.js";
import { useLocation, useParams } from "react-router-dom";
import PolicyDetails from "../../components/datastore/policy/PolicyDetails.jsx";
import { useDispatch } from "react-redux";
import { getBulkTicketAsync } from "../../redux/slices/ticketSlice";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
import {
  getCustomerId,
  getSelectedDatastoreAccount,
  setSelectedDatastoreAccount,
} from "../../utils/SessionHelper";
import "./datastoreView.scss";
import SensitiveData from "../../components/datastore/dataInventory/sensitive-data.jsx";
import DataOverview from "../../components/datastore/dataInventory/data-overview.jsx";
import ShadowSensitiveData from "../../components/datastore/dataInventory/ShadowSensitiveData.jsx";
import ShadowDataOverview from "../../components/datastore/dataInventory/ShadowDataOverview.jsx";
import ShadowDataPieChart from "../../components/datastore/dataInventory/ShadowDataPieChart.jsx";
import MosstUsedDataStores from "../../components/datastore/dataInventory/MostUsedDataStores.jsx";
import { DATASTORE } from "../../routes/constants";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";

const Datastore = ({ setPath, tab, selectedAccount }) => {
  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const limit = 10;
  const params = useParams();
  const filterName = params.filterName;
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [inputText, setInputText] = useState("");
  const [currentPosts, setCurrentPosts] = useState([]);
  const [severity, setSeverity] = useState(filterName ? filterName : null);
  const [sensitiveFilter, setSensitiveFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("cdDatastoreRiskScore");
  const [sortType, setSortType] = useState("desc");
  const [totalDatastoresCount, setTotalDatastoresCount] = useState(0);
  const [search, setSearch] = useState(null);
  const [ticketDetail, setTicketDetail] = useState();
  const policyViolationId = params.policyViolationId;
  const dispatch = useDispatch();
  const [shadowAIFilter, setShadowAIFilter] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const location = query.get("region");
  const [queryParams, setQueryParams] = useState({});
  const locationPath = useLocation();

  const shadowDemoData = [
    {
      cdDatastoreRiskScore: 0,
      name: "cerebras/Cerebras-GPT-590M",
      administrator: null,
      type: "llm",
      "metadata.cloudAccountId": "099873896856",
      "metadata.createdAt": "2023-07-28 11:02:30.720000",
      size: "4.5389 GB",
      mountLocation: "/data/home/model_data",
      datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
      assetId:
        "arn:aws:ec2:us-east-1:099873896856:instance/i-081f271c3fdd6a72e",
      attackstatus: false,
      indirectAttackStatus: false,
      sensitiveInfo: [
        { count: 2079, name: "social_security_numbers" },
        { count: 10012, name: "credit_cards" },
        { count: 16349, name: "drivers_licenses" },
        { count: 27250, name: "mobile_numbers" },
      ],
      datastoreType: "shadow",
    },
    {
      cdDatastoreRiskScore: 0.0,
      name: "falcon-7b-bf16",
      administrator: null,
      type: "llm",
      "metadata.cloudAccountId": "099873896856",
      "metadata.createdAt": "2023-07-13 16:15:06.168000",
      size: "14.69 GB",
      mountLocation: "",
      datastoreId: "25c33b99-7719-4444-5555-ba4ee28539e3",
      assetId:
        "arn:aws:ec2:ap-northeast-2:099873896856:instance/i-03dfaeb24d515251f",
      attackstatus: false,
      indirectAttackStatus: false,
      sensitiveInfo: [
        { count: 2079.0, name: "social_security_numbers" },
        { count: 10012.0, name: "credit_cards" },
        { count: 16349.0, name: "drivers_licenses" },
        { count: 27250.0, name: "mobile_numbers" },
      ],
      datastoreType: "managed",
    },
    {
      cdDatastoreRiskScore: 0,
      name: "gpt2",
      administrator: null,
      type: "llm",
      "metadata.cloudAccountId": "099873896856",
      "metadata.createdAt": "2023-07-28 11:11:53.400000",
      size: "1.021 GB",
      mountLocation: "/data/home/model_data",
      datastoreId: "7cd5c0fa-4321-4f89-bd9b-e9979b823c30",
      assetId:
        "arn:aws:ec2:us-east-1:099873896856:instance/i-05f66db16bae61bb0",
      attackstatus: false,
      indirectAttackStatus: false,
      sensitiveInfo: [
        { count: 1526, name: "social_security_numbers" },
        { count: 28081, name: "credit_cards" },
        { count: 27566, name: "drivers_licenses" },
        { count: 25019, name: "mobile_numbers" },
      ],
      datastoreType: "self-managed",
    },
    {
      cdDatastoreRiskScore: 0,
      name: "gpt2",
      administrator: null,
      type: "llm",
      "metadata.cloudAccountId": "099873896856",
      "metadata.createdAt": "2023-07-13 16:18:20.576000",
      size: "1.021 GB",
      mountLocation: "/data/home/model_data",
      datastoreId: "ff4cda8a-8662-4d1f-b064-01175974f84a",
      assetId:
        "arn:aws:ec2:us-east-1:099873896856:instance/i-04b488e9f94a0d175",
      attackstatus: false,
      indirectAttackStatus: false,
      sensitiveInfo: [
        { count: 1204, name: "social_security_numbers" },
        { count: 35049, name: "credit_cards" },
        { count: 63818, name: "drivers_licenses" },
        { count: 3351, name: "mobile_numbers" },
      ],
      datastoreType: "self-managed",
    },
  ];

  const handleSensitiveFilter = (val) => {
    setSensitiveFilter(val);
    setSeverity("sensitive");
  };

  const handleSeverity = (type) => {
    if (type === severity) {
      if (filterName === "SHADOW_AI") {
        setSeverity("AI");
      } else {
        setSeverity("");
      }
    } else {
      setSensitiveFilter(null);
      if (shadowAIFilter && type === "SHADOW_AI") {
        setSeverity("AI");
      } else {
        setSeverity(type);
      }
    }
    setPage(1);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");
    const searchQuery = params.get("search");
    if (searchQuery) {
      setSearch(searchQuery);
    }
    if (tab) {
      setSeverity(tab);
    }
    setQueryParams({ tab, searchQuery });
  }, [locationPath]);

  useEffect(() => {
    document.title = "Daxa AI - Datastores";
    setPath(window.location.pathname);
  }, []);

  const availableKeywords = [
    "cddatastoreriskscore",
    "score",
    "riskScore",
    "name",
    "type",
    "mountlocation",
    "location",
    "size",
  ];
  const getSearchField = (keyword, field, search) => {
    if (availableKeywords.includes(keyword.toLowerCase())) {
      return field;
    } else {
      return search;
    }
  };
  const getKeywordField = (keyword) => {
    if (availableKeywords.includes(keyword.toLowerCase())) {
      return keyword;
    } else {
      return null;
    }
  };

  const getDatastores = async () => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");
    const searchQuery = params.get("search");
    setLoading(true);
    const cloudAccountId =
      selectedAccount.value === "all" ? null : selectedAccount.value;

    let payload = {
      customerId: getCustomerId(),
      limit: limit,
      page: page,
      cloudAccountId: cloudAccountId,
      ordering: sortField,
      severity:
        tab ||
        queryParams.tab ||
        (!severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.replaceAll(" ", "_")),
      policyViolationId: policyViolationId ? policyViolationId : null,
    };
    if (location) {
      payload.location = location;
    }
    const search_ =
      search && search.includes(":")
        ? getSearchField(search.split(":")[0], search.split(":")[1], search)
        : search;
    const keyword_ =
      search && search.includes(":")
        ? getKeywordField(search.split(":")[0])
        : null;

    if (REACT_APP_DATASTORE_API === "OPENSEARCH") {
      let severity_ =
        !severity || severity.includes("TOTAL DATA STORES")
          ? null
          : severity.includes("SENSITIVE")
          ? "sensitive"
          : severity.replaceAll(" ", "_");
      payload = {
        customerId: getCustomerId(),
        q: searchQuery || queryParams.searchQuery || search_,
        dataset: "datastores",
        exact: false,
        page: page,
        limit: limit,
        ordering: sortField,
        sortType: sortType,
        cloudAccountId: cloudAccountId,
        severity: severity_ || tab || queryParams.tab,
        sensitiveFilter: sensitiveFilter,
        policyViolationId: policyViolationId ? policyViolationId : null,
      };

      if (location) {
        payload.location = location;
      }
      if (keyword_) {
        payload.keyword = keyword_;
      }
    }

    let res =
      REACT_APP_DATASTORE_API === "OPENSEARCH"
        ? await getDataStoresApiV2(payload)
        : await getDataStoresApi(payload);

    if (res.err) {
      setLoading(false);
      return;
    }

    if (!res.data) {
      setLoading(false);
    } else {
      setTotalDatastoresCount(res.count);
      res = addIndex(res.data);
      if (filterName === "SHADOW_AI" && severity !== "SHADOW_AI") {
        //custom frontend filter function
        getFilteredData(res);
      } else {
        setCurrentPosts(res);
      }
      setLoading(false);
    }
  };

  const getFilteredData = () => {
    switch (severity) {
      case "VECTOR_DB":
        setCurrentPosts([]);
        setTotalDatastoresCount(0);

        break;
      case "LLMs":
        setCurrentPosts(
          shadowDemoData.filter(
            (data) =>
              data.datastoreType === "managed" ||
              data.datastoreType === "self-managed"
          )
        );
        setTotalDatastoresCount(3);
        break;
      case "sensitive_data":
        setCurrentPosts(shadowDemoData);
        setTotalDatastoresCount(shadowDemoData.length);
        break;
      default:
        setTotalDatastoresCount(shadowDemoData.length);
        setCurrentPosts(shadowDemoData);
    }
  };

  const sortData = (field) => {
    if (field === sortField && sortType === "asc") {
      setSortType("desc");
    } else if (field === sortField && sortType === "desc") {
      setSortType("asc");
    } else {
      setSortType("desc");
    }

    setSortField(field);
    setPage(1);
  };

  const getBulkTicketDetail = async () => {
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policyViolationId ? policyViolationId : null,
      host: window.location.host,
    };
    const resp = await dispatch(getBulkTicketAsync(payload));
    if (resp && resp.payload) {
      setTicketDetail(resp.payload);
    } else {
      setTicketDetail();
    }
  };

  useEffect(() => {
    setPage(1);
    setSelectedDatastoreAccount(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    if (
      page ||
      selectedAccount ||
      sortField ||
      severity ||
      sortType ||
      sensitiveFilter ||
      queryParams.tab ||
      queryParams.searchQuery
    )
      getDatastores();
  }, [
    page,
    selectedAccount,
    sortField,
    severity,
    sortType,
    sensitiveFilter,
    queryParams,
  ]);

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  const constructPathAndRedirect = () => {
    //console.log(window.location.pathname.split("/"));
    //if (window.location.pathname.split("/")?.length === 2) {
    let baseURL = `${DATASTORE}?`;
    if (severity) {
      baseURL += `tab=${severity}&`;
    }
    if (search) {
      baseURL += `search=${search}`;
    }
    history.push(baseURL);
    //  }
  };

  const makeSearch = (ev) => {
    ev.preventDefault();
    constructPathAndRedirect();
    getDatastores();
  };

  useEffect(() => {
    if (severity || severity === "") {
      constructPathAndRedirect();
    }
  }, [severity]);

  const refreshDatastores = () => {
    getDatastores();
  };

  // useEffect(() => {
  //   return () => {
  //     sessionStorage.removeItem("policy name");
  //     sessionStorage.removeItem("policy id");
  //   };
  // }, []);

  useEffect(() => {
    if (policyViolationId) {
      getBulkTicketDetail();
    }
  }, [policyViolationId]);

  const toggleShadowAIFilter = () => {
    if (!shadowAIFilter) {
      setShadowAIFilter(true);
      setSeverity("AI");
    }
    if (shadowAIFilter) {
      setShadowAIFilter(false);
      setSeverity("SHADOW_AI");
    }
  };

  return (
    <div id="datastores">
      <div className="row align-items-center">
        <div className="col-12 col-md-12 col-xl-4 mb-2 page-heading">
          {!policyViolationId
            ? filterName === "SHADOW_AI"
              ? "AI > "
              : "Data Stores > "
            : "Policy Violations > "}
          <span className="text-info">{"Overview"}</span>
        </div>
      </div>
      {!policyViolationId ? (
        <>
          {filterName === "SHADOW_AI" ? (
            <>
              <Cards
                severity={severity}
                filterIssues={(type) => {
                  setPage(1);

                  handleSeverity(type);
                }}
                selectedAccount={selectedAccount}
                inputText={inputText}
                setInputText={setInputText}
              />
              <div className="modal-inventory mt-3">
                <div className="inventroy-title-container">
                  <h6 className="text-white">
                    {filterName === "SHADOW_AI"
                      ? "Model Inventory"
                      : "Data Inventory"}
                    {`${location ? "(" + location + ")" : ""}`}
                  </h6>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col m-0 col-md-6 col-lg-6 pb-4">
                        <ShadowDataOverview
                          region={location}
                          selectedAccount={selectedAccount}
                        />
                      </div>{" "}
                      <div className="col m-0 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
                        <ShadowDataPieChart />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="training-data">
                <div className="inventroy-title-container">
                  <h6 className="text-white">
                    Training Data
                    {`${location ? "(" + location + ")" : ""}`}
                  </h6>
                </div>
                <div className="row">
                  <div className="col m-0 col-md-6 col-lg-6 pb-4">
                    <MosstUsedDataStores
                      selectedAccount={selectedAccount}
                      setSensitiveFilter={handleSensitiveFilter}
                      region={location}
                    />
                  </div>
                  <div className="col m-0 col-md-6 col-lg-6 pb-4">
                    <ShadowSensitiveData
                      selectedAccount={selectedAccount}
                      setSensitiveFilter={handleSensitiveFilter}
                      region={location}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="data-inventroy">
                <div className="inventroy-title-container">
                  <h6 className="text-white">
                    {filterName === "SHADOW_AI" &&
                    (severity === "SHADOW_AI" || severity === "AI")
                      ? "Modal Inventory"
                      : "Data Inventory"}{" "}
                    {`${location ? "(" + location + ")" : ""}`}
                  </h6>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <SensitiveData
                      selectedAccount={selectedAccount}
                      setSensitiveFilter={handleSensitiveFilter}
                      region={location}
                    />
                  </div>
                  <div className="col-sm-6">
                    <DataOverview
                      region={location}
                      selectedAccount={selectedAccount}
                    />
                  </div>
                </div>
              </div>
              <Cards
                severity={severity}
                filterIssues={(type) => {
                  setPage(1);

                  handleSeverity(type);
                }}
                selectedAccount={selectedAccount}
                inputText={inputText}
                setInputText={setInputText}
              />
            </>
          )}
        </>
      ) : (
        <PolicyDetails
          ticketDetail={ticketDetail}
          currentPosts={currentPosts}
          getBulkTicketDetail={getBulkTicketDetail}
        />
      )}

      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }>
        <div id="datastores">
          <div className="table-main">
            <div class="row">
              <div class="col col-xl-4 align-self-start">
                <form onSubmit={makeSearch}>
                  <div class="form-group has-search">
                    <i class="ri-search-line form-control-feedback"></i>
                    <input
                      type="text"
                      value={search}
                      className="form-control ml-3"
                      placeholder="Search.."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div>

              <div class="col col-xl-8 align-self-end">
                <div class="p-2 text-right">
                  {filterName === "SHADOW_AI" &&
                    (severity === "SHADOW_AI" || severity === "AI") && (
                      <span>
                        <span className="mr-2">Show All</span>
                        <Toggle
                          defaultChecked={shadowAIFilter}
                          onChange={() => toggleShadowAIFilter()}
                          className="risk-accept-toggle"
                          icons={false}
                          id="risk-accept-toggle"
                        />
                      </span>
                    )}

                  <Pagination
                    page={page}
                    setPage={(val) => setPage(val)}
                    limit={limit}
                    totalCount={totalDatastoresCount}
                  />
                </div>
              </div>
            </div>

            {currentPosts.length ? (
              <Table
                policyViolationId={policyViolationId}
                severity={severity}
                setLoading={setLoading}
                currentPosts={currentPosts}
                sortData={sortData}
                ticketDetail={ticketDetail}
                page={"datastore-listing"}
                refreshDatastores={refreshDatastores}
              />
            ) : !loading ? (
              <NoDataContainer message={"No Datastores Available"} />
            ) : (
              ""
            )}
            {/* <WorldMap /> */}
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default Datastore;
