import React, { useEffect } from "react";
import IdentityComponent from "../../components/cloud/identity";

const Identity = ({ setPath, path, selectedAccount }) => {
  useEffect(() => {
    document.title = "Daxa AI - Identity";

    setPath(window.location.pathname);
  }, []);

  return (
    <div>
      <IdentityComponent path={path} selectedAccount={selectedAccount} />
    </div>
  );
};

export default Identity;
