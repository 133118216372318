import { useTheme } from "@mui/material";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import { trainingDataList } from "../shadow-llm/training_data";
import AWSIcon from "../../assets/img/AWS_S3.svg";
import GCPIcon from "../../assets/img/gcpCloud.svg";
import { useHistory } from "react-router-dom";
import { gcpTrainingDataList } from "../shadow-llm/gcp_training_data";
import {
  getImageForNode,
  modifyStringStartAndEnd,
} from "../../utils/commonUtils";
import Tooltip from "../common/Tooltip";

const TrainingDataCard = ({}) => {
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    cardContainer: {
      width: "70%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    countOfLLMs: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "130px",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    locationCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  };

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Typography color={theme.palette.surface80.main} sx={styles.cardTitle}>
          Retrieved Data
        </Typography>
        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          sx={styles.fontMedium}
        >
          Most Accessed Documents
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Data Source</TableCell>
              <TableCell>Retrievals</TableCell>
              <TableCell sx={{ width: "50%" }}>Labels</TableCell>
              <TableCell>Applications</TableCell>
            </TableHead>
            <TableBody>
              {trainingDataList
                ?.sort((data1, data2) => data2?.retrievals - data1?.retrievals)
                ?.map((dataItem) => (
                  <TableRow>
                    <TableCell sx={{ width: "auto" }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={getImageForNode(dataItem.type).imgSrc}
                          alt="icon"
                          height={18}
                          width={18}
                        />
                        <Tooltip title={dataItem?.fullName}>
                          <div
                            style={{
                              width: "400px",
                              wordBreak: "break-all",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {dataItem?.FileName}
                            </Typography>
                          </div>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.countOfLLMs}>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {dataItem?.retrievals}
                      </Typography>
                      <Bar
                        isLastBar={false}
                        xLabel={""}
                        barBackColor={theme.palette.surface20.main}
                        barFaceColor={theme.palette.lightMagenta.main}
                        rectWidth={(dataItem?.retrievals / 100) * 100}
                        index={dataItem?.Title}
                        totalValue={4}
                        barLabel={""}
                        barValue={dataItem?.retrievals}
                        type={""}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {dataItem?.sensitive_labels}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {dataItem?.count}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TrainingDataCard;
