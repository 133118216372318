import { useEffect } from "react";
import ShadowLLMComponent from "../../components/shadow-llm/ShadowLLMComponent";

const ShadowLLM = ({ setPath, selectedAccount }) => {
  setPath(window.location.pathname);

  useEffect(() => {
    document.title = "Daxa AI - Vulnerabily Details";
  }, []);

  return (
    <>
      <ShadowLLMComponent selectedAccount={selectedAccount} />
    </>
  );
};

export default ShadowLLM;
