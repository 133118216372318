import {
  axiosDenaliPrivate,
  axiosAILabDenaliPrivate,
} from "../../utils/ApiHandler";

export const getAllPromptsSummary = (params) => {
  return axiosAILabDenaliPrivate.post(
    "/api/v1/ai/apps/prompts/summary",
    params
  );
};
