import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../context/appContext";
import { UsageCardProps } from "../../types/type";
import {
  formatBytes,
  getNumberAbbreviation,
  isContainsValue,
  isToday,
} from "../../utils/commonUtils";
import Box from "../common/Box";
import Loader from "../common/Loader";
import Typography from "../common/Typography";
import { fetchAITrainingDataSlice } from "../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../utils/SessionHelper";
import LegendWithColor from "../common/LegendWithColor";
import PromptLineGraph from "../llm/LLMFramework/PromptLineGraph";
import Divider from "../common/Divider";
import { Select } from "../common/Select";
import {
  DASHBOARD_DATA_VIEW,
  promptTimestampFilter,
} from "../constants/constants";
import { getAllPromptsSummarySlice } from "../../redux/slices/llmSlice";
import { setPromptTimeFilter } from "../../redux/slices/dashboardSlice";
import moment from "moment";
import { genAITrends } from "../../mock-data/genAIAppTrendsData";
import { Radio, RadioGroup } from "../common/Radio";
import { FormControlLabel } from "../common/FormControl";

interface DsType {
  size: number;
  deltaSize: number;
  dsCount: number;
}

const UsageCard = () => {
  const {
    resourcesData,
    isFetchingImpactedStoresData,
    getImpactedStoresData,
    dashboardViewLense,
  } = useContext(AppContext);
  const [resources, setResources] = useState<UsageCardProps>({
    deltaIncrease: true,
    currentResources: 0,
    deltaResources: 0,
    increaseInPercent: 0,
    users: 0,
  });
  const [dsInfo, setDsInfo] = useState<DsType>({
    size: 0,
    deltaSize: 0,
    dsCount: 0,
  });
  const [promptsSummary, setPromptsSummary] = useState({
    promptStats: {},
    promptTrends: {},
  });
  const [selectedCategory, setSelectedCategory] = useState("retrievals");

  const theme = useTheme();
  const dispatch = useDispatch();
  const promptFilter = useSelector(
    (state: any) => state.dashboard.promptFilter
  );

  const isDataView = dashboardViewLense === DASHBOARD_DATA_VIEW;

  const getDsInfoData = async () => {
    const payload = {
      customerId: getCustomerId(),
    };
    const resp = await dispatch(getAllPromptsSummarySlice(payload));
    if (resp?.payload) {
      setPromptsSummary(resp?.payload);
    }
  };

  useEffect(() => {
    if (isDataView) {
      getImpactedStoresData();
    } else {
      getDsInfoData();
    }
  }, [isDataView]);

  useEffect(() => {
    if (isDataView && isContainsValue(resourcesData)) {
      const increaseFlag =
        resourcesData?.delta30_resources <= resourcesData?.current_resources;
      const increaseValue =
        resourcesData?.current_resources !== 0 &&
        resourcesData?.delta30_resources !== 0
          ? increaseFlag
            ? (resourcesData?.delta30_resources /
                resourcesData?.current_resources) *
              100
            : ((resourcesData?.delta30_resources * -1) /
                resourcesData?.current_resources) *
              100
          : 0;
      setResources({
        deltaIncrease: increaseFlag,
        currentResources: resourcesData?.current_resources,
        deltaResources: resourcesData?.delta30_resources,
        increaseInPercent: Number(increaseValue.toFixed(2)),
        users: resourcesData?.current_users,
      });
    } else if (isContainsValue(dsInfo) && !isDataView) {
      const increaseFlag = dsInfo?.deltaSize <= dsInfo?.size;
      const increaseValue =
        dsInfo?.size !== 0 && dsInfo?.deltaSize !== 0
          ? increaseFlag
            ? ((dsInfo?.size - dsInfo?.deltaSize) / dsInfo?.size) * 100
            : ((dsInfo?.deltaSize - dsInfo?.size) / dsInfo?.deltaSize) * 100
          : 0;
      setResources({
        deltaIncrease: increaseFlag,
        currentResources: dsInfo?.size,
        deltaResources: 0,
        increaseInPercent: Number(increaseValue.toFixed(2)),
        users: dsInfo?.dsCount,
      });
    } else {
      setResources({
        deltaIncrease: true,
        currentResources: 0,
        deltaResources: 0,
        increaseInPercent: 0,
        users: 0,
      });
    }
  }, [resourcesData, dsInfo, isDataView]);

  const styles = {
    cardContainer: {
      width: "100%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      paddingBottom: "0px",
      height: "100%",
    },
    cloudResourcesContainer: {
      display: "flex",
      flexDirection: isDataView ? "row" : "column",
      gap: isDataView ? 0 : theme.spacing(6),
    },
    resourcesTextContainer: {
      display: "flex",
      flexDirection: "column",
    },
    largeContainer: {
      width: "66%",
    },
    smallContainer: {
      width: isDataView ? "33%" : "100%",
    },
    resourcesText: {
      display: "flex",
      gap: theme.spacing(0.25),
      alignItems: "end",
    },
    resourcePercent: {
      display: "flex",
      gap: theme.spacing(0.25),
      alignItems: "center",
    },
    noOfResourcesText: {
      fontFamily: "Manrope",
      fontSize: "28px",
    },
    cardTitle: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    upArrowIcon: {
      height: "14px",
      width: "14px",
      color: theme.palette.primaryBlue.main,
    },
    cardHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //flexDirection: "column",
      // gap: theme.spacing(0.5),
    },
    aiCardContent: {
      //marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      //gap: theme.spacing(5),
      gap: theme.spacing(1),
    },
    firstRow: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridColumnGap: theme.spacing(2),
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    cardHeading: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    promptsTrends: {
      display: "flex",
      flexDirection: "column",
    },
    legendContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
      gap: theme.spacing(3),
    },
    promptMetric: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    totalMessages: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    messageBlock: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    categorySelector: {
      display: "flex",
      gap: theme.spacing(2),
    },
    radioBtnText: {
      "& .MuiTypography-root": {
        fontSize: "13px",
        color: theme.palette.surface80.main,
      },
    },
  };

  const categories = [
    {
      name: "retrievals",
      label: "Retrievals",
      color: theme.palette.peach.main,
    },
    {
      name: "users",
      label: "Users",
      color: theme.palette.laurelGreen.main,
    },
    {
      name: "documents",
      label: "Documents",
      color: theme.palette.paleBlue.main,
    },
  ];

  const getMessageCount = (data, category) => {
    return data && category ? data[category] : 0;
  };

  //const promptGraphData = [
  //  {
  //    category: "vectorDB",
  //    issues: 0,
  //    date: moment().subtract(6, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "vectorDB",
  //    issues: 1,
  //    date: moment().subtract(5, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "vectorDB",
  //    issues: 2,
  //    date: moment().subtract(4, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "vectorDB",
  //    issues: 0,
  //    date: moment().subtract(3, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "vectorDB",
  //    issues: 1,
  //    date: moment().subtract(2, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "vectorDB",
  //    issues: 0,
  //    date: moment().subtract(1, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "vectorDB",
  //    issues: 0,
  //    date: moment().format("YYYY-MM-DD"),
  //  },

  //  {
  //    category: "sensitivePrompts",
  //    issues: 2,
  //    date: moment().subtract(6, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitivePrompts",
  //    issues: 0,
  //    date: moment().subtract(5, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitivePrompts",
  //    issues: 2,
  //    date: moment().subtract(4, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitivePrompts",
  //    issues: 3,
  //    date: moment().subtract(3, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitivePrompts",
  //    issues: 2,
  //    date: moment().subtract(2, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitivePrompts",
  //    issues: 1,
  //    date: moment().subtract(1, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitivePrompts",
  //    issues: 0,
  //    date: moment().format("YYYY-MM-DD"),
  //  },

  //  {
  //    category: "sensitiveResponses",
  //    issues: 2,
  //    date: moment().subtract(6, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitiveResponses",
  //    issues: 4,
  //    date: moment().subtract(5, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitiveResponses",
  //    issues: 2,
  //    date: moment().subtract(4, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitiveResponses",
  //    issues: 0,
  //    date: moment().subtract(3, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitiveResponses",
  //    issues: 0,
  //    date: moment().subtract(2, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitiveResponses",
  //    issues: 4,
  //    date: moment().subtract(1, "days").format("YYYY-MM-DD"),
  //  },
  //  {
  //    category: "sensitiveResponses",
  //    issues: 1,
  //    date: moment().format("YYYY-MM-DD"),
  //  },
  //];

  const formatTrendData = (data) => {
    let result: any = [];
    if (data?.length > 0) {
      data?.forEach((item: any) => {
        for (const [key, value] of Object.entries(item)) {
          if (
            key !== "at" &&
            categories.find((category) => category.name === key)
          ) {
            result.push({
              category: key,
              issues: Number(value),
              date: item?.at,
            });
          }
        }
      });
    }
    result.sort((obj1, obj2) => +new Date(obj1.date) - +new Date(obj2.date));
    return result;
  };

  const plotLineGraph = useMemo(
    () => (
      <PromptLineGraph
        categories={categories}
        graphData={formatTrendData(genAITrends[selectedCategory][promptFilter])}
        svgHeight={120}
        ticks={2}
        showCategoryTooltip={false}
      />
    ),
    [promptsSummary?.promptTrends, promptFilter, selectedCategory]
  );

  return (
    <Box sx={styles.cardContainer}>
      {isFetchingImpactedStoresData ? (
        <Loader height={isDataView ? "68px" : "159px"} />
      ) : isDataView ? (
        <>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.cardTitle}
          >
            Last 30 Days
          </Typography>
          <Box sx={styles.cloudResourcesContainer}>
            <Box
              sx={{
                ...styles.resourcesTextContainer,
                ...styles.largeContainer,
              }}
            >
              <Box sx={styles.resourcesText}>
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.noOfResourcesText}
                >
                  {isDataView
                    ? getNumberAbbreviation(resources?.currentResources)
                    : formatBytes(resources?.currentResources)}
                </Typography>
                <Box sx={styles.resourcePercent}>
                  {resources.deltaIncrease ? (
                    <ArrowUpwardIcon sx={styles.upArrowIcon} />
                  ) : (
                    <ArrowDownwardIcon sx={styles.upArrowIcon} />
                  )}
                  <Typography
                    variant="caption"
                    color={theme.palette.primaryBlue.main}
                  >
                    {`${resources.increaseInPercent}% ${
                      isDataView
                        ? `(
                    ${getNumberAbbreviation(resources?.deltaResources)})`
                        : ""
                    }`}
                  </Typography>
                </Box>
              </Box>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                {isDataView ? "Cloud Resources" : "Data Used for Training"}
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.resourcesTextContainer,
                ...styles.smallContainer,
              }}
            >
              <Typography
                color={theme.palette.surface70.main}
                sx={styles.noOfResourcesText}
              >
                {resources?.users}
              </Typography>
              <Typography
                color={theme.palette.surface70.main}
                variant="caption"
              >
                {isDataView ? "Users" : "Number of Datastores used"}
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={styles.cardHeader}>
            <Typography
              sx={styles.cardHeading}
              color={theme.palette.surface80.main}
            >
              Applications Activity Trend
            </Typography>
            {/*<Divider orientation="vertical" flexItem />*/}
            <Select
              minWidth={100}
              options={promptTimestampFilter}
              value={promptFilter}
              variant="standard"
              sx={styles?.select}
              onChange={async (event: any) =>
                await dispatch(setPromptTimeFilter(event.target.value))
              }
            />
          </Box>
          <Box sx={styles.aiCardContent}>
            <Box sx={styles.categorySelector}>
              <RadioGroup
                row
                sx={styles.categorySelector}
                value={selectedCategory}
                onChange={(event) => setSelectedCategory(event.target.value)}
              >
                <FormControlLabel
                  value="retrievals"
                  control={<Radio size="small" />}
                  label="Retrievals"
                  sx={styles.radioBtnText}
                />
                <FormControlLabel
                  value="users"
                  control={<Radio size="small" />}
                  label="Users"
                  sx={styles.radioBtnText}
                />
                <FormControlLabel
                  value="documents"
                  control={<Radio size="small" />}
                  label="Documents"
                  sx={styles.radioBtnText}
                />
              </RadioGroup>
            </Box>
            <Box sx={styles.promptsTrends}>{plotLineGraph}</Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UsageCard;
