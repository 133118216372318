import { useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { MapPlotData, RegionsTooltip } from "../../types/type";
import Box from "../common/Box";
import Loader from "../common/Loader";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import Typography from "../common/Typography";
import WorldMap, { CountryData } from "../common/WorldMap";
import GeoDataStoreCard from "./GeoDataStoreCard";
import { regions } from "../maps/aws_regions";
import { gcpRegions } from "../maps/gcp_regions";
import { isContainsValue } from "../../utils/commonUtils";
import { DASHBOARD_DATA_VIEW } from "../constants/constants";
import LegendWithColor from "../common/LegendWithColor";
import { frameworkGeoData } from "../../mock-data/llmPromptsData";
import NetworkGraph from "../common/NetworkGraph";
import NetworkGraphCard from "./NetworkGraphCard";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "../common/Button";
import Divider from "../common/Divider";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { networkGraphData } from "../../mock-data/networkGraphData";

const GeoDistributionCard = () => {
  const [activeTab, setActiveTab] = useState("inventory-view");
  const {
    aiSummary,
    dashboardViewLense,
    geoDistribution,
    isFetchingGeoDistribution,
    getGeoDistributionData,
    overviewAllRegions,
    isFetchingAllRegionsOverview,
    getSensitiveData,
    overviewForSelectedRegion,
    isFetchingSelectedRegionOverview,
    getRegionsOverview,
  } = useContext(AppContext);

  const [geoView, setGeoView] = useState<string>("llms");
  const [activeRegion, setActiveRegion] = useState<{
    data: [];
    region: string;
    regionCode: string;
  }>({
    data: [],
    region: "All Regions",
    regionCode: "all",
  });
  const [geoData, setGeoData] = useState<
    Array<{ type: string; data: Array<MapPlotData> }>
  >([{ type: "", data: [] }]);
  const [tooltipData, setTooltipData] = useState<RegionsTooltip>({
    impacted: 0,
    cloud: "",
    data: [],
  });
  const isFrameworkView = geoView === "frameworks";
  const [overviewAll, setOverviewAll] = useState([]);
  const [graphView, setGraphView] = useState("networkView");
  const [overviewRegionSpecific, setOverviewRegionSpecific] = useState([]);
  const [impactedDataStore, setImpactedDataStore] = useState(0);
  const [totalDataStore, setTotalDataStore] = useState(0);
  const dataView = dashboardViewLense === DASHBOARD_DATA_VIEW;
  const [networkSelectedNode, setNetworkSelectedNode] = useState<any>(null);
  const [networkFullScreen, setNetworkFullScreen] = useState(false);

  const theme = useTheme();
  const styles = {
    geoCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      position: networkFullScreen ? "absolute" : "relative",
      height: networkFullScreen ? "100vh" : "auto",
      left: 0,
      top: 0,
      zIndex: networkFullScreen ? 1000 : "auto",
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    cardContent: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "65% 35%",
    },
    mapContainer: {
      width: "100%",
    },
    mapTabs: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    mapLegends: {
      width: "fit-content",
    },
    cardHeading: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    zoomButton: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      minWidth: "24px",
      height: "24px",
      border: `1px solid ${theme.palette.surface40.main}`,
      color: theme.palette.surface40.main,
      padding: 0,
    },
    zoomIcon: {
      width: 16,
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    actionButtons: {
      display: "flex",
      gap: theme.spacing(1),
      flexGrow: 1,
      justifyContent: "end",
    },
  };

  const cardTabs = [
    {
      value: "inventory-view",
      label: "Inventory View",
      color: theme.palette.lightMagenta.main,
      stroke: theme.palette.purpleStroke.main,
    },
    //{
    //  value: 'risk-view',
    //  label: 'Risk View',
    //  color: theme.palette.critical.main,
    //  stroke: theme.palette.redStroke.main,
    //},
  ];

  useEffect(() => {
    if (dataView) {
      getGeoDistributionData();
      getSensitiveData();
    } else {
      getGeoDistributionData({ type: "llm" });
      getSensitiveData({ type: "llm" });
    }
  }, [dashboardViewLense]);

  const getImpactedDSCount = () => {
    return overviewAllRegions?.sensitive_datastore;
    //return activeRegion?.regionCode === 'all'
    //  ? overviewAllRegions?.sensitive_datastore || 0
    //  : overviewRegionSpecific?.reduce(
    //      (
    //        acc: number,
    //        curr: {
    //          dataType: string;
    //          numberOfRecords: number;
    //          totalValue: number;
    //          impacted: number;
    //        }
    //      ) => acc + curr.impacted,
    //      0
    //    );
  };

  const formatDataForMap = (data) => {
    return data.map((dataItem) => {
      const regionData =
        !dataItem?.cloud || dataItem?.cloud === "aws"
          ? regions.find((region) => region.code === dataItem?.region)
          : gcpRegions.find((region) => region.code === dataItem?.region);
      return {
        type: "Feature",
        properties: {
          id: dataItem.region,
          region: dataItem?.region,
          name: regionData?.name,
          impacted: dataView
            ? dataItem?.dsTypes[0]?.count
            : !isFrameworkView
            ? dataItem?.dsTypes?.find((data) => data?.type === "LLM")?.count
            : dataItem?.datastores,
          hasDataResidencyViolation:
            dataView || isFrameworkView
              ? dataItem?.hasDataResidencyViolation
              : dataItem?.region === "us-east-1",
          hasDataStoreRisk: dataItem?.hasDataStoreRisk,
          sensitiveData: dataItem?.sensitiveData || dataItem?.sensitive_data,
          cloud: dataItem?.cloud,
        },
        geometry: {
          type: "Circle",
          coordinates: [regionData?.longitude, regionData?.latitude],
        },
      };
    });
  };

  useEffect(() => {
    if (Object.values(geoDistribution).length > 0) {
      let formattedData;
      if (isFrameworkView) {
        formattedData = formatDataForMap(frameworkGeoData);
      } else {
        formattedData = formatDataForMap(geoDistribution);
      }
      const inventoryView = formattedData;
      const riskView = formattedData.filter(
        (data) => data.properties.hasDataStoreRisk
      );
      setGeoData([
        { type: "inventory-view", data: inventoryView },
        {
          type: "risk-view",
          data: riskView,
        },
      ]);
    }
  }, [geoDistribution, geoView]);

  const totalLLMs = isContainsValue(aiSummary?.llm_ds_type)
    ? Object.values(aiSummary?.llm_ds_type)?.reduce(
        (acc: number, curr: any) => {
          return acc + curr;
        },
        0
      )
    : 0;

  useEffect(() => {
    if (isContainsValue(overviewAllRegions) || isFrameworkView) {
      let cardData;
      if (isFrameworkView) {
        cardData = frameworkGeoData[0];
      } else {
        cardData = overviewAllRegions;
      }
      const totalRecords = cardData?.sensitive_data?.reduce(
        (acc: number, curr) => acc + (curr?.count || curr?.records),
        0
      );
      const overviewAll = cardData.sensitive_data?.map((data) => ({
        dataType: data?.tag,
        numberOfRecords: data?.count || data?.records,
        impacted: data?.datastores,
        totalValue: totalRecords,
      }));
      setOverviewAll(overviewAll);
      setImpactedDataStore(cardData?.sensitive_datastore);
      setTotalDataStore(overviewAllRegions?.total_datastore);
    }
  }, [overviewAllRegions, geoData]);

  useEffect(() => {
    if (activeRegion?.regionCode !== "all")
      getRegionsOverview({
        region: activeRegion?.regionCode,
        type: dataView ? "" : "llm",
      });
    else {
      if (!isFrameworkView) {
        setImpactedDataStore(overviewAllRegions?.sensitive_datastore);
        setTotalDataStore(overviewAllRegions?.total_datastore);
      } else {
        setImpactedDataStore(frameworkGeoData[0]?.sensitive_datastore);
        setTotalDataStore(0);
      }
    }
  }, [activeRegion]);

  useEffect(() => {
    if (Object.values(overviewForSelectedRegion)?.length > 0) {
      let cardData;
      if (isFrameworkView) {
        cardData = frameworkGeoData[0];
      } else {
        cardData = overviewForSelectedRegion;
      }
      const totalRecords = cardData?.sensitive_data?.reduce(
        (acc: number, curr) => acc + (curr?.count || curr?.records),
        0
      );
      setOverviewRegionSpecific(
        cardData?.sensitive_data?.map((data) => ({
          dataType: data?.tag,
          numberOfRecords: data?.count || data?.records,
          impacted: data?.datastores,
          totalValue: totalRecords,
        }))
      );
      setImpactedDataStore(cardData?.sensitive_datastore);
      setTotalDataStore(overviewForSelectedRegion?.total_datastore);
    }
  }, [overviewForSelectedRegion]);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setActiveRegion({ data: [], region: "All Regions", regionCode: "all" });
  };

  const handleMouseOverTooltip = (cloud, data, impacted) => {
    if (tooltipData?.data?.length === 0) {
      const recordsForTooltip =
        data?.map(({ tag, records }) => ({
          dataType: tag,
          records: records,
        })) || [];
      setTooltipData({ impacted: impacted, data: recordsForTooltip, cloud });
    }
  };

  const tabData = [
    {
      value: "frameworks",
      label: "Applications",
    },
    {
      value: "llms",
      label: "LLMs",
    },
  ];

  const parentTabData = [
    {
      value: "geoView",
      label: "Geo-distribution View",
    },
    {
      value: "networkView",
      label: "Network Graph",
    },
  ];

  const getPolicyDetails = (data) => {
    return data && data?.length > 0
      ? data?.reduce(
          (acc: number, curr: { count: number }) => acc + curr.count,
          0
        )
      : 0;
  };

  const toggleWidgetWidth = (value) => {
    setNetworkFullScreen(value);
  };

  const styleSelectedNode = (d) => {
    if (networkSelectedNode?.id === d?.id) {
      setNetworkSelectedNode(null);
    } else {
      setNetworkSelectedNode(d);
    }
  };

  useEffect(() => {
    const id = networkSelectedNode?.id;
    const previouslySelectedElement =
      document.querySelector(`.networkGraphSelectedNode`) ||
      document.querySelector(`.networkGraphHoveredHexagon`);
    previouslySelectedElement?.classList.remove("networkGraphSelectedNode");

    if (
      previouslySelectedElement?.classList.contains(
        "networkGraphHoveredHexagon"
      )
    ) {
      previouslySelectedElement?.classList.add("node-hexagon-border");
      previouslySelectedElement?.classList.remove("networkGraphHoveredHexagon");
    }
    if (id && id !== "") {
      const activeNode = document.querySelector(`.nodeWrapper-${id}`);
      activeNode?.classList.remove("node-hexagon-border");
      activeNode?.classList.add(
        networkSelectedNode?.type === "langchain"
          ? "networkGraphHoveredHexagon"
          : "networkGraphSelectedNode"
      );
    }
  }, [networkSelectedNode]);

  return (
    <Box sx={styles.geoCard}>
      {isFetchingGeoDistribution ? (
        <Loader height="531px" />
      ) : (
        <>
          <Box sx={styles.cardHeading}>
            <Tabs
              value={graphView}
              onChange={(event, newValue) => {
                setGraphView(newValue);
              }}
            >
              {parentTabData?.map((item) => (
                <Tab value={item.value} label={item.label} key={item.value} />
              ))}
            </Tabs>
            <Box sx={styles.actionButtons}>
              <Button sx={styles.zoomButton} onClick={() => {}}>
                <AddIcon sx={styles.zoomIcon} />
              </Button>
              <Button sx={styles.zoomButton} onClick={() => {}}>
                <RemoveIcon sx={styles.zoomIcon} />
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                sx={styles.zoomButton}
                onClick={() => toggleWidgetWidth(!networkFullScreen)}
              >
                {networkFullScreen ? (
                  <FullscreenExitIcon sx={styles.zoomIcon} />
                ) : (
                  <FullscreenIcon sx={styles.zoomIcon} />
                )}
              </Button>
            </Box>
          </Box>
          <TabPanel value={graphView} index={parentTabData[0]?.value}>
            <>
              <Tabs
                value={geoView}
                onChange={(event, newValue) => {
                  setGeoView(newValue);
                }}
              >
                {tabData?.map((item) => (
                  <Tab value={item.value} label={item.label} key={item.value} />
                ))}
              </Tabs>
              <Box sx={styles.cardContent}>
                <Box sx={styles.mapTabs}>
                  <Box sx={{ display: dataView ? "" : "none" }}>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                      {cardTabs.map((tab: object, index: number) => (
                        <Tab {...tab} key={index} />
                      ))}
                    </Tabs>
                  </Box>
                  {/*{!dataView && (
                <Box sx={styles.mapLegends}>
                  <LegendWithColor
                    label="LLMs With Sensitive Data"
                    color={theme.palette.lightMagenta.main}
                  />
                </Box>
              )}*/}
                  <WorldMap
                    countriesToPlot={
                      geoData.find((data) => data.type === activeTab)?.data ||
                      []
                    }
                    activeTabData={
                      cardTabs.find((tab) => tab.value === activeTab) ||
                      cardTabs[0]
                    }
                    activeRegion={activeRegion}
                    dataView={dataView}
                    setActiveRegion={setActiveRegion}
                    handleMouseOverTooltip={handleMouseOverTooltip}
                    tooltipData={tooltipData || []}
                    clearTooltipData={() =>
                      setTooltipData({
                        impacted: 0,
                        cloud: "",
                        data: [],
                      })
                    }
                    isFrameworkView={isFrameworkView}
                  />
                </Box>
                <GeoDataStoreCard
                  barColor={
                    cardTabs.find((tab) => tab?.value === activeTab)?.color ||
                    ""
                  }
                  impactedDS={impactedDataStore}
                  totalDS={totalDataStore}
                  activeRegion={activeRegion}
                  dataDistribution={
                    activeRegion.regionCode === "all"
                      ? overviewAll
                      : overviewRegionSpecific
                  }
                  isFetchingData={
                    isFetchingAllRegionsOverview ||
                    isFetchingSelectedRegionOverview
                  }
                  isFrameworkView={isFrameworkView}
                />
              </Box>
            </>
          </TabPanel>
          <TabPanel value={graphView} index={parentTabData[1]?.value}>
            <Box sx={styles.cardContent}>
              <NetworkGraph
                selectedNode={networkSelectedNode}
                handleSelectedNode={styleSelectedNode}
                setSelectedNode={setNetworkSelectedNode}
                getPolicyDetails={getPolicyDetails}
                networkGraphData={networkGraphData}
              />
              <Box sx={styles.cardContainer}>
                {networkSelectedNode && (
                  <NetworkGraphCard
                    nodeDetails={networkSelectedNode}
                    getPolicyDetails={getPolicyDetails}
                  />
                )}
              </Box>
            </Box>
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default GeoDistributionCard;
