import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPromptsSummary } from "../apis/llmAPI";

const initialState = {
  llms: [],
};

export const getAllPromptsSummarySlice = createAsyncThunk(
  "llm/get",
  async (params) => {
    const response = await getAllPromptsSummary(params);
    return response.data;
  }
);

// LLM slice for configuring reducers and actions.
export const llmSlice = createSlice({
  name: "llm",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: () => {},
});

export default llmSlice.reducer;
