import { Link } from "react-router-dom";
import DaxaLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import DaxaTitle from "../../assets/img/daxa-assets/daxa-title.svg";

const Timeout = () => {
  return (
    <>
      <section className="sign-in-page">
        <div className="container p-0">
          <div className="logo-container">
            <div>
              <img
                src={DaxaLogo}
                alt="Daxa icon"
                className="daxa-logo"
                height={48}
              />
            </div>
            <div>
              <img
                src={DaxaTitle}
                alt="Daxa title"
                className="daxa-title"
                width={88}
              />
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-sm-12 align-self-center">
              <div className="sign-in-from">
                <h3 className="text-center text-danger mb-5">
                  Session timeout <br />
                  Please
                  <Link to="/">
                    <u>
                      <b>login</b>
                    </u>
                  </Link>
                  again to access the demo
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Timeout;
