import { useTheme } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../common/Accordion";
import Box from "../../common/Box";
import Divider from "../../common/Divider";
import Typography from "../../common/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import TextButton from "../../common/TextButton";
import Chip from "../../common/Chip";
import StarIcon from "../../../assets/img/star.svg";
import EditRestrictedLabelsDialog from "./EditRestrictedLabelsDialog";
import GroupsIcon from "../../../assets/img/groupsIcon.svg";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Tooltip from "../../common/Tooltip";
import { getFileName } from "../../../utils/commonUtils";

export interface Props {
  userDetails: any;
  appName: string;
  selectedFilter: string;
}
const RetrievalUserDetails = ({
  userDetails,
  appName,
  selectedFilter,
}: Props) => {
  const isUser = selectedFilter === "user";
  const theme = useTheme();
  const [showUserDetails, setShowUserDetails] = useState(true);
  const [openEditLabelDialog, setOpenEditLabelDialog] = useState(false);
  const [displayedLabels, setDisplayedLabels] = useState([]);
  const styles = {
    container: {
      padding: theme.spacing(2),
    },
    cardHeader: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    icon: {
      color: `${theme.palette.primaryBlue.main} !important`,
      fontSize: theme.spacing(2.25),
    },
    userDetailsBox: {
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    restrictedLabelsHeader: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    sectionBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    accordion: {
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        margin: "0px",
      },
    },
    labelChip: {
      borderRadius: theme.spacing(0.25),
    },
    aiChip: {
      backgroundColor: "transparent",
      color: theme.palette.surface60.main,
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
    list: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    divider: {
      height: "13px",
    },
    groups: { display: "flex", gap: theme.spacing(1) },
  };

  const onEditUserLabels = (labels) => {
    const localLabels = localStorage.getItem("restrictedLabels");
    let retrievalUsers = localLabels ? JSON.parse(localLabels) : {};
    const name = userDetails?.name;
    if (retrievalUsers[name]?.frameworkRestrictedLabels) {
      retrievalUsers[name].frameworkRestrictedLabels[appName] = labels;
    } else {
      retrievalUsers[name] = {
        frameworkRestrictedLabels: {
          ...retrievalUsers[name]?.frameworkRestricedLabels,
          [appName]: labels,
        },
      };
    }
    setDisplayedLabels(labels);
    localStorage.setItem("restrictedLabels", JSON.stringify(retrievalUsers));
  };

  useEffect(() => {
    setDisplayedLabels(userDetails?.restrictedLabels);
  }, [userDetails?.restrictedLabels]);

  const documentGroups = !isUser
    ? userDetails?.authorizedGroups
    : userDetails?.groups;

  return (
    <Accordion sx={styles.accordion} defaultExpanded={true}>
      <Box sx={styles.cardHeader}>
        <Typography color={theme.palette.surface80.main} variant="body2">
          Retrievals By
        </Typography>
        {isUser ? (
          <Typography color={theme.palette.surface80.main} variant="body2">
            {userDetails?.name}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color={theme.palette.surface80.main}
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            {getFileName(userDetails?.fileName)}
          </Typography>
        )}
        <Divider orientation="vertical" flexItem />
        <Typography color={theme.palette.surface40.main} variant="caption">
          {userDetails?.designation || "-"}
        </Typography>
        <Divider orientation="vertical" flexItem sx={styles.divider} />
        <Box>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={styles.icon} />}
            onClick={() => setShowUserDetails(!showUserDetails)}
          >
            <TextButton>Details</TextButton>
          </AccordionSummary>
        </Box>
      </Box>
      <AccordionDetails>
        <Box sx={styles.userDetailsBox}>
          {!isUser && (
            <>
              <Box sx={styles.sectionBox}>
                <Box sx={styles.restrictedLabelsHeader}>
                  <Typography
                    color={theme.palette.surface40.main}
                    variant="caption"
                  >
                    Document Owner
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color={theme.palette.surface80.main}
                >
                  {userDetails?.owner || "-"}
                </Typography>
              </Box>
              <Divider orientation="horizontal" />
            </>
          )}
          <Box sx={styles.sectionBox}>
            <Typography color={theme.palette.surface40.main} variant="caption">
              {isUser ? "" : "Authorized"} Groups ({documentGroups?.length || 0}
              )
            </Typography>
            <Box sx={styles.list}>
              {documentGroups?.map((group) => (
                <Box sx={styles.groups}>
                  <img src={GroupsIcon} width="10px" />
                  <Typography
                    color={theme.palette.surface80.main}
                    variant="caption"
                  >
                    {group}
                  </Typography>
                  <Divider orientation="vertical" sx={styles.divider} />
                </Box>
              ))}
            </Box>
          </Box>
          {isUser && (
            <>
              <Divider orientation="horizontal" />
              <Box sx={styles.sectionBox}>
                <Box sx={styles.restrictedLabelsHeader}>
                  <Typography
                    color={theme.palette.surface40.main}
                    variant="caption"
                  >
                    Restricted Labels ({displayedLabels?.length || 0})
                  </Typography>
                  <Divider orientation="vertical" sx={styles.divider} />
                  <Chip
                    sx={styles.aiChip}
                    label="AI Assisted"
                    avatar={<img src={StarIcon} />}
                  />
                </Box>
                <Box sx={styles.list}>
                  {displayedLabels?.length > 0 ? (
                    displayedLabels?.map((label) => (
                      <Chip
                        sx={styles.labelChip}
                        label={
                          <Typography
                            color={theme.palette.surface80.main}
                            variant="caption"
                          >
                            {label}
                          </Typography>
                        }
                      />
                    ))
                  ) : (
                    <Typography
                      color={theme.palette.surface80.main}
                      variant="caption"
                    >
                      No labels found
                    </Typography>
                  )}
                  <Divider orientation="vertical" sx={styles.divider} />
                  <TextButton
                    onClick={() => setOpenEditLabelDialog((prev) => !prev)}
                  >
                    Edit
                  </TextButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </AccordionDetails>
      <EditRestrictedLabelsDialog
        open={openEditLabelDialog}
        onClose={() => setOpenEditLabelDialog(false)}
        name={userDetails?.name}
        userLabels={userDetails?.restrictedLabels}
        onEditUserLabels={onEditUserLabels}
      />
    </Accordion>
  );
};

export default RetrievalUserDetails;
