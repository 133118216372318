import { IconButton, useTheme } from "@mui/material";
import Box from "../../common/Box";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "../../common/Typography";

export const LangchainPopover = ({
  handleClose,
  popoverData,
}: {
  handleClose: any;
  popoverData: any;
}) => {
  const theme = useTheme();
  const styles = {
    popover: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    popoverHeader: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    popoverHeaderBody: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    popoverHeaderBodyText: {
      fontSize: "10px",
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: "#999DA6",
    },
    closeIcon: {
      color: theme?.palette?.surface20?.main,
    },
    popoverBody: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      columnGap: theme.spacing(9.5),
      rowGap: theme.spacing(2),
    },
    iconButton: {
      padding: 0,
    },
    lableWithValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const dataLabels = [
    {
      label: "Location",
      field: "location",
    },
    {
      label: "Installed Via Package Manager",
      field: "installedVia",
    },
    {
      label: "Github Link",
      field: "githubLink",
    },
    {
      label: "License Type",
      field: "licenceType",
    },
    {
      label: "Documentation Site Link",
      field: "documentationUrl",
    },
    {
      label: "PyPi Link",
      field: "pypiUrl",
    },
  ];

  const labelAndValue = (label, value) => (
    <Box sx={styles.lableWithValue}>
      <Typography color="#63698B" sx={styles.popoverHeaderBodyText}>
        {label}
      </Typography>
      <Typography variant="subtitle2" color="#080B1E">
        {popoverData?.packageInfo[value] || "-"}
      </Typography>
    </Box>
  );

  return (
    <Box sx={styles.popover}>
      <Box sx={styles.popoverHeader}>
        <Box>
          <Box sx={styles.popoverHeaderBody}>
            <Typography variant="subtitle2" color="#63698B">
              {popoverData?.name}
            </Typography>
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.dividerStyles}
            />
            <TextButton>View Details</TextButton> */}
          </Box>
          <Box sx={styles.popoverHeaderBody}>
            <Typography color="#63698B" sx={styles.popoverHeaderBodyText}>
              {popoverData?.version}
            </Typography>
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.dividerStyles}
            />
            <Typography color="#63698B" sx={styles.popoverHeaderBodyText}>
              5 GB
            </Typography> */}
          </Box>
        </Box>
        <IconButton onClick={handleClose} sx={styles.iconButton}>
          <CloseIcon fontSize="small" sx={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.popoverBody}>
        {dataLabels?.map((item: any) => labelAndValue(item?.label, item.field))}
      </Box>
    </Box>
  );
};
