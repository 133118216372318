import "./style.scss";

const Footer = () => {
  return (
    <footer>
      <div className="contents">
        <div>
          <h6>Copyright © 2024 Daxa AI, Inc</h6>
          <h6>All rights reserved.</h6>
        </div>
        <div>
          <h6>
            <a
              href="https://www.clouddefense.io/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a href="https://www.clouddefense.io/terms-of-use" target="_blank">
              Term of Service
            </a>
          </h6>
        </div>
        <div>
          <img
            src={
              "https://uploads-ssl.webflow.com/6368a330c6078773baa83c80/636a5c651c7417e9ef7672d4_soc2.webp"
            }
            className="logo"
            alt="logo"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
