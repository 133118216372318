import { useEffect, useState } from "react";
import Box from "../../common/Box";
import { useTheme } from "@mui/material";
import SensitiveMessageTable from "../SensitiveMessageTable";
import SnippetDetails from "./SnippetDetails";

export const DataSourceMessageDetails = ({
  messageData,
  selectedLoaderIndex,
  directoryValue,
  frameworkData,
}) => {
  const [selectedSensitiveLabel, setSelectedSensitiveLabel] = useState("");
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  const styles = {
    summaryContent: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      columnGap: theme.spacing(3),
    },
  };

  useEffect(() => {
    setSelectedSensitiveLabel(
      messageData[selectedLoaderIndex][0].sensitiveLabel
    );
  }, [selectedLoaderIndex]);

  const createDirectoryLink = (res) => {
    const data = res?.sourceFile?.split("/");
    let directoryStr = "";
    const offset = data?.length - 2 === 1 ? 1 : 2;
    for (let i = 0; i < data.length - offset; i++) {
      directoryStr = directoryStr + `${data[i]}/`;
    }
    return directoryStr;
  };

  useEffect(() => {
    const res =
      messageData[selectedLoaderIndex]?.find(
        (promptData) => promptData.sensitiveLabel === selectedSensitiveLabel
      )?.messages || [];
    if (res?.length > 0) {
      directoryValue(createDirectoryLink(res[0]));
    }
    setMessages(res);
  }, [selectedLoaderIndex, selectedSensitiveLabel]);

  return (
    <Box sx={styles.summaryContent}>
      <SensitiveMessageTable
        sensitiveData={(messageData[selectedLoaderIndex] || [])?.filter(
          (labels) => labels?.sensitiveLabel !== "Identity Violation"
        )}
        onRowClick={(label) => {
          setSelectedSensitiveLabel(label);
        }}
        selectedSensitiveLabel={selectedSensitiveLabel}
      />
      <SnippetDetails
        sensitiveData={messageData[selectedLoaderIndex]}
        messagesList={messages}
        selectedSensitiveLabel={selectedSensitiveLabel}
        frameworkData={frameworkData}
      />
    </Box>
  );
};
