export const trainingDataList = [
  {
    FileName: "Notion_DB/product-table/product-kb.md",
    fullName: "Notion_DB/product-table/product-kb.md",
    cloud: "aws",
    redirectTo:
      "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket",
    Source: "Configured",
    sensitive_labels: "Address, Product Roadmap",
    size: "4.16 GB",
    type: "Notion",
    training_date: "2023-05-10T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 4,
    retrievals: 23,
  },
  {
    FileName: "health-advice.pdf",
    fullName:
      "https://drive.google.com/file/d/1Mx8cqCtjqIAAuz1jO6DsqR03n6GZ4b1O/kb/health-advice.pdf",
    Location: "s3://role-data-bucket/training_2.json",
    cloud: "aws",
    redirectTo:
      "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket",
    Source: "Configured",
    sensitive_labels: "SSN, PII, Medical Advice",
    size: "2.45 GB",
    type: "GoogleDrive",
    training_date: "2023-05-15T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 3,
    retrievals: 12,
  },
  {
    FileName: "Notion_DB/kb/health-records.json",
    fullName: "Notion_DB/kb/health-records.json",
    Location: "s3://role-data-bucket/training_3.json",
    cloud: "aws",
    redirectTo:
      "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket",
    Source: "Configured",
    sensitive_labels: "SSN, Medical Advice",
    size: "6.34 GB",
    type: "Notion",
    training_date: "2023-06-10T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 3,
    retrievals: 31,
  },
  {
    FileName: "historical-loans.pdf",
    fullName:
      "https://drive.google.com/file/d/1Mx8cqCtjqIAAuz1jO6DsqR03n6GZ4b1O/view//approvals/historical-loans.pdf",
    Location: "s3://role-data-bucket/training_3.json",
    cloud: "aws",
    redirectTo:
      "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket",
    Source: "Configured",
    sensitive_labels: "SSN, PII, ADDRESS",
    size: "6.34 GB",
    type: "GoogleDrive",
    training_date: "2023-06-10T04:32:36.491000",
    policy_violation: true,
    account: "099873896856",
    count: 1,
    retrievals: 35,
  },
  //{
  //  FileName: "training_6.json",
  //  Location: "s3://role-data-bucket/training_3.json",
  //  cloud: "aws",
  //  redirectTo:
  //    "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket",
  //  Source: "Configured",
  //  sensitive_labels: "SSN, PII, ADDRESS",
  //  size: "6.34 GB",
  //  type: "s3",
  //  training_date: "2023-06-10T04:32:36.491000",
  //  policy_violation: true,
  //  account: "099873896856",
  //  count: 1,
  //},
  // {
  //   FileName: "training_4.json",
  //   Location: "s3://role-data-bucket/training_4.json",
  //   Source: "Live Access",
  //   sensitive_labels: "SSN, PII, ADDRESS",
  //   size: "5.3 GB",
  //   type: "s3",
  //   training_date: "2023-06-09T04:32:36.491000", account: "099873896856",
  // },
  // {
  //   FileName: "training_5.json",
  //   Location: "s3://role-data-bucket/training_5.json",
  //   Source: "Live Access",
  //   sensitive_labels: "SSN, PII, ADDRESS",
  //   size: "1.12 GB",
  //   type: "s3",
  //   training_date: "2023-07-04T04:32:36.491000", account: "099873896856",
  // },
  //{
  //  FileName: "training_6.json",
  //  Location: "s3://sagemaker-us-east-1-099873896856/training_6.json",
  //  redirectTo:
  //    "/datastore-details/3f672f44-f1ab-4923-a1b3-3d25c327a0fe/arn:aws:s3:::role-data-bucket",
  //  Source: "Access Path",
  //  sensitive_labels: "SSN, PII, ADDRESS",
  //  size: "2.16 GB",
  //  type: "s3",
  //  training_date: "2023-07-05T04:32:36.491000",
  //  account: "099873896856",
  //  count: 1,
  //},
];
