import { Collapse, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { retrievalUserDetails } from "../../mock-data/retrievalUserDetails";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import WarningIcon from "../../assets/img/warningIcon.svg";
import KeyboardArrowUpRounded from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded";

const CollapsableRow = ({
  row,
  rowData,
  selectedUser,
  selectedFilter,
  onRowClick,
  selectedSensitiveLabel,
  userLabels,
}) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const styles = {
    category: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableCell: {
      fontSize: "13px",

      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",

      //paddingX: 0,
      "& .MuiTableCell-root": {
        // paddingX: "0 !important",
      },
    },
    imageWithLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    tableBody: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: "transparent",
      },
    },
  };

  // Keep row open by default if flag is set to true
  useEffect(() => {
    if (rowData?.length > 0) setOpen(rowData[0]?.labelType === row?.value);
    else setOpen(false);
  }, [rowData]);

  const findingsCount = rowData.reduce(function (a, b) {
    return a + b?.messages?.length;
  }, 0);

  return (
    <>
      <TableRow>
        <TableCell
          sx={styles.category}
          onClick={() => setOpen((prev) => !prev)}
          width={"60%"}
        >
          {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {row?.name}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {findingsCount}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            padding: 0,
            "& .MuiTableCell-root": {
              paddingRight: "0 !important",
            },
          }}
          colSpan={2}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <Table>
              <TableBody sx={styles.tableBody}>
                {rowData?.map((rowItem) => {
                  let recordCount = 0;
                  if (selectedUser) {
                    if (selectedFilter === "user") {
                      recordCount =
                        rowItem?.messages?.filter(
                          (message) => message.retrievedBy === selectedUser
                        )?.length || 0;
                    } else {
                      const fileName = selectedUser?.split("/")?.at(-1);
                      recordCount =
                        rowItem?.messages?.filter((message) =>
                          message?.sourceFile?.includes(fileName)
                        )?.length || 0;
                    }
                  } else {
                    recordCount = rowItem?.messages?.length;
                  }
                  const labelExists =
                    userLabels &&
                    userLabels.find((label) =>
                      rowItem?.sensitiveLabel.includes(label)
                    );
                  return (
                    <TableRow
                      selected={
                        rowItem?.sensitiveLabel === selectedSensitiveLabel
                      }
                      onClick={() => onRowClick(rowItem?.sensitiveLabel)}
                    >
                      <TableCell sx={styles.tableCell}>
                        <Box sx={styles.imageWithLabel}>
                          {labelExists ? (
                            <img src={WarningIcon} alt="warning" />
                          ) : (
                            ""
                          )}
                          <Typography
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            {rowItem?.sensitiveLabel}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right" sx={styles.tableCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {recordCount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SensitiveMessageTable = ({
  selectedUser,
  sensitiveData,
  onRowClick,
  selectedSensitiveLabel,
  appName,
  selectedFilter,
}: {
  selectedUser?: string;
  sensitiveData: any;
  onRowClick: any;
  selectedSensitiveLabel: any;
  appName?: string;
  selectedFilter?: string;
}) => {
  const theme = useTheme();
  const styles = {
    tableContainer: {
      maxHeight: "100%",
      height: "fit-content",
      borderRadius: theme.spacing(0.5),
    },
    barCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface0.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    imageWithLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  };

  const tableRows = [
    {
      name: "Entity",
      value: "entity",
      open: true,
    },
    {
      name: "Topics",
      value: "topic",
    },
    {
      name: "Identity",
      value: "identity",
    },
  ];

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader>
        <TableHead sx={styles.tableHeader}>
          <TableCell>Findings</TableCell>
          <TableCell align="right">Count</TableCell>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => {
            const userLabels =
              appName && selectedUser && selectedFilter === "user"
                ? retrievalUserDetails.find(
                    (user) => user?.name === selectedUser
                  )?.frameworkRestrictedLabels[appName]
                : "";

            return (
              <CollapsableRow
                row={row}
                rowData={sensitiveData?.filter(
                  (data) =>
                    data?.labelType === row?.value && data?.messages?.length > 0
                )}
                selectedFilter={selectedFilter}
                selectedUser={selectedUser}
                selectedSensitiveLabel={selectedSensitiveLabel}
                onRowClick={onRowClick}
                userLabels={userLabels}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SensitiveMessageTable;
