import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import Tooltip from "./Tooltip";

interface KeyValueProps {
  label: string;
  valueIcon?: JSX.Element | null;
  value: string;
}

const KeyValueBlock = ({ label, valueIcon = null, value }: KeyValueProps) => {
  const theme = useTheme();
  const styles = {
    block: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    labelText: { fontSize: "11px" },
    tooltipChildren: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: theme.spacing(25),
    },
    ellipsis: {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    whiteSpaceNoWrap: {
      whiteSpace: "nowrap",
    },
  };

  return (
    <Box sx={styles.block}>
      <Typography sx={styles.labelText} color={theme.palette.surface30.main}>
        {label}
      </Typography>
      <Box sx={styles.tooltipChildren}>
        {valueIcon ? valueIcon : null}
        {value ? (
          <Tooltip title={value}>
            <div style={styles.ellipsis}>
              <Typography
                variant="caption"
                color={theme.palette.surface80.main}
                sx={styles.whiteSpaceNoWrap}>
                {value}
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <Typography variant="caption" color={theme.palette.surface80.main}>
            -
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default KeyValueBlock;
