import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";

export interface Props {
  date: string;
  data: Array<{ color: string; label: string; value: number }>;
  coordinates?: { x: number; y: number };
  tooltipMargin?: number;
  showCategoryTooltip?: boolean;
  category: string;
}

const LineChartTooltip = ({
  date,
  data,
  coordinates = { x: 0, y: 0 },
  tooltipMargin = 88,
  showCategoryTooltip,
  category = "",
}: Props) => {
  const theme = useTheme();
  const styles = {
    tooltipContainer: {
      zIndex: 20,
      top: coordinates?.y || 0 + "px",
      left: (coordinates?.x || 0) + tooltipMargin + "px",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      width: "184px",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface80.main,
      borderRadius: theme.spacing(0.5),
    },
    dateText: {
      fontWeight: 500,
      opacity: "0.5",
    },
    valueText: {
      fontWeight: 500,
    },
    rowBadge: (color: string) => ({
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: "50%",
      backgroundColor: color,
    }),
    dataContainer: {
      marginTop: theme.spacing(1.5),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    legends: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    legendBadge: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    tooltipData: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    capitalize: {
      textTransform: "capitalize",
    },
  };
  const value = data.find(
    (item) => item?.label?.toLowerCase() === category
  )?.value;
  return (
    <Box sx={styles.tooltipContainer}>
      <Typography
        variant="tooltip"
        color={theme.palette.surfaceMain.main}
        sx={styles.dateText}
      >
        {date}
      </Typography>
      <Box sx={styles.dataContainer}>
        {showCategoryTooltip ? (
          data?.map((dataItem, index) => (
            <Box sx={styles.legends} key={index}>
              <Box sx={styles.legendBadge}>
                <Box sx={styles.rowBadge(dataItem.color)}></Box>
                <Typography
                  variant="caption"
                  color={theme.palette.surfaceMain.main}
                >
                  {dataItem.label}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                color={theme.palette.surfaceMain.main}
                sx={styles.valueText}
              >
                {dataItem.value}
              </Typography>
            </Box>
          ))
        ) : (
          <Box sx={styles.tooltipData}>
            <Typography color={theme.palette.surfaceMain.main} variant="body1">
              {value}
            </Typography>
            <Typography
              color={theme.palette.surface0.main}
              variant="caption"
              sx={styles.capitalize}
            >
              {category}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LineChartTooltip;
