import { useTheme } from "@mui/material";
import * as d3 from "d3";
import moment from "moment";
import { useEffect, useState } from "react";
import Box from "./Box";
import LineChartTooltip from "./LineChartTooltip";

export interface Props {
  lineData: Array<{ category: string; issues: number; date: string }>;
  classNameForGraph: string;
  categories: Array<{ name: string; color: string; label: string }>;
  svgHeight?: number;
  tooltipMargin?: number;
  svgWidth?: number;
  ticks?: number;
  getDataForSpecificPoint: (date: string) => {};
  unit?: string;
  showCategoryTooltip?: boolean;
}

interface data {
  category: string;
  issues: number;
  date: string;
}

const LineChart = ({
  lineData,
  classNameForGraph,
  categories,
  svgHeight = 250,
  tooltipMargin,
  svgWidth,
  ticks,
  getDataForSpecificPoint,
  unit = "week",
  showCategoryTooltip = true,
}: Props) => {
  const [tooltipCoordinates, setTooltipCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [graphData, setGraphData] = useState({});
  const theme = useTheme();

  const styles = {
    chartContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
  };

  const getDataForDate = (date: Date) => {
    const formattedDate =
      unit === "week"
        ? moment(date).format("YYYY-MM-DD").toString()
        : unit === "day"
        ? moment(date).utc().format("YYYY-MM-DDTHH").concat(":00:00Z")
        : moment(date).utc().format("YYYY-MM-DDTHH:m").concat(":00Z");
    setGraphData({
      ...getDataForSpecificPoint(formattedDate),
    });
  };

  const drawLineChart = () => {
    const margin = {
      top: 10,
      right: 20,
      bottom: 50,
      left: svgHeight === 250 ? 70 : 20,
    };
    const width = parseFloat(d3.select(`.${classNameForGraph}`).style("width"));
    const height = svgHeight - margin.top - margin.bottom;

    const dates: string[] = lineData.map((line) => line?.date);
    let formatDate;
    if (unit === "week") {
      formatDate = d3.timeFormat("%d %b");
    } else {
      formatDate = (date) => date;
    }

    const parseDate = d3.timeParse("%Y-%m-%d");

    const xScale = d3
      .scaleTime()
      .rangeRound([0, width - margin.left - margin.right]);
    const yScale = d3.scaleLinear().rangeRound([height, 0]);
    const yAxis = d3
      .axisLeft(yScale)
      .tickSizeInner(-width)
      .tickSizeOuter(0)
      .ticks(ticks);
    let xExtent;
    if (unit === "week") {
      xExtent = d3.extent(dates, (d: string) => parseDate(d));
    } else {
      xExtent = d3.extent(dates, (d: string) => moment(d));
    }
    xScale.domain([xExtent[0] ?? 0, xExtent[1] ?? 1]);
    const max = d3.max(lineData, (d) => d.issues);
    const diffrence = max && (Math.floor(max / 5) + 1) * 5 - max;
    const yMax = max && diffrence && max + diffrence;

    yScale.domain([0, yMax ?? 0]);
    let xAxis;
    if (unit === "week") {
      xAxis = d3
        .axisBottom(xScale)
        .tickValues(dates.map((date: string) => parseDate(date) as Date))
        .ticks(d3.utcDay)
        .tickFormat(formatDate)
        .tickSizeOuter(0)
        .tickSizeInner(-width);
    } else {
      xAxis = d3
        .axisBottom(xScale)
        .ticks(unit === "day" ? d3.utcHour.every(4) : d3.utcMinute.every(10))
        .tickFormat((d: any) => {
          if (unit === "day") {
            return moment(d).format("H A");
          } else {
            return moment(d).format("h:mm");
          }
        })
        .tickSizeOuter(0)
        .tickSizeInner(-width);
    }

    const dataGrouped = d3.group(lineData, (d) => d.category);

    const color = d3
      .scaleOrdinal()
      .domain(categories.map((category) => category?.name))
      .range(categories.map((category) => category?.color));

    const line: any = d3
      .line()
      .x((d: any) => {
        if (unit === "week") {
          return xScale(parseDate(d.date) as Date);
        } else if (unit === "day") {
          return xScale(moment(d.date).toDate() as Date);
        } else {
          return xScale(moment(d.date).toDate() as Date);
        }
      })
      .y((d: any) => yScale(d.issues));

    d3.select(`.${classNameForGraph}`).selectAll("svg").remove();
    const svg = d3
      .select(`.${classNameForGraph}`)
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("transform", "translate(0,0)")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const chart = svg.append("g");

    dataGrouped.forEach((categoryData: {}, category: string) => {
      svg
        .append("path")
        .datum(categoryData)
        .attr("class", "multi-line")
        .attr("d", line)
        .style("stroke", (d) => color(category) as string)
        .style("stroke-width", "2px")
        .style("fill", "none")
        .attr("stroke-dasharray", function () {
          const length: number = this.getTotalLength();
          return length + " " + length;
        })
        .attr("stroke-dashoffset", function () {
          const length: number = this.getTotalLength();
          return length;
        })
        .transition()
        .duration(1000)
        .attr("stroke-dashoffset", 0);
    });

    const xAxisGroup = chart
      .append("g")
      .attr("class", "barBottomAxis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    const yAxisGroup = chart
      .append("g")
      .call(yAxis)
      .attr("class", "barBottomAxis");
    yAxisGroup.selectAll("text").attr("x", "-8");
    yAxisGroup.selectAll(".domain").remove();
    yAxisGroup.select(":nth-child(1) line").attr("stroke-width", "1");
    yAxisGroup.selectAll("line").attr("stroke-width", "0.5");
    xAxisGroup.selectAll(".tick").selectAll("line").remove();
    xAxisGroup.selectAll("text").attr("y", "16");

    const tooltipLine = svg
      .append("rect")
      .attr("class", "line")
      .attr("height", height + 5)
      .attr("transform", "translate(0,-5)")
      .attr("width", 1)
      .attr("fill", theme.palette.surface80.main);

    const lines: HTMLCollectionOf<SVGGeometryElement> =
      document.getElementsByClassName(
        "multi-line"
      ) as HTMLCollectionOf<SVGGeometryElement>;

    const mousePerLine = svg
      .selectAll(".mouse-per-line")
      .data(d3.range(0))
      .enter()
      .append("g")
      .attr("class", "mouse-per-line");

    mousePerLine
      .append("circle")
      .attr("r", 4)
      .style("fill", (d: number) => categories[d].color)
      .style("stroke-width", "1px")
      .style("opacity", "0");

    const onMouseMove = (event: Event) => {
      const mouse = d3.pointer(event);
      let x = mouse[0];
      const y = mouse[1];

      const value = xScale.invert(x);
      tooltipLine.attr("x", x);
      getDataForDate(value);

      const boundingCoords = document
        .querySelector(`.${classNameForGraph}`)
        ?.getBoundingClientRect();
      const xCoord = boundingCoords?.width
        ? boundingCoords?.width - x < 250
          ? x - (!tooltipMargin ? 230 : 200)
          : x
        : x;
      setTooltipCoordinates({ x: xCoord, y });
      setIsTooltipVisible(true);
      d3.selectAll(".mouse-per-line").attr("transform", function (d, i) {
        let beginning = 0,
          end = lines[i]?.getTotalLength(),
          target = 0;
        let pos = { x: 0, y: 0 };
        const trueFlag = true;
        while (trueFlag) {
          target = Math.floor((beginning + end) / 2);
          pos = lines[i]?.getPointAtLength(target);
          if ((target === end || target === beginning) && pos.x !== mouse[0]) {
            break;
          }
          if (pos?.x > mouse[0]) end = target;
          else if (pos?.x < mouse[0]) beginning = target;
          else break;
        }
        return "translate(" + mouse[0] + "," + pos?.y + ")";
      });
    };

    const onMouseOut = (event: Event) => {
      setIsTooltipVisible(false);
    };

    const mouseTracker = svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) => onMouseMove(event))
      .on("mouseout", (event) => onMouseOut(event))
      .on("mouseover", () => {
        d3.selectAll(".mouse-per-line circle").style("opacity", "1");
      });
  };

  useEffect(() => {
    drawLineChart();
  }, [lineData]);

  return (
    <Box sx={styles.chartContainer} id="chart-container">
      <div className={classNameForGraph}></div>
      <div className={`${classNameForGraph}`}></div>
      {isTooltipVisible && (
        <LineChartTooltip
          tooltipMargin={tooltipMargin}
          coordinates={tooltipCoordinates}
          date={graphData["date"]}
          category={lineData[0]?.category}
          data={[
            {
              color: theme.palette.peach.main,
              label: categories[0]?.label,
              value: graphData[categories[0]?.name],
            },
            {
              color: theme.palette.laurelGreen.main,
              label: categories[1]?.label,
              value: graphData[categories[1]?.name],
            },
            {
              color: theme.palette.paleBlue.main,
              label: categories[2]?.label,
              value: graphData[categories[2]?.name],
            },
            //{
            //  color: theme.palette.purpleStroke.main,
            //  label: 'DS Issues',
            //  value: graphData.dsIssues,
            //},
            //{
            //  color: theme.palette.pictonBlue.main,
            //  label: 'Cloud Issues',
            //  value: graphData.cloudIssues,
            //},
            //{
            //  color: theme.palette.redStroke.main,
            //  label: 'Permission Access',
            //  value: graphData.permissionAccess,
            //},
            //{
            //  color: theme.palette.mistyMoss.main,
            //  label: 'Live Access',
            //  value: graphData.liveAccess,
            //},
            //{
            //  color: theme.palette.yellowRed.main,
            //  label: 'Anomalous Access',
            //  value: graphData.anomalousAccess,
            //},
          ]}
          showCategoryTooltip={showCategoryTooltip}
        />
      )}
    </Box>
  );
};

export default LineChart;
