import React, { useState, useEffect } from "react";
import FormatUrl from "../../utils/UrlFormatter";
import { Toaster } from "../../utils/toaster";
import axios from "axios";
import { getUserId } from "../../utils/SessionHelper";
import DaxaLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import DaxaTitle from "../../assets/img/daxa-assets/daxa-title.svg";

const CompleteProfile = ({ setLoggendIn }) => {
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [id, setId] = useState(null);
  const [passwordNeeded, setPasswordNeeded] = useState(true);

  useEffect(() => {
    setLoggendIn(false);
    document.title = "Daxa AI - Complete Profile";
  }, []);

  const validatePhone = (number) => {
    let numberFormat = "^[0-9]+$";
    if (number.match(numberFormat) && number.length >= 10) {
      return true;
    } else {
      return false;
    }
  };

  const updateUser = (ev) => {
    var passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*-_<>{}(),.#?&])[A-Za-z\d@$!%*-_<>{}(),.#?&]{5,}$/;
    ev.preventDefault();

    if (!phone) {
      Toaster(3, "Phone required");
      return;
    }

    if (!validatePhone(phone)) {
      Toaster(3, "Invalid phone number");
      return;
    }

    if (passwordNeeded) {
      if (password && password.length >= 9) {
        if (password === confirmPassword) {
          if (!passwordRegex.test(password)) {
            Toaster(
              3,
              "password should contain atleast one uppercase letter, one number and one special character"
            );
            return;
          }
        } else {
          Toaster(3, "password and confirm password should be same");
          return;
        }
      } else {
        Toaster(3, "password should be greater than 8 character");
        return;
      }
    }

    let url = FormatUrl(`/user/user/${id}`);

    axios
      .patch(url, {
        email: email,
        password_created: passwordNeeded,
        phone: phone,
        status: "ACTIVE",
      })
      .then(function (response, error) {
        let email = response.data.email;
        if (passwordNeeded) {
          updatePassword(email);
        } else {
          if (localStorage.getItem("customerId")) {
            Toaster(1, "Profile created");
            const userId = getUserId();
            localStorage.clear();
            sessionStorage.clear();
            const url = FormatUrl(`/user/logout/${userId}`);
            window.location = url;
          } else {
            Toaster(1, "Profile created");
            window.location = "/";
          }
        }
      })

      .catch(function (error) {
        if (error.response) {
          Toaster(
            2,
            error.response?.data?.err?.message || "Failed to update profile"
          );
        } else {
          Toaster(2, "Failed to update profile");
        }
      });
  };

  const verifyEmail = async (id) => {
    let url1 = FormatUrl(`/user/user/${id}`);
    let res = await axios.get(url1);
    res = res.data;

    if (res.googleOrOkta === "google") {
      setPasswordNeeded(false);
    }
    setEmail(res.email);
    let url2 = FormatUrl(`/user/confirmEmail`);
    await axios.post(url2, {
      email: res.email,
    });
  };

  const updatePassword = (email) => {
    // for google login no password needed
    if (!passwordNeeded) {
      window.location = "/";
      return;
    }

    if (!password) {
      Toaster(3, "password required");
      return;
    }
    if (!confirmPassword) {
      Toaster(3, "confirmPassword required");
      return;
    }
    if (password !== confirmPassword) {
      Toaster(3, "password should match");
      return;
    }
    let url = FormatUrl(`/user/changePassword`);

    axios
      .post(url, {
        email: email,
        oldPassword: "Abcd@1234",
        newPassword: password,
      })
      .then(function (response, error) {
        // let data = response.data;
        if (localStorage.getItem("customerId")) {
          Toaster(1, "Profile created");
          const userId = getUserId();
          localStorage.clear();
          sessionStorage.clear();
          const url = FormatUrl(`/user/logout/${userId}`);
          window.location = url;
        } else {
          Toaster(1, "Profile created");
          window.location = "/";
        }
      })

      .catch(function (error) {
        console.log(error);
        Toaster(1, "Failed to update profile");
      });
  };

  useEffect(() => {
    let id = window.location.pathname.split("/")[2];
    setId(id);
    verifyEmail(id);
  }, []);

  return (
    <>
      <section className="sign-in-page">
        <div className="container p-0">
          <div className="logo-container">
            <div>
              <img
                src={DaxaLogo}
                alt="Daxa icon"
                className="daxa-logo"
                height={48}
              />
            </div>
            <div>
              <img
                src={DaxaTitle}
                alt="Daxa title"
                className="daxa-title"
                width={88}
              />
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-sm-12 align-self-center">
              <div className="sign-in-from">
                <form onSubmit={updateUser} autocomplete="off">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      disabled
                      value={email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Phone</label>
                    <input
                      type="text"
                      onChange={(ev) => setPhone(ev.target.value)}
                      className="form-control"
                      placeholder="Phone"
                    />
                  </div>
                  {passwordNeeded ? (
                    <div className="form-group">
                      <label htmlFor="email">Password</label>
                      <input
                        type="password"
                        onChange={(ev) => setPassword(ev.target.value)}
                        className="form-control"
                        placeholder="*******"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {passwordNeeded ? (
                    <div className="form-group">
                      <label htmlFor="email">Confirm password</label>
                      <input
                        type="password"
                        onChange={(ev) => setConfirmPassword(ev.target.value)}
                        className="form-control"
                        placeholder="*******"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="d-inline-block w-100">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={updateUser}>
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompleteProfile;
