import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Loader from "../common/Loader";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import { DASHBOARD_DATA_VIEW } from "../constants/constants";
import DataStoreTable from "./DataStoreTable";
import { TableCell } from "../common/Table";
import Tooltip from "../common/Tooltip";
import { getNumberAbbreviation } from "../../utils/commonUtils";
import Bar from "../common/Bar";

export interface Props {
  barColor: string;
  activeRegion: { data: any; region: string; regionCode: string };
  impactedDS: number;
  totalDS: number;
  dataDistribution: Array<{
    dataType: string;
    numberOfRecords: number;
    totalValue: number;
    impacted: number;
  }>;
  isFetchingData: boolean;
  isFrameworkView: boolean;
}

const GeoDataStoreCard = ({
  barColor,
  activeRegion,
  impactedDS,
  totalDS,
  dataDistribution,
  isFetchingData,
  isFrameworkView,
}: Props) => {
  const theme = useTheme();
  const { dashboardViewLense } = useContext(AppContext);
  const dataView = dashboardViewLense === DASHBOARD_DATA_VIEW;
  const styles = {
    cardContainer: {
      display: "flex",
      width: "100%",
      height: "450px",
      flexDirection: "column",
      gap: theme.spacing(3),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      boxShadow: `0px 0px 10px 0px ${theme.palette.surface0.main}, 0px 2px 3px -2px rgba(9, 12, 31, 0.70)`,
    },
    cardHeader: {
      width: "100%",
      display: "flex",
      // justifyContent: "center",
      padding: theme.spacing(2),
    },
    cardHeaderText: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    regionsText: {
      fontWeight: theme.typography.fontWeightLight,
    },
    moreDetailsButton: {
      display: "flex",
      marginLeft: "auto",
    },
    infoContent: {
      paddingX: theme.spacing(2),
    },
    impactedDataText: {
      fontFamily: "Manrope",
      fontSize: "28px",
    },
    barCell: {
      width: "35%",
    },
    donutTooltip: {
      tooltip: {
        sx: {
          bgcolor: theme.palette.surface80.main,
          "& .MuiTooltip-arrow": {
            color: theme.palette.surface80.main,
          },
        },
      },
    },
    tableImpactValue: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    barWithValue: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    barSize: {
      minWidth: "150px",
    },
  };

  const getViewDetailsLink = () => {
    if (dataView) {
      return activeRegion.regionCode === "all"
        ? "/datastore"
        : `/datastore?region=${activeRegion?.regionCode}`;
    } else {
      return "/datastore?search=llm";
    }
  };

  const RecordsTooltip = ({ numberOfRecords, totalRecords }) => {
    const theme = useTheme();
    const styles = {
      tooltipContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1),
        gap: theme.spacing(0.5),
      },
    };
    return (
      <Box sx={styles.tooltipContainer}>
        <Typography
          variant="caption"
          align="right"
          color={theme.palette.surface0.main}
        >
          {getNumberAbbreviation(numberOfRecords)}
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.surface40.main}
          align="right"
        >
          / {getNumberAbbreviation(totalRecords)}
        </Typography>
      </Box>
    );
  };

  const tableCols = [
    {
      title: <TableCell>Data Type</TableCell>,
      render: (row: any) => (
        <TableCell>
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {row?.dataType}
          </Typography>
        </TableCell>
      ),
    },
    {
      title: <TableCell sx={styles.barCell}>Number of Records</TableCell>,
      render: (row: any) => (
        <TableCell>
          <Tooltip
            placement="bottom"
            arrow
            title={
              <RecordsTooltip
                numberOfRecords={row.numberOfRecords}
                totalRecords={row.totalValue}
              />
            }
            componentsProps={styles.donutTooltip}
          >
            <div>
              <Bar
                barFaceColor={barColor}
                barBackColor={theme.palette.surface20.main}
                rectWidth={(row.numberOfRecords / row.totalValue) * 100}
                xLabel="Number of Items"
                totalValue={row.totalValue}
                barLabel="Label"
                index={1}
                isLastBar={false}
                barValue={row.numberOfRecords}
              />
            </div>
          </Tooltip>
        </TableCell>
      ),
    },
    {
      title: (
        <TableCell align="right">
          {dataView ? "Impacted Data Stores" : "LLMs"}
        </TableCell>
      ),
      render: (row: any) => (
        <TableCell align="right" sx={styles.tableImpactValue}>
          <Typography
            variant="caption"
            color={theme.palette.primaryBlue.main}
            sx={styles.tableImpactValue}
          >
            {row.impacted}
          </Typography>
        </TableCell>
      ),
    },
  ];

  const frameWorkTableCols = [
    {
      title: <TableCell>Sensitive Messages</TableCell>,
      render: (row: any) => (
        <TableCell>
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {row?.dataType}
          </Typography>
        </TableCell>
      ),
    },
    {
      title: <TableCell></TableCell>,
      render: (row: any) => (
        <TableCell>
          <Box sx={styles.barWithValue}>
            <Typography variant="caption" sx={styles.tableImpactValue}>
              {row.numberOfRecords}
            </Typography>
            <Tooltip
              placement="bottom"
              arrow
              title={
                <RecordsTooltip
                  numberOfRecords={row.numberOfRecords}
                  totalRecords={row.totalValue}
                />
              }
              componentsProps={styles.donutTooltip}
            >
              <div style={styles.barSize}>
                <Bar
                  barFaceColor={barColor}
                  barBackColor={theme.palette.surface20.main}
                  rectWidth={(row.numberOfRecords / row.totalValue) * 100}
                  xLabel="Number of Items"
                  totalValue={row.totalValue}
                  barLabel="Label"
                  index={1}
                  isLastBar={false}
                  barValue={row.numberOfRecords}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      ),
    },
  ];

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Box sx={styles.cardHeaderText}>
          <Typography variant="caption" color={theme.palette.surface80.main}>
            {dataView
              ? "Data Stores Distribution By Sensitive Data"
              : !isFrameworkView
              ? "LLM Distribution"
              : "Application Distribution"}
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={styles.regionsText}
          >
            {activeRegion.region}
          </Typography>
        </Box>
        {!isFrameworkView ? (
          <TextButton
            hideText={true}
            endIcon={<KeyboardArrowRightIcon />}
            sx={styles.moreDetailsButton}
            link={getViewDetailsLink()}
          >
            View All
          </TextButton>
        ) : (
          ""
        )}
      </Box>
      <Box sx={styles.infoContent}>
        <Typography
          sx={styles.impactedDataText}
          color={theme.palette.surface70.main}
        >
          {!isFrameworkView ? `${impactedDS}/${totalDS}` : impactedDS}
        </Typography>
        <Typography variant="caption" color={theme.palette.surface70.main}>
          {dataView
            ? "All Impacted Data Stores"
            : !isFrameworkView
            ? "All LLMs"
            : "Frameworks"}
        </Typography>
      </Box>
      <DataStoreTable
        barColor={barColor}
        dataDistribution={dataDistribution}
        isFetchingData={isFetchingData}
        dataView={dataView}
        tableCols={!isFrameworkView ? tableCols : frameWorkTableCols}
      />
    </Box>
  );
};

export default GeoDataStoreCard;
