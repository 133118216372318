import { useTheme } from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import Typography from "../common/Typography";
import gcpIcon from "../../assets/img/gcp.svg";
import awsIcon from "../../assets/img/awsIcon.svg";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import { DASHBOARD_AI_VIEW, sensitiveInfoTags } from "../constants/constants";
import { useHistory } from "react-router-dom";
import {
  getStringFromArray,
  isDataWithinDefinedDate,
} from "../../utils/commonUtils";
import LangchainIcon from "../../assets/img/langchain.svg";
import Bar from "../common/Bar";
import { fetchFrameworkSlice } from "../../redux/slices/dashboardSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import {
  frameworkData,
  modelData,
  totalActiveUsers,
  totalPolicyViolationCount,
  totalRetrievals,
  totalSensitiveMessageCount,
} from "../../mock-data/frameworkData";
import Loader from "../common/Loader";
import Tooltip from "../common/Tooltip";

const ModelAppListCard = () => {
  const [selectedTab, setSelectedTab] = useState("framework");
  const [models, setModels] = useState([]);
  const [frameworks, setFrameworks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { getAIDatastores, dashboardViewLense, dataStoreViolation } =
    useContext(AppContext);
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    cardContainer: {
      maxHeight: "300px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    cardHeader: {
      paddingX: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    modelName: {
      marginLeft: theme.spacing(1),
    },
    tabPanel: {
      flex: 1,
      overflow: "hidden",
      height: "100%",
      display: "flex",
    },
    tableContainer: {
      maxHeight: "100%",
      overflow: "scroll",
    },
    langchainCell: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "start",
    },
    langchainVersion: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    barCell: {
      width: "150px",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    table: {
      height: "100%",
    },
    noHover: {
      pointerEvents: "none",
    },
    tableCell: {
      "&.MuiTableCell-root": {
        paddingX: theme.spacing(1.5),
        border: "none",
      },
    },
  };

  const tabList = [
    { label: "Applications", value: "framework" },
    { label: "LLM", value: "llm" },
  ];

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const getFrameworksData = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const res = await dispatch(fetchFrameworkSlice(payload));
    if (res.payload) {
      setFrameworks(res.payload.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrameworksData();
  }, []);

  useEffect(() => {
    getAIDatastores();
  }, [dashboardViewLense]);

  const getSensitiveDataTags = (sensitiveInfo) => {
    let sensitiveTag = "";
    return sensitiveTag?.concat(
      sensitiveInfo?.map(
        (sensitiveData) => sensitiveInfoTags[sensitiveData?.name]
      )
    );
  };

  const modelStatus = (modelName) => {
    const model = dataStoreViolation[1]?.data?.find(
      (model) => model.name === modelName
    );
    return model ? "Deployed" : "Under Finetuning";
  };

  useEffect(() => {
    if (dataStoreViolation.length > 0) {
      setModels(dataStoreViolation[0]?.data);
    }
  }, [dataStoreViolation]);

  const navigateToLLM = (datastoreId, assetId) => {
    if (datastoreId && assetId) {
      history.push(`/llm/${datastoreId}/${assetId.replace("/", "_")}`);
    }
  };

  const getResponsePromptCount = (promptsData, keyword) => {
    let count = 0;
    //promptsData
    //  .find((dataItem) => dataItem.tabValue === keyword)
    //  ?.data.forEach((msgData) => {
    //    const messageArr = msgData.messages.filter((message) =>
    //      isDataWithinDefinedDate(sensitiveDataFilter, message.retrivalDate)
    //    );
    //    count += messageArr.length;
    //  });
    return count;
  };

  const navigateToLangchain = (langchainData) => {
    history.push(`/framework/${langchainData?.id}`);
  };

  const getFrameworkObject = (name) => {
    return frameworkData.find((framework) => framework.name === name);
  };

  const getModelObject = (assetId) => {
    return modelData.find((model) => model.assetId === assetId);
  };

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {tabList.map((tab: { label: string }, index: number) => (
            <Tab {...tab} key={index} />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index="llm" sx={styles.tabPanel}>
        <TableContainer sx={styles.tableContainer}>
          <Table sx={styles.table}>
            <TableHead>
              <TableCell sx={styles.tableCell}>Name</TableCell>
              <TableCell sx={styles.tableCell}>Policy Violations</TableCell>
              <TableCell sx={styles.tableCell}>Status</TableCell>
              <TableCell sx={styles.tableCell}>Category</TableCell>
              <TableCell sx={styles.tableCell}>Labels</TableCell>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                models?.map((model: any) => {
                  const modelInfo = getModelObject(model?.assetId);
                  return (
                    <TableRow
                      key={model?.id}
                      onClick={() =>
                        navigateToLLM(model?.datastoreId, model?.assetId)
                      }
                    >
                      <TableCell sx={styles.tableCell}>
                        <img
                          src={model.cloud === "gcp" ? gcpIcon : awsIcon}
                          alt=""
                          height={16}
                        />
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                          sx={styles.modelName}
                        >
                          {model?.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ ...styles.barCell, ...styles.tableCell }}
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {modelInfo?.policyViolations || 0}
                        </Typography>
                        <Bar
                          isLastBar={false}
                          xLabel={""}
                          barBackColor={theme.palette.surface20.main}
                          barFaceColor={theme.palette.lightMagenta.main}
                          rectWidth={
                            ((modelInfo?.policyViolations || 0) /
                              totalPolicyViolationCount) *
                            100
                          }
                          index={model?.name}
                          totalValue={modelInfo?.policyViolations || 0}
                          barLabel={""}
                          barValue={modelInfo?.policyViolations || 0}
                          type={""}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {modelStatus(model?.name || "")}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {capitalizeFirstLetter(model?.datastoreType)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {getSensitiveDataTags(model?.sensitiveInfo)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={styles.noHover}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel index="framework" value={selectedTab} sx={styles.tabPanel}>
        <TableContainer>
          <Table sx={styles.table}>
            <TableHead>
              <TableCell sx={styles.tableCell}>Name</TableCell>
              <TableCell sx={styles.tableCell}>Policy Violations</TableCell>
              <TableCell sx={styles.tableCell}>Retrievals</TableCell>
              <TableCell sx={styles.tableCell}>Active Users</TableCell>
              <TableCell sx={styles.tableCell}>Labels</TableCell>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                frameworks?.map((framework: any) => {
                  const frameworkDetailsObj = getFrameworkObject(
                    framework?.name
                  );
                  return (
                    <TableRow onClick={() => navigateToLangchain(framework)}>
                      <TableCell sx={styles.tableCell}>
                        <Box sx={styles.langchainCell}>
                          <img src={LangchainIcon} alt="Langchain" />
                          <Box sx={styles.langchainVersion}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {framework?.name}
                            </Typography>
                            <Typography
                              variant="tooltip"
                              color={theme.palette.surface60.main}
                            >
                              {frameworkDetailsObj?.version}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ ...styles.barCell, ...styles.tableCell }}
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {frameworkDetailsObj?.policyViolations}
                        </Typography>
                        <Bar
                          isLastBar={false}
                          xLabel={""}
                          barBackColor={theme.palette.surface20.main}
                          barFaceColor={theme.palette.pictonBlue.main}
                          rectWidth={
                            ((frameworkDetailsObj?.policyViolations || 0) /
                              totalPolicyViolationCount) *
                            100
                          }
                          index={framework.name}
                          totalValue={
                            frameworkDetailsObj?.policyViolations || 0
                          }
                          barLabel={""}
                          barValue={frameworkDetailsObj?.policyViolations || 0}
                          type={""}
                        />
                      </TableCell>

                      <TableCell sx={styles.tableCell}>
                        <Box sx={styles.barCell}>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            {frameworkDetailsObj?.retrievals}
                          </Typography>
                          <Bar
                            isLastBar={false}
                            xLabel={""}
                            barBackColor={theme.palette.surface20.main}
                            barFaceColor={theme.palette.lightMagenta.main}
                            rectWidth={
                              ((frameworkDetailsObj?.retrievals || 0) /
                                totalRetrievals) *
                              100
                            }
                            index={framework.name}
                            totalValue={frameworkDetailsObj?.retrievals || 0}
                            barLabel={""}
                            barValue={frameworkDetailsObj?.retrievals || 0}
                            type={""}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={styles.tableCell} align="center">
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {frameworkDetailsObj?.activeUsers}
                        </Typography>
                        {/*<Bar
                            isLastBar={false}
                            xLabel={""}
                            barBackColor={theme.palette.surface20.main}
                            barFaceColor={theme.palette.lightMagenta.main}
                            rectWidth={
                              ((frameworkDetailsObj?.activeUsers || 0) /
                                totalActiveUsers) *
                              100
                            }
                            index={framework.name}
                            totalValue={frameworkDetailsObj?.activeUsers || 0}
                            barLabel={""}
                            barValue={frameworkDetailsObj?.activeUsers || 0}
                            type={""}
                          />*/}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Tooltip
                          title={frameworkDetailsObj?.labels?.join(", ")}
                        >
                          <div>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {frameworkDetailsObj?.labels
                                ? getStringFromArray(
                                    [...frameworkDetailsObj?.labels],
                                    2
                                  )
                                : "-"}
                            </Typography>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={styles.noHover}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Box>
  );
};

export default ModelAppListCard;
