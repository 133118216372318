import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import LangchainIcon from "../../assets/img/langchainIcon.svg";
import UsersIcon from "../../assets/img/users.svg";
import Divider from "./Divider";
import AWSIcon from "../../assets/img/awsIcon.svg";
import { getImageForNode } from "../../utils/commonUtils";

const NetworkTooltip = ({
  tooltipData,
  tooltipCoordinates,
  getPolicyDetails,
}) => {
  const theme = useTheme();
  const styles = {
    parentContainer: {
      position: "absolute",
      top: tooltipCoordinates?.y - 180 + "px" || 0,
      left: tooltipCoordinates?.x - 90 + "px" || 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface100.main,
      width: "210px",
      borderRadius: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      zIndex: "50",
    },
    violationDetails: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    metricContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    violationMetrics: {
      display: "flex",
      justifyContent: "space-between",
    },
    appName: {
      display: "flex",
      flexDirection: "column",
    },
    versionDetails: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tooltipHeader: {
      display: "flex",
      gap: theme.spacing(1),
    },
    divider: {
      height: "10px",
    },
    fontWeightThin: {
      fontWeight: theme.typography.fontWeightLight,
    },
    usersTooltip: {
      display: "flex",
      width: "100%",
    },
    usersHeader: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    userAppName: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
    },
    activeUserCount: {
      marginLeft: "auto",
    },
    groupPair: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  };

  return (
    <Box sx={styles.parentContainer}>
      {tooltipData?.type !== "users" ? (
        <Box sx={styles.tooltipHeader}>
          <img
            src={getImageForNode(tooltipData.type)?.imgSrc}
            alt="icon"
            height={24}
            width={24}
          />
          <Box sx={styles.appName}>
            <Typography variant="tooltip" color={theme.palette.surface40.main}>
              {tooltipData?.name}
            </Typography>
            <Box sx={styles.versionDetails}>
              <Typography
                variant="tooltip"
                sx={styles.fontWeightThin}
                color={theme.palette.surface40.main}
              >
                {tooltipData.versionDetails}
              </Typography>
              <Divider
                orientation="vertical"
                sx={styles.divider}
                color={theme.palette.surface70.main}
              />
              {tooltipData?.cloud === "aws" && (
                <img src={AWSIcon} alt="cloud" height={20} width={20} />
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.usersTooltip}>
          <Box sx={styles.usersHeader}>
            <img src={UsersIcon} alt="icon" height={24} width={24} />
            <Box sx={styles.userAppName}>
              <Typography
                variant="tooltip"
                color={theme.palette.surface20.main}
              >
                Active Users
              </Typography>
              <Typography
                variant="tooltip"
                color={theme.palette.surface40.main}
              >
                {tooltipData?.appName}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.activeUserCount}>
            <Typography
              variant="caption"
              color={theme.palette.surfaceMain.main}
            >
              {tooltipData?.activeUsers}
            </Typography>
          </Box>
        </Box>
      )}
      <Divider orientation="horizontal" color={theme.palette.surface70.main} />
      {tooltipData?.type !== "users" ? (
        <Box sx={styles.violationDetails}>
          <Typography variant="caption" color={theme.palette.critical.main}>
            {tooltipData.policyName}
          </Typography>
          <Box sx={styles.metricContainer}>
            {tooltipData?.policyViolations?.length > 0 && (
              <Box sx={styles.violationMetrics}>
                <Typography
                  variant="caption"
                  color={theme.palette.surface0.main}
                >
                  Policy Violations
                </Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.surface0.main}
                >
                  {getPolicyDetails(tooltipData?.policyViolations || [])}
                </Typography>
              </Box>
            )}
            {(tooltipData?.sensitiveMessages?.length > 0 ||
              tooltipData?.sensitiveRecords?.length > 0) && (
              <Box sx={styles.violationMetrics}>
                <Typography
                  variant="caption"
                  color={theme.palette.surface0.main}
                >
                  {tooltipData?.name === "falcon-7b-bf16"
                    ? "Sensitive Identifiers"
                    : "Sensitive Messages"}
                </Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.surface0.main}
                >
                  {getPolicyDetails(
                    tooltipData?.sensitiveMessages ||
                      tooltipData?.sensitiveRecords ||
                      []
                  )}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            {tooltipData?.groups?.map((group) => (
              <Box sx={styles.groupPair}>
                <Typography
                  variant="caption"
                  color={theme.palette.surface20.main}
                >
                  {group.name}
                </Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.surfaceMain.main}
                >
                  {group.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NetworkTooltip;
