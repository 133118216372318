import { useTheme } from "@mui/material";
import Box from "../common/Box";
import { Dialog, DialogContent, DialogTitle } from "../common/Dialog";
import Typography from "../common/Typography";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import TextButton from "../common/TextButton";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";

const ViewHashes = ({ open, packageInfo, handleClose }) => {
  const theme = useTheme();
  const styles = {
    dialogContent: {
      display: "flex",
      gap: theme.spacing(2),
    },
    packageName: {
      fontSize: "18px",
      width: "80%",
    },
    textBlock: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      justifyContent: "space-between",
    },
    hashText: {
      display: "flex",
      alignItems: "end",
      gap: theme.spacing(3.25),
    },
    copyIcon: {
      cursor: "pointer",
      color: theme.palette.surface80.main,
    },
  };

  const copyHash = (copyText) => {
    navigator.clipboard.writeText(copyText);
    Toaster(TOASTER_TYPES.SUCCESS, "Hash Digest copied to clipboard!");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle handleClose={handleClose}>
        <Typography
          color={theme.palette.surface80.main}
          sx={styles.packageName}
        >
          {packageInfo?.packageName}
        </Typography>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.textBlock}>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Algorithm
          </Typography>
          <Typography variant="body2">SHA256</Typography>
        </Box>
        <Box sx={styles.textBlock}>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Hash Digest
          </Typography>
          <Box sx={styles.hashText}>
            <Typography variant="body2">
              {packageInfo?.sha.slice(0, 15)}...
            </Typography>
            <TextButton onClick={() => copyHash(packageInfo?.sha)}>
              <ContentCopyRoundedIcon fontSize="small" sx={styles.copyIcon} />
            </TextButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewHashes;
