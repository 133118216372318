export const retrievalUserDetails = [
  {
    name: "John Doe",
    designation: "Underwriter",
    groups: ["underwriter_group@acmecorp.com"],
    frameworkRestrictedLabels: {
      ProductInsights: ["Board Meeting Notes", "Consulting Agreement"],
      "AcmeMortgageApp-1": [],
      "HealthCareApp-1": ["Symmetric Key"],
      "SupportApp-1": ["PII - SSN", "Consulting Agreement"],
    },
  },
  {
    name: "Tom Platt",
    designation: "Sales Intern",
    groups: ["sales_group@acmecorp.com"],
    frameworkRestrictedLabels: {
      ProductInsights: ["Board Meeting Notes", "Consulting Agreement"],
      "AcmeMortgageApp-1": [
        "Intellectual Property",
        "Bias",
        "Sexual Harassment",
        "Client Secret",
        "Merger Agreement",
      ],
      "HealthCareApp-1": ["Symmetric Key"],
      "SupportApp-1": ["PII - SSN", "Consulting Agreement"],
    },
  },
  {
    name: "Alex Smith",
    designation: "Customer Care",
    groups: ["customer_care@acmecorp.com"],
    frameworkRestrictedLabels: {
      ProductInsights: ["Board Meeting Notes", "Consulting Agreement"],
      "AcmeMortgageApp-1": [
        "PII - SSN",
        "PII - CC",
        "Intellectual Property",
        "Bias",
        "Financial Report",
      ],
      "HealthCareApp-1": ["Symmetric Key"],
      "SupportApp-1": ["PII - SSN", "Consulting Agreement"],
    },
  },
];

export const fileDetails = [
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
    authorizedGroups: [
      "underwriter_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
    accessCount: 35,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
    authorizedGroups: [
      "underwriter_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
    accessCount: 1,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
    authorizedGroups: [],
    accessCount: 1,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-loans.pdf",
    authorizedGroups: [
      "underwriter_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
    accessCount: 7,
    owner: "John Doe",
  },
];

export const entityData = [
  "API Key",
  "JWT Token",
  "Client Access Token",
  "Client Secret",
  "Symmetric Key",
  "MRN",
  "PII - SSN",
  "PII - CC",
];

export const topicData = [
  "Medical Advice",
  "Harmful Advice",
  "Sexual Harassment",
  "Bias",
  "Board Meeting Notes",
  "Consulting Agreement",
  "Customer List",
  "Distribution/Partner Agreement",
  "Executive Severance Agreement",
  "Financial Report",
  "Loan And Security Agreement",
  "Merger Agreement",
  "NDA",
  "Patent Application Fillings",
  "Price List",
  "Settlement Agreement",
  "Employee Agreement",
  "Enterprise Agreement",
  "Product Roadmap",
  "Intellectual Property",
];
