import React from "react";
import infoIcon from "../../../assets/img/icons/ico-blue-white-info.svg";
import deleteIcon from "../../../assets/img/icons/ico-delete-grey.svg";
import "./InfoModal.scss";

const InfoModal = ({ handleModal, data }) => {
  const info = [
    {
      title1: "Daxa AI User Onboarding Cloudformation Stack:",
      sub_title1:
        "As part of Daxa AI user onboarding AWS Cloudformation templates are executed on users aws account to create the below aws resources",
      services: [
        { title: "AWS VPC", values: ["Public Subnet", "Private Subnet"] },
        {
          title: "AWS IAM Roles",
          values: [
            "CDCrossAccountRole",
            "InternalRole",
            "CDSCannerEnableInternalRole",
          ],
        },
        { title: "AWS Ec2 Server", values: [] },
        {
          title: "AWS Lambda Functions",
          values: [
            "EnableCDAWSScanners",
            "UpdateCDAWSScanners",
            "CDScannerLambda",
            "CDScannerV2Lambda",
            "CDDataProfilerLambda",
            "CDScannerDataAggregator",
            "CDScannerDataAccessLambda",
          ],
        },
        {
          title: "AWS Events Rule",
          values: ["Public Subnet", "Private Subnet"],
        },
        {
          title: "AWS VPC",
          values: [
            "CDEventRuleSecurityHub(aws.securityhub)",
            "CDEventRuleSecurityHubPastEvenets(cd.aws.securityhub)",
            "CDEventRuleDataScannerLiveness(liveness)",
            "CDEventRuleDataScanner(data-scanner)",
            "CDScannerScheduledRule",
            "CDScannerDataAccessScheduledRule",
            "CDScannerScheduledClassificationFinish",
            "CDScannerScheduledRuleReRun",
            "CDScannerDataAccessStopLambdaRule",
          ],
        },
        {
          title: "AWS Step Functions",
          values: ["CDStateMachine"],
        },
        { title: "AWS KMS Key", values: [] },
        {
          title: "AWS SQS Queue",
          values: [
            "CDExportToS3Queue",
            "CDDBSnapshotQueue",
            "CDS3ClassificationQueue",
            "CDS3ClassificationAggregatorQueue",
          ],
        },
        {
          title: "AWS SNS Topic",
          values: [
            "CDDBSnapshotSNSTopic",
            "CDExportFileToS3SNSTopic",
            "CDAggregatorSNSTopic",
            "CDRunDataProfilerSNSTopic",
          ],
        },
        { title: "AWS S3 Bucket", values: [] },
        { title: "AWS Event EventBusPolicy", values: ["CDEventBusPolicy"] },
      ],
    },
    {
      title1: "AWS VPC",
      sub_title1:
        "VPC is used for provisining the CD scanner EC2 instance. This vpc includes the public and private subnets - CD Scanner Ec2 server downloads the libs/packages from internet for this purpose it needs public subnet. CD services uses the AWS SSM service to run command on Scanner Ec2 remotely.",
    },
    {
      title1: "AWS IAM Roles",
      title2: "CDCrossAccountRole",
      title3: "InternalRole",
      title4: "CDSCannerEnableInternalRole",
      sub_title1:
        "This cross account role is assume by Daxa AI side to access the cloudwatch logs and s3 lists etc. This role only having the read permission access for users resources.",
      sub_title2:
        "This role is internal to user aws account and not used outside. This role is attached to Scanner Lambda functions. These scanner lambda functions scans the resources in aws account and send the findings to event bus. This role having readonly access to RDS, DynamoDB and redshift services and readwrire access to EC2, S3, SQS and cloudwatch.",
      sub_title3:
        "This role used by custom resource which enables the security hub, inspector2 and gaurdduty services on users account. To enable this it needs the access to users security hub, inspector2 and gaurdduty. This role is also limited to user aws account and not used outside it.",
    },
    {
      title1: "AWS Ec2 Server",
      sub_title1:
        "CD Scanner EC2 server used to scan the ebs volumes in users aws account. It takes the snapshot of exisitng ebs volume and create the new volumes to attach it to EC2 server.",
    },
    {
      title1: "AWS Lambda Functions",
      title2: "EnableCDAWSScanners",
      title3: "UpdateCDAWSScanners",
      title4: "CDScannerLambda",
      title5: "CDScannerV2Lambda",
      title6: "CDDataProfilerLambda",
      title7: "CDScannerDataAggregator",
      title8: "CDScannerDataAccessLambda",
      sub_title1:
        "This lambda functions enables the security hub, inspector2 and gaurdduty services for users account on all region as part of launch stack. It skips the already enabled services. On delete stack operation this lambda function rollback the services state. During rollback it skips those services which were already enabled before launch stack.",
      sub_title2:
        "This lambda function involve in upgrading the cfn templates. The addional features published by Daxa AI.",
      sub_title3:
        "This lambda function does the data discovery and classification for self managed datastore like - MySQL, MongoDB running on ec2.",
      sub_title4:
        "This lambda function does the data discovery and classification for managed datastore like - RDS, DocumentDB etc.",
      sub_title5:
        "This lambda function handels the managed data store classification",
      sub_title6:
        "This is used to aggregate classification results about S3 and RDS scanning.",
      sub_title7: "This is used to capture the Data Flows.",
    },
    {
      title1: "AWS Events Rule",
      title2: "CDEventRuleSecurityHub",
      title3: "CDEventRuleSecurityHubPastEvenets",
      title4: "CDEventRuleDataScannerLiveness",
      title5: "CDEventRuleDataScanner",
      title6: "CDScannerScheduledRule",
      title7: "CDScannerDataAccessScheduledRule",
      title8: "CDScannerScheduledClassificationFinish",
      title9: "CDScannerScheduledRuleReRun",
      title10: "CDScannerDataAccessStopLambdaRule",
      sub_title1:
        "This is used to send the security hub events to Daxa AI side.",
      sub_title2:
        "This is used to send the old security hub events i.e before onboarding to Daxa AI side.",
      sub_title3:
        "This is redirecting liveness i.e Data Flow events to Daxa AI side.",
      sub_title4: "This is redirecting data scanner events to Daxa AI side.",
      sub_title5:
        "This rule is initiated for checking the RDS data scanning status.",
      sub_title6:
        "This rule triggeres the Data Access Lambda at specified frequency.",
      sub_title7: "This rule triggeres data scanning finish check.",
      sub_title8:
        "This rule is initiating the data scanning with defined frequency.",
      sub_title9: "This rule is responsible for the cost control processes.",
    },
    {
      title1: "AWS Step Functions",
      title2: "CDStateMachine",
      sub_title1:
        "Starting point for managed and selfmanaged data store discovery and classifications. This defines the workflow for lambda function execution.",
    },
    {
      title1: "AWS KMS Key",
      sub_title1: "This is used while exporting the RDS to S3 bucket.",
    },
    {
      title1: "AWS SQS Queue",
      title2: "CDExportToS3Queue",
      title2: "CDDBSnapshotQueue",
      title2: "CDS3ClassificationQueue",
      title2: "CDS3ClassificationAggregatorQueue",
      sub_title1:
        "This Queue is used for managed data store AWS RDS DB snapshot export",
      sub_title2:
        "This Queue is used for managed data store AWS RDS DB snapshot",
      sub_title3: "This Queue is used for data scanning.",
      sub_title4: "This Queue is used for data scanning.",
    },
    {
      title1: "CDEventBusPolicy",
      sub_title1:
        "This policy added to put the events on event bus from Cloud Defesne side. This is needed for the cost control processes.",
    },
    {
      title1: "AWS SNS Topic",
      title2: "CDDBSnapshotSNSTopic",
      title3: "CDExportFileToS3SNSTopic",
      title4: "CDAggregatorSNSTopic",
      title5: "CDRunDataProfilerSNSTopic",
      sub_title1:
        "AWS RDS DB Snapshot completion notification send to this Topic",
      sub_title2:
        "AWS RDS DB Snapshot export completion notification send to this Topic",
      sub_title3:
        "CD Datascanner lambda function uses this for classification purpose.",
      sub_title4: "Used for Daxa AI data discovery and classifications",
    },
    {
      title1: "AWS S3 Bucket",
      sub_title1:
        "AWS S3 bucket is used to store the RDS DB snapshot export and other classification results.",
    },
  ];

  return (
    <div id="info-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-start align-items-center">
              <img src={infoIcon} className="mr-3" />
              <span className="header-title">{data?.title || ""}</span>
              <img
                src={deleteIcon}
                className="mr-3 delete-icon"
                onClick={handleModal}
              />
            </div>
            <div className="modal-body ">
              {info.map((info, index) => (
                <div key={index}>
                  <h5 className="title-1">{info.title1}</h5>
                  <h6 className="sub-title-1">{info.sub_title1}</h6>
                  {info.services && (
                    <ul>
                      {info.services.map((service, index) => (
                        <li key={index}>
                          {service.title}
                          <ol>
                            {service.values.map((value, idx) => (
                              <li key={idx}>{value}</li>
                            ))}
                          </ol>
                        </li>
                      ))}
                    </ul>
                  )}

                  {info.title2 ? (
                    <h5 className="title-2">&#8226; {info.title2}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title2 ? (
                    <h6 className="sub-title-2">{info.sub_title2}</h6>
                  ) : (
                    ""
                  )}
                  {info.title3 ? (
                    <h5 className="title-2">&#8226; {info.title3}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title3 ? (
                    <h6 className="sub-title-2">{info.sub_title3}</h6>
                  ) : (
                    ""
                  )}
                  {info.title4 ? (
                    <h5 className="title-2">&#8226; {info.title4}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title4 ? (
                    <h6 className="sub-title-2">{info.sub_title4}</h6>
                  ) : (
                    ""
                  )}

                  {info.title5 ? (
                    <h5 className="title-2"> &#8226; {info.title5}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title5 ? (
                    <h6 className="sub-title-2">{info.sub_title5}</h6>
                  ) : (
                    ""
                  )}

                  {info.title6 ? (
                    <h5 className="title-2">&#8226; {info.title6}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title6 ? (
                    <h6 className="sub-title-2">{info.sub_title6}</h6>
                  ) : (
                    ""
                  )}

                  {info.title7 ? (
                    <h5 className="title-2">&#8226; {info.title7}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title7 ? (
                    <h6 className="sub-title-2">{info.sub_title7}</h6>
                  ) : (
                    ""
                  )}

                  {info.title8 ? (
                    <h5 className="title-2">&#8226; {info.title8}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title8 ? (
                    <h6 className="sub-title-2">{info.sub_title8}</h6>
                  ) : (
                    ""
                  )}

                  {info.title9 ? (
                    <h5 className="title-2">&#8226; {info.title9}</h5>
                  ) : (
                    ""
                  )}
                  {info.sub_title9 ? (
                    <h6 className="sub-title-2">{info.sub_title9}</h6>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
