import { CollapsibleGraph } from "../../common/CollapsibleGraph";
import { useEffect, useMemo, useState } from "react";
import Box from "../../common/Box";
import ChainIcon from "../../../assets/img/chainIcon.svg";
import { Popover } from "../../common/Popover";
import { LangchainPopover } from "./LangchainPopover";
import { LangchainGraphNode } from "./LangchainGraphNode";
import GPTIcon from "../../../assets/img/gptIcon.svg";
import HuggingFaceIcon from "../../../assets/img/hugging.png";

export const ChainGraph = ({
  isChild = false,
  className = "",
  chainData = {},
}: {
  isChild: boolean;
  className: string;
  chainData: any;
}) => {
  const [treeData, setTreeData] = useState<any>({});
  const [selectedNode, setSelectedNode] = useState<any>("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (Object.keys(chainData)?.length > 0) {
      setTreeData(chainData);
    }
  }, [chainData]);

  const childrenLength = treeData?.children?.filter(
    (val) => Object.keys(val)?.length > 0
  )?.length;

  const styles = {
    chainGraphWrapper: {
      position: "relative",
      height:
        childrenLength > 0
          ? 100 * (childrenLength === 1 ? childrenLength + 1 : childrenLength)
          : 200,
      width: childrenLength > 0 ? 600 : 228,
      borderRadius: "5px",
      border: "1px solid #3B3E52",
      paddingX: "12px",
    },
    chainGraph: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    chainIcon: {
      display: "flex",
      gap: "4px",
    },
    chainText: {
      fontSize: "11px",
      color: "#fff",
    },
    modelDetail: {
      position: "absolute",
      left: "12px",
      bottom: "12px",
    },
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const selectedElement = document.getElementsByClassName("selectedNode")[0];
    selectedElement?.classList?.remove("selectedNode");
    setAnchorEl(null);
  };

  const openPopover = (e, id) => {
    if (selectedNode !== id) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClick = (e, d) => {
    if (selectedNode === d?.data?.name) {
      const elem: any = document.getElementById(d?.data?.name);
      elem.classList.remove("selectedNode");
      setSelectedNode("");
      setAnchorEl(null);
    } else {
      const elem: any = document.getElementById(d?.data?.name);
      const allElements = document.querySelectorAll("*");
      allElements.forEach((element) => {
        element.classList.remove("selectedNode");
      });
      elem.classList.add("selectedNode");
      setSelectedNode(d?.data?.name);
      setPopoverData(d?.data);
      openPopover(e, d?.data?.name);
    }
  };

  const plotGraph = useMemo(() => {
    return (
      <CollapsibleGraph
        className={className}
        graphData={treeData}
        handleClick={handleClick}
        classNameForGraph="chain-graph-wrapper"
        isChild={isChild}
        nodeDepth={100}
        nodeLength={childrenLength > 0 ? childrenLength + 1 : 2}
      />
    );
  }, [treeData]);

  return (
    <Box sx={styles.chainGraph}>
      <Box sx={styles.chainIcon}>
        <img src={ChainIcon} alt="Chain Icon" height={16} width={16} />
        <div style={styles.chainText}>{className}</div>
      </Box>
      <Box className="chain-graph-wrapper" sx={styles.chainGraphWrapper}>
        {plotGraph}
        <Popover open={open} anchorEl={anchorEl} handleClose={handleClose}>
          <LangchainPopover
            handleClose={handleClose}
            popoverData={popoverData}
          />
        </Popover>
        <Box sx={styles.modelDetail}>
          <LangchainGraphNode
            icon={
              chainData?.modelDetail?.vendor === "Huggingface"
                ? HuggingFaceIcon
                : GPTIcon
            }
            title={chainData?.modelDetail?.name}
            description={chainData?.modelDetail?.vendor}
            isNode={false}
          />
        </Box>
      </Box>
    </Box>
  );
};
