import { useEffect } from "react";
import PolicySetting from "../../components/settings/PolicySetting";

const PolicySettings = ({ setPath }) => {
  useEffect(() => {
    document.title = "Daxa AI - Policy";

    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <PolicySetting />
    </>
  );
};

export default PolicySettings;
