import { useTheme } from "@mui/material";
import MUITooltip, { TooltipProps } from "@mui/material/Tooltip";

const Tooltip = ({ arrow = true, ...props }: TooltipProps) => {
  const theme = useTheme();
  const styles = {
    tooltip: {
      "& .MuiTooltip-tooltip": {
        backgroundColor: theme.palette.surface80.main,
        color: theme.palette.surface10.main,
      },
      "& .MuiTooltip-arrow:before": {
        backgroundColor: theme.palette.surface80.main,
      },
    },
  };

  return (
    <MUITooltip
      arrow={arrow}
      componentsProps={{
        popper: {
          sx: styles.tooltip,
        },
      }}
      {...props}
    />
  );
};

export default Tooltip;
