import moment from "moment";

var currentDate = new Date();
var currentDateString = currentDate.toISOString().split("T")[0];

export const genAITrends = {
  retrievals: {
    day: [
      { at: currentDateString + "T07:00:00Z", retrievals: 0 },
      { at: currentDateString + "T08:00:00Z", retrievals: 1 },
      { at: currentDateString + "T09:00:00Z", retrievals: 0 },
      { at: currentDateString + "T10:00:00Z", retrievals: 9 },
      { at: currentDateString + "T11:00:00Z", retrievals: 5 },
      { at: currentDateString + "T12:00:00Z", retrievals: 3 },
      { at: currentDateString + "T13:00:00Z", retrievals: 0 },
      { at: currentDateString + "T14:00:00Z", retrievals: 0 },
      { at: currentDateString + "T15:00:00Z", retrievals: 2 },
      { at: currentDateString + "T16:00:00Z", retrievals: 5 },
      { at: currentDateString + "T17:00:00Z", retrievals: 3 },
      { at: currentDateString + "T18:00:00Z", retrievals: 2 },
      { at: currentDateString + "T19:00:00Z", retrievals: 1 },
      { at: currentDateString + "T20:00:00Z", retrievals: 0 },
      { at: currentDateString + "T21:00:00Z", retrievals: 0 },
      { at: currentDateString + "T22:00:00Z", retrievals: 1 },
      { at: currentDateString + "T23:00:00Z", retrievals: 0 },
      { at: currentDateString + "T24:00:00Z", retrievals: 4 },
      { at: currentDateString + "T01:00:00Z", retrievals: 0 },
      { at: currentDateString + "T02:00:00Z", retrievals: 0 },
      { at: currentDateString + "T03:00:00Z", retrievals: 2 },
      { at: currentDateString + "T04:00:00Z", retrievals: 1 },
    ],
    week: [
      { at: moment().subtract(6, "days").format("YYYY-MM-DD"), retrievals: 8 },
      { at: moment().subtract(5, "days").format("YYYY-MM-DD"), retrievals: 3 },
      { at: moment().subtract(4, "days").format("YYYY-MM-DD"), retrievals: 7 },
      { at: moment().subtract(3, "days").format("YYYY-MM-DD"), retrievals: 4 },
      { at: moment().subtract(2, "days").format("YYYY-MM-DD"), retrievals: 9 },
      { at: moment().subtract(1, "days").format("YYYY-MM-DD"), retrievals: 2 },
      { at: moment().subtract(0, "days").format("YYYY-MM-DD"), retrievals: 6 },
    ],
    hour: [
      { at: currentDateString + "T02:00:00Z", retrievals: 0 },
      { at: currentDateString + "T02:10:00Z", retrievals: 1 },
      { at: currentDateString + "T02:11:00Z", retrievals: 2 },
      { at: currentDateString + "T02:20:00Z", retrievals: 3 },
      { at: currentDateString + "T02:20:00Z", retrievals: 3 },
      { at: currentDateString + "T02:30:00Z", retrievals: 0 },
      { at: currentDateString + "T02:40:00Z", retrievals: 0 },
      { at: currentDateString + "T02:50:00Z", retrievals: 4 },
    ],
  },
  users: {
    day: [
      { at: currentDateString + "T07:00:00Z", users: 1 },
      { at: currentDateString + "T08:00:00Z", users: 0 },
      { at: currentDateString + "T09:00:00Z", users: 0 },
      { at: currentDateString + "T10:00:00Z", users: 1 },
      { at: currentDateString + "T11:00:00Z", users: 0 },
      { at: currentDateString + "T12:00:00Z", users: 0 },
      { at: currentDateString + "T13:00:00Z", users: 0 },
      { at: currentDateString + "T14:00:00Z", users: 0 },
      { at: currentDateString + "T15:00:00Z", users: 2 },
      { at: currentDateString + "T16:00:00Z", users: 0 },
      { at: currentDateString + "T17:00:00Z", users: 0 },
      { at: currentDateString + "T18:00:00Z", users: 2 },
      { at: currentDateString + "T19:00:00Z", users: 0 },
      { at: currentDateString + "T20:00:00Z", users: 0 },
      { at: currentDateString + "T21:00:00Z", users: 1 },
      { at: currentDateString + "T22:00:00Z", users: 0 },
      { at: currentDateString + "T23:00:00Z", users: 0 },
      { at: currentDateString + "T24:00:00Z", users: 0 },
      { at: currentDateString + "T01:00:00Z", users: 0 },
      { at: currentDateString + "T02:00:00Z", users: 1 },
      { at: currentDateString + "T03:00:00Z", users: 0 },
      { at: currentDateString + "T04:00:00Z", users: 1 },
    ],
    week: [
      { at: moment().subtract(6, "days").format("YYYY-MM-DD"), users: 0 },
      { at: moment().subtract(5, "days").format("YYYY-MM-DD"), users: 3 },
      { at: moment().subtract(4, "days").format("YYYY-MM-DD"), users: 0 },
      { at: moment().subtract(3, "days").format("YYYY-MM-DD"), users: 4 },
      { at: moment().subtract(2, "days").format("YYYY-MM-DD"), users: 0 },
      { at: moment().subtract(1, "days").format("YYYY-MM-DD"), users: 1 },
      { at: moment().subtract(0, "days").format("YYYY-MM-DD"), users: 1 },
    ],
    hour: [
      { at: currentDateString + "T02:00:00Z", users: 0 },
      { at: currentDateString + "T02:10:00Z", users: 1 },
      { at: currentDateString + "T02:11:00Z", users: 0 },
      { at: currentDateString + "T02:20:00Z", users: 0 },
      { at: currentDateString + "T02:20:00Z", users: 0 },
      { at: currentDateString + "T02:30:00Z", users: 0 },
      { at: currentDateString + "T02:40:00Z", users: 1 },
      { at: currentDateString + "T02:50:00Z", users: 0 },
    ],
  },
  documents: {
    day: [
      { at: currentDateString + "T07:00:00Z", documents: 5 },
      { at: currentDateString + "T08:00:00Z", documents: 0 },
      { at: currentDateString + "T09:00:00Z", documents: 0 },
      { at: currentDateString + "T10:00:00Z", documents: 3 },
      { at: currentDateString + "T11:00:00Z", documents: 2 },
      { at: currentDateString + "T12:00:00Z", documents: 0 },
      { at: currentDateString + "T13:00:00Z", documents: 0 },
      { at: currentDateString + "T14:00:00Z", documents: 1 },
      { at: currentDateString + "T15:00:00Z", documents: 0 },
      { at: currentDateString + "T16:00:00Z", documents: 0 },
      { at: currentDateString + "T17:00:00Z", documents: 4 },
      { at: currentDateString + "T18:00:00Z", documents: 1 },
      { at: currentDateString + "T19:00:00Z", documents: 1 },
      { at: currentDateString + "T20:00:00Z", documents: 0 },
      { at: currentDateString + "T21:00:00Z", documents: 0 },
      { at: currentDateString + "T22:00:00Z", documents: 5 },
      { at: currentDateString + "T23:00:00Z", documents: 0 },
      { at: currentDateString + "T24:00:00Z", documents: 8 },
      { at: currentDateString + "T01:00:00Z", documents: 0 },
      { at: currentDateString + "T02:00:00Z", documents: 1 },
      { at: currentDateString + "T03:00:00Z", documents: 5 },
      { at: currentDateString + "T04:00:00Z", documents: 2 },
    ],
    week: [
      { at: moment().subtract(6, "days").format("YYYY-MM-DD"), documents: 3 },
      { at: moment().subtract(5, "days").format("YYYY-MM-DD"), documents: 1 },
      { at: moment().subtract(4, "days").format("YYYY-MM-DD"), documents: 0 },
      { at: moment().subtract(3, "days").format("YYYY-MM-DD"), documents: 0 },
      { at: moment().subtract(2, "days").format("YYYY-MM-DD"), documents: 2 },
      { at: moment().subtract(1, "days").format("YYYY-MM-DD"), documents: 0 },
      { at: moment().subtract(0, "days").format("YYYY-MM-DD"), documents: 1 },
    ],
    hour: [
      { at: currentDateString + "T02:00:00Z", documents: 1 },
      { at: currentDateString + "T02:10:00Z", documents: 0 },
      { at: currentDateString + "T02:11:00Z", documents: 1 },
      { at: currentDateString + "T02:20:00Z", documents: 0 },
      { at: currentDateString + "T02:20:00Z", documents: 2 },
      { at: currentDateString + "T02:30:00Z", documents: 0 },
      { at: currentDateString + "T02:40:00Z", documents: 0 },
      { at: currentDateString + "T02:50:00Z", documents: 1 },
    ],
  },
};
