import { InputAdornment, useTheme } from "@mui/material";
import MUITextField, { TextFieldProps } from "@mui/material/TextField";

export const TextField = (props: TextFieldProps) => {
  const theme = useTheme();
  const styles = {
    textField: {
      ".MuiFormLabel-root": {
        color: theme.palette.surface40.main,
        "&.Mui-focused": {
          color: theme.palette.primaryBlue.main,
        },
      },
      ".MuiInputBase-root:before": {
        borderBottom: `1px solid ${theme.palette.surface40.main}`,
      },
      ".MuiInputBase-root:after": {
        borderBottom: `1px solid ${theme.palette.primaryBlue.main}`,
      },
      ".MuiInputAdornment-root": {
        display: "none",
      },
      ".MuiInputBase-input": {
        fontSize: "14px",
        color: props.value
          ? theme.palette.surface80.main
          : theme.palette.surface50.main,
      },
      ".MuiInputBase-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.surface40.main}`,
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.primaryBlue.main}`,
          },
        },
      },

      ".MuiFormHelperText-root": {
        marginX: 0,
      },
      ...props.sx,
    },
  };

  return (
    <MUITextField
      {...props}
      InputProps={{
        startAdornment: <InputAdornment position="start"></InputAdornment>,
      }}
      sx={styles.textField}
    />
  );
};
