import moment from "moment";
import { useSelector } from "react-redux";
import LineChart from "../../common/LineChart";

interface Props {
  categories: { name: string; label: string; color: string }[];
  graphData: any[];
  svgHeight?: number;
  ticks?: number;
  showCategoryTooltip?: boolean;
}

const PromptLineGraph = ({
  categories,
  graphData,
  svgHeight,
  ticks,
  showCategoryTooltip = true,
}: Props) => {
  const promptFilter = useSelector(
    (state: any) => state.dashboard.promptFilter
  );

  const getDataForSpecificPoint = (formattedDate: string) => {
    let trendsData = {};
    categories.forEach((category) => {
      const value =
        graphData.find(
          (data) =>
            data.date === formattedDate && data.category === category.name
        )?.issues || 0;

      trendsData = { ...trendsData, [category.name]: value };
    });
    return { ...trendsData, date: moment(formattedDate).format("YYYY-MMM-DD") };
  };

  return (
    <LineChart
      unit={promptFilter}
      getDataForSpecificPoint={getDataForSpecificPoint}
      tooltipMargin={30}
      lineData={graphData}
      categories={categories}
      classNameForGraph="lineChartForPrompts"
      svgHeight={svgHeight}
      ticks={ticks}
      svgWidth={350}
      showCategoryTooltip={showCategoryTooltip}
    />
  );
};

export default PromptLineGraph;
