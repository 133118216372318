import { useState } from "react";
import Box from "../../common/Box";
import TextButton from "../../common/TextButton";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Divider, useTheme } from "@mui/material";
import Typography from "../../common/Typography";
import S3Icon from "../../../assets/img/AWS_S3.svg";
import GoogleDriveIcon from "../../../assets/img/google-drive.png";
import NotionIcon from "../../../assets/img/notion.png";
import MarkIcon from "../../../assets/img/markDown.svg";
import SharePointLoader from "../../../assets/img/sharepoint.png";
import SlackLoaderIcon from "../../../assets/img/slack.png";
import { Select } from "../../common/Select";
import Search from "../../common/Search";
import { DataSourceMessageDetails } from "./DataSourceMessageDetails";

//const tabData = [
//  {
//    value: 1,
//    label: "S3 Directory Loader",
//    outOf: 12,
//    icon: S3Icon,
//    source: "s3://loan-approval-data-098998/approvals/",
//    sourceType: "directory",
//    updatedAt: "2023-11-09T14:14:45",
//  },
//];

export const DataSourceTab = ({ tabData, messagesData, frameworkData }) => {
  const [isSummary, setIsSummary] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tabData[0]);
  const [directory, setDirectory] = useState("");
  const theme = useTheme();
  const styles = {
    summaryCard: {
      padding: "8px 0 24px 0",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    fontWeight700: {
      fontWeight: 700,
    },
    fontLight: {
      fontSize: "10px",
      marginLeft: 1,
    },
    dFlex: {
      display: "flex",
    },
    alignEnd: {
      alignItems: "end",
    },
    bottom4: {
      marginBottom: 0.5,
    },
    gap4: {
      gap: 1,
    },
    summaryTab: (selected: boolean) => ({
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      padding: "8px 16px",
      background: theme.palette.surface10.main,
      border: `1px solid ${
        selected ? "#2190F7" : theme.palette.surface20.main
      }`,
      borderRadius: "2px",
      width: "fit-content",
      cursor: "pointer",
    }),
    summaryToggle: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    textButton: {
      width: "fit-content",
    },
    gap56: {
      gap: theme.spacing(7),
    },
    snippetDetails: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      paddingY: theme.spacing(4),
    },
    snippetHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    sortByText: {
      fontSize: "13px",
    },
    messageDetailsHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    sortByBox: {
      display: "flex",
      alignItems: "end",
      gap: theme.spacing(1.5),
    },
    gap24: {
      gap: theme.spacing(3),
    },
  };

  const getIconForLoader = (loader) => {
    switch (loader) {
      case "S3 Directory Loader":
        return S3Icon;
      case "GoogleDriveLoader":
        return GoogleDriveIcon;
      case "Notion DB Loader":
        return NotionIcon;
      case "SharePoint Loader":
        return SharePointLoader;
      case "Slack API Loader":
        return SlackLoaderIcon;
      default:
        return S3Icon;
    }
  };

  return (
    <Box>
      <Box sx={styles.summaryCard}>
        <Box sx={{ ...styles.dFlex, ...styles.gap24 }}>
          {tabData?.map((item) => (
            <Box
              sx={styles.summaryTab(selectedTab?.label === item.label)}
              onClick={() => setSelectedTab(item)}
            >
              <Box sx={{ ...styles.dFlex, ...styles.alignEnd }}>
                <Typography
                  color={theme.palette.critical.main}
                  variant="h5"
                  sx={styles.fontWeight700}
                >
                  {frameworkData?.frameworkSummary?.name === "HealthCareApp-1"
                    ? "2"
                    : frameworkData?.frameworkSummary?.name ===
                      "AcmeMortgageApp-1"
                    ? "2"
                    : "1"}
                </Typography>
                <Typography
                  color={theme.palette.surface80.main}
                  variant="caption"
                  sx={styles.bottom4}
                >
                  /{" "}
                  {frameworkData?.frameworkSummary?.name === "HealthCareApp-1"
                    ? "8"
                    : frameworkData?.frameworkSummary?.name ===
                      "AcmeMortgageApp-1"
                    ? "34"
                    : "12"}
                </Typography>
                <Typography
                  color={theme.palette.surface80.main}
                  sx={{ ...styles.fontLight, ...styles.bottom4 }}
                >
                  Files with restricted data
                </Typography>
              </Box>
              <Box sx={{ ...styles.dFlex, ...styles.gap4 }}>
                <img
                  alt={item?.label}
                  src={getIconForLoader(item.label)}
                  height="14"
                  width="14"
                />
                <Typography
                  color={theme.palette.surface80.main}
                  variant="body2"
                >
                  {item.label}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={styles.summaryToggle}>
          <TextButton
            onClick={() => setIsSummary(!isSummary)}
            endIcon={
              isSummary ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
            }
            sx={styles.textButton}
          >
            Summary
          </TextButton>

          {isSummary ? (
            <Box sx={{ ...styles.dFlex, ...styles.gap56 }}>
              <Box>
                <Typography
                  color={theme.palette.surface30.main}
                  variant="caption"
                >
                  Source
                </Typography>
                <Typography variant="body2">{selectedTab?.source}</Typography>
              </Box>
              <Box>
                <Typography
                  color={theme.palette.surface30.main}
                  variant="caption"
                >
                  Source Type
                </Typography>
                <Typography variant="body2">
                  {selectedTab.sourceType}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color={theme.palette.surface30.main}
                  variant="caption"
                >
                  Last Modified
                </Typography>
                <Typography variant="body2">{selectedTab.updatedAt}</Typography>
              </Box>
              {selectedTab?.groups && (
                <Box>
                  <Typography
                    color={theme.palette.surface30.main}
                    variant="caption"
                  >
                    Authorized Groups
                  </Typography>
                  <Typography variant="body2">
                    {selectedTab.groups.join(", ")}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Divider variant="fullWidth" color={theme.palette.surface20.main} />
      <Box sx={styles.snippetDetails}>
        <Box sx={styles.snippetHeader}>
          <Typography variant="body2">Snippets by Labels</Typography>
          <Box sx={styles.messageDetailsHeader}>
            <Box sx={styles.sortByBox}>
              <Typography sx={styles.sortByText}>View by</Typography>
              <Select />
            </Box>
            <Box sx={styles.sortByBox}>
              <Typography sx={styles.sortByText}>Sort by</Typography>
              <Select />
            </Box>
            <Search
              placeholder="Search By Keyword"
              value={""}
              onChange={(e) => {}}
              onClear={() => {}}
              onKeyDown={(e) => {}}
            />
          </Box>
        </Box>
        <DataSourceMessageDetails
          messageData={messagesData}
          selectedLoaderIndex={tabData.findIndex(
            (tab) => tab.label === selectedTab.label
          )}
          directoryValue={(link) => setDirectory(link)}
          frameworkData={frameworkData}
        />
      </Box>
    </Box>
  );
};
