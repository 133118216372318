import MUIRadioGroup from "@mui/material/RadioGroup";
import MUIRadio from "@mui/material/Radio";

export const Radio = (args) => {
  return <MUIRadio {...args} />;
};

export const RadioGroup = (args) => {
  return <MUIRadioGroup {...args} />;
};
