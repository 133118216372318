import Close from "@mui/icons-material/Close";
import { IconButton, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Autocomplete } from "../../common/AutoComplete";
import Box from "../../common/Box";
import Button from "../../common/Button";
import Checkbox from "../../common/Checkbox";
import { Dialog, DialogActions, DialogTitle } from "../../common/Dialog";
import { Radio, RadioGroup } from "../../common/Radio";
import { TextField } from "../../common/TextField";
import Typography from "../../common/Typography";

const SuggestLabelDialog = ({ labels, open, onClose, onSubmit, value }) => {
  const [selectedLabel, setSelectedLabel] = useState("");
  const [isEmptyLabel, setIsEmptyLabel] = useState(false);
  const theme = useTheme();
  const styles = {
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
    },
    dialogTitleText: {
      fontSize: "18px",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
    },
    radioButtonContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
    },
    radioButtonLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    labelText: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    textFieldBox: {
      width: "100%",
      paddingY: theme.spacing(2),
    },
  };

  const submitLabel = () => {
    if (selectedLabel && selectedLabel !== "") {
      onSubmit(selectedLabel);
    } else {
      setIsEmptyLabel(true);
    }
    onCloseDialog();
  };

  const onCloseDialog = () => {
    setIsEmptyLabel(false);
    setSelectedLabel("");
    onClose();
  };

  useEffect(() => {
    setSelectedLabel(value);
  }, [value]);

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <Box sx={styles.dialogHeader}>
        <Typography sx={styles.dialogTitleText}>
          Please Suggest An Accurate Label
        </Typography>
        <IconButton onClick={onCloseDialog}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={styles.dialogContent}>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          If you think this message should be under a different label, please
          suggest a more suitable topic for it.
        </Typography>
        <Box sx={styles.radioButtonContainer}>
          <Autocomplete
            placeholder="Type"
            variant="standard"
            options={labels}
            value={selectedLabel}
            helperText={isEmptyLabel && "Kindly select a label"}
            error={isEmptyLabel}
            onChange={(e: any, newValue: string) => {
              if (newValue !== "") {
                setIsEmptyLabel(false);
              }
              setSelectedLabel(newValue);
            }}
            //renderOption={(props, option, { selected }) => (
            //  <li {...props}>
            //    <Checkbox
            //      label={option}
            //      style={{ marginRight: 8 }}
            //      checked={selected}
            //    />
            //    {option}
            //  </li>
            //)}
          />
          <Box sx={styles.textFieldBox}>
            {selectedLabel === "Other" && (
              <TextField
                variant="standard"
                fullWidth
                placeholder="Type Here"
                onChange={(e: any) => {}}
              />
            )}
          </Box>
        </Box>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={onCloseDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={submitLabel}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuggestLabelDialog;
