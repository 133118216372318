import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import * as Yup from "yup";
import integrationIcon from "../../../assets/img/icons/settings/integration.svg";
import {
  listIssueTypesAsync,
  listJiraProjectsAsync,
  updateIntegrationAsync,
} from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import { getUserId } from "../../../utils/SessionHelper";
import { LOADER_CONFIG } from "../../../utils/Constants";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import Button from "../../common/Button";

// Functional component for Add Integration.
const UpdateJiraIntegrationModal = ({
  handleModal,
  refreshIntegrations,
  card,
}) => {
  const dispatch = useDispatch();
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [projects, setProjects] = useState([]);
  const [issues, setIssues] = useState([]);
  const loading = useSelector((state) => state.globalOperation.loading);

  // Formik
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      host: "",
      project: "",
      issue: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please enter username."),
      password: Yup.string().required("Please enter password."),
      host: Yup.string().required("Please enter host."),
    }),
    onSubmit: (values) => {
      updateIntegration({ ...values });
    },
  });

  const listJiraProjects = async (values) => {
    dispatch(showLoading());
    const resp = await dispatch(
      listJiraProjectsAsync({
        host: values.host,
        username: values.username,
        password: values.password,
      })
    );
    if (resp && resp.payload) {
      dispatch(hideLoading());
      setProjects(resp.payload.projects);
    } else {
      dispatch(hideLoading());
    }
  };

  const listIssueType = async (values) => {
    dispatch(showLoading());
    const resp = await dispatch(
      listIssueTypesAsync({
        host: values.host,
        username: values.username,
        password: values.password,
        project: values.project,
      })
    );

    if (resp && resp.payload && !resp.payload.err) {
      dispatch(hideLoading());
      setIssues(resp.payload.issues);
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp?.payload?.err?.message);
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (card) {
      formik.setFieldValue("username", card?.username);
      formik.setFieldValue("password", card?.password);
      formik.setFieldValue("host", card?.host);
      formik.setFieldValue("project", card?.project?.id);
      formik.setFieldValue(
        "issue",
        card?.issueType && card?.issueType?.length > 0 ? card?.issueType[0] : ""
      );
      if (!projects.length) {
        listJiraProjects(card);
        listIssueType(card);
      }
    }
  }, [card]);

  const handleCancel = () => {
    handleModal(false);
  };

  const getProjects = (id) => {
    return projects.find((project) => project.id === id);
  };

  const updateIntegration = async (values) => {
    dispatch(showLoading());
    setConnectionFailed(false);
    const resp = await dispatch(
      updateIntegrationAsync({
        username: values.username,
        password: values.password,
        host: values.host,
        project: getProjects(values.project.trim()),
        issue: [values.issue],
        id: card._id,
        userId: getUserId(),
      })
    );
    if (resp && resp.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "Integration updated successfully.");
      dispatch(hideLoading());
      refreshIntegrations();
      handleModal(false);
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp.payload.err.message);
      dispatch(hideLoading());
      setConnectionFailed(true);
    }
  };

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-start align-items-center">
                  <img src={integrationIcon} className="mr-3" />
                  <span>Update Integration</span>
                </div>
                <div className="modal-body">
                  <label className="label">
                    Username: <span className="mandatory-icon">*</span>
                  </label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter Username"
                        name="name"
                        maxLength={50}
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("username", e.target.value);
                        }}
                        value={formik.values.username}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.username && formik.touched.username && (
                        <div className="error-message">
                          {formik.errors.username}
                        </div>
                      )}
                    </div>
                  </div>
                  <label className="label">
                    Password: <span className="mandatory-icon">*</span>
                  </label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        type="password"
                        id="name"
                        placeholder="**********"
                        name="name"
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("password", e.target.value);
                        }}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <div className="error-message">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <label className="label">
                    Host: <span className="mandatory-icon">*</span>
                  </label>
                  <div className="row py-2">
                    <div className="col col-sm-12 workday-subheading-value">
                      <input
                        type="text"
                        id="host"
                        placeholder="Enter Host"
                        name="host"
                        maxLength={50}
                        className="form-control"
                        onChange={(e) => {
                          formik.setFieldValue("host", e.target.value);
                        }}
                        value={formik.values.host}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.host && formik.touched.host && (
                        <div className="error-message">
                          {formik.errors.host}
                        </div>
                      )}
                    </div>
                  </div>

                  {projects?.length ? (
                    <>
                      <label className="label">
                        Project: <span className="mandatory-icon">*</span>
                      </label>
                      <div className="row py-2">
                        <div className="col col-sm-12 workday-subheading-value">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue("project", e.target.value);
                            }}
                            value={formik.values.project.id}
                            onBlur={formik.handleBlur}
                            className="form-control"
                          >
                            {projects.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {issues?.length ? (
                    <>
                      <label className="label">
                        Issue Type: <span className="mandatory-icon">*</span>
                      </label>

                      <div className="row py-2">
                        <div className="col col-sm-12 workday-subheading-value">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue("issue", e.target.value);
                            }}
                            value={formik.values.issue}
                            onBlur={formik.handleBlur}
                            className="form-control"
                          >
                            {issues.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {connectionFailed && (
                    <div className="row mt-2">
                      <div className="col-9 offset-md-3 pl-0 text-danger">
                        Something went wrong. Please try again.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>

                  <span className="separator mx-3"></span>

                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default UpdateJiraIntegrationModal;
