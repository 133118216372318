import moment from "moment";

export const frameworkPromptSummaryData = [
  {
    name: "SupportApp-1",
    description: "Support productivity co-pilot for level-1 and level-2 teams",
    loaderData: [
      {
        value: 1,
        label: "SharePoint Loader",
        outOf: 12,
        source:
          "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/",
        sourceType: "directory",
        updatedAt: "08-07-2024 14:14:45",
      },
      {
        value: 1,
        label: "Slack API Loader",
        outOf: 12,
        source: "http://loan-approval-data-098998/approvals/",
        sourceType: "directory",
        updatedAt: "11-09-2023 14:14:45",
      },
    ],
    policyViolations: [
      {
        count: 36,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM accessible from assets with security issues that could facilitate malicious activity.",
        lastViolatedAt: "2023-11-13T06:06:08.356000",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "Topic - Product Roadmap",
          labelType: "topic",
          messages: [
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 10:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-14 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://daxaai.sharepoint.com/loan-approval-data-098998",
                  type: "sharepoint",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
      [
        {
          sensitiveLabel: "Topic - Product Roadmap",
          labelType: "topic",
          messages: [
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-14 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "http://loan-approval-data-098998/approvals/product-kb.pdf",
              nodesChain: [
                {
                  name: "http://loan-approval-data-098998/approvals/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "http://loan-approval-data-098998/approvals/",
                  type: "slack",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
  {
    name: "ProductInsights",
    description: "Chatbot for Product team using Notion knowledge base",
    loaderData: [
      {
        value: 1,
        label: "Notion DB Loader",
        outOf: 12,
        source: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/",
        sourceType: "API",
        updatedAt: "02-26-2024 02:12:52",
      },
    ],
    policyViolations: [
      {
        count: 24,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM accessible from assets with security issues that could facilitate malicious activity.",
        lastViolatedAt:
          moment().subtract(1, "days").format("DD MMM YYYY").toString() +
          ", 10:24:45",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "Topic - Product Roadmap",
          labelType: "topic",
          messages: [
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 10:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-14 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 10:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-14 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-15 11:24:45",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
  {
    name: "HealthCareApp-1",
    description: "Medical assistant for basic health and dietary questions",
    loaderData: [
      {
        value: 1,
        label: "S3 Directory Loader",
        outOf: 12,
        source: "s3://loan-approval-data-098998/approvals/",
        sourceType: "directory",
        updatedAt: "11-11-2023 16:16:23",
      },
    ],
    policyViolations: [
      {
        count: 5,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
        lastViolatedAt: "2023-11-11T06:06:08.556000",
        policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
        policyViolationName: "Sensitive identifiers in prompt session",
        remediation:
          "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
      {
        count: 36,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
        lastViolatedAt: "2023-11-10T06:06:08.556000",
        policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "Topic - Medical Advice",
          labelType: "topic",
          messages: [
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2023-11-10 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "PHI - MRN",
          labelType: "entity",
          messages: [
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2023-11-11 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2023-11-11 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2023-11-11 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2023-11-11 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2023-11-11 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
  {
    name: "AcmeMortgageApp-1",
    description: "Mortgage approval assistant for Underwriter team",
    loaderData: [
      {
        value: 1,
        label: "GoogleDriveLoader",
        outOf: 12,
        source:
          "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg",
        sourceType: "Folder",
        updatedAt: "02-24-2024 12:43:45",
        groups: ["Underwriter"],
      },
    ],
    policyViolations: [
      {
        count: 24,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
        lastViolatedAt:
          moment().subtract(0, "days").format("DD MMM YYYY").toString() +
          ", 08:01:32",
        policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
        policyViolationName: "Sensitive identifiers in prompt session",
        remediation:
          "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
      {
        count: 12,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description: "",
        lastViolatedAt:
          moment().subtract(1, "days").format("DD MMM YYYY").toString() +
          ", 12:21:43",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
      {
        count: 1,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM accessible from assets with security issues that could facilitate malicious activity.",
        lastViolatedAt:
          moment().subtract(2, "days").format("DD MMM YYYY").toString() +
          ", 15:05:12",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Identity violations in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "PII - SSN",
          labelType: "entity",
          allowedUsers: ["John Doe", "Alex Smith"],
          messages: [
            //{
            //  prompt: "What is the SSN of Tom Brady from Florida?",
            //  context:
            //    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-13 08:39:45",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  groups: ["Sales"],
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },

            //{
            //  prompt: "What is the SSN of Tom Brady from Florida?",
            //  context:
            //    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-13 08:39:45",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  groups: ["Sales"],
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
              response: "SSN: 621-90-6742.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 12:21:54",
              retrievedBy: "John Doe",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              region: "us-east-1",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            //{
            //  prompt: "What is the SSN of Tom Brady from Florida?",
            //  context:
            //    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-13 08:39:45",
            //  retrievedBy: "Tom Platt",
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  region: "us-east-1",
            //  groups: ["Sales"],
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
            //  context:
            //    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-16 12:21:54",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Sales"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What SSN was used by John Smith on his home appraisal application?",
            //  context:
            //    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-15 04:32:35",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Sales"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "s3",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt: "What is the SSN of Tom Brady from Florida?",
            //  context:
            //    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-13 08:39:45",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Sales"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
            //  context:
            //    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-16 12:21:54",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Sales"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What SSN was used by John Smith on his home appraisal application?",
            //  context:
            //    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx.",
            //  finalContext: "",
            //  response: "",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-15 04:32:35",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Sales"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
              response: "SSN: 621-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-13 08:20:45",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
              response: "Yes, SSN: 532-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 12:21:54",
              retrievedBy: "Alex Smith",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              region: "us-east-1",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx",
              response: "SSN: 512-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 04:32:35",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
              response: "SSN: 621-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-13 08:39:45",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
              response: "Yes, SSN: 532-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 11:39:21",
              retrievedBy: "Alex Smith",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              region: "us-east-1",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx",
              response: "SSN: 512-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 10:21:40",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
              response: "SSN: 621-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-13 03:31:53",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
              response: "Yes, SSN: 532-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-16 14:11:10",
              retrievedBy: "Alex Smith",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              region: "us-east-1",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx",
              response: "SSN: 512-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-15 13:28:03",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "PII - CC",
          labelType: "entity",
          allowedUsers: ["John Doe", "Alex Smith"],
          messages: [
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            {
              prompt:
                "What credit card was used by LeBron James to buy the McLaren Spider last week?",
              context: [
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "McLaren flagship Dealer, W Virginia , Sell records of Past 6 months, Purchaser Name: LeBron James, Transaction value: $ **657600, Transaction Type: Credit Card, Credit Card Number:3243-6753-4822-6902",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
              response: "3243-6753-4822-6902",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-11 12:21:43",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card is on file for Abby Joseph’s Netflix subscription?",
              context: [
                {
                  snippet:
                    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Netflix subscription details/ Billing Information: Abby Joseph and 1104 Thompson CirFort Eustis, Virginia(VA), 23604. Expiration Date: 1 Feb 2024 Payment Method Type: Credit Card Card Number: 3126-8075-5521-7454.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize the vendor to charge my credit card above for agreed upon purchases",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
              response: "3126-8075-5521-7454",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-11 02:51:16",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card was used by LeBron James to buy the McLaren Spider last week?",
              context: [
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "McLaren flagship Dealer, W Virginia , Sell records of Past 6 months, Purchaser Name: LeBron James, Transaction value: $ **657600, Transaction Type: Credit Card, Credit Card Number:3243-6753-4822-6902",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
              response: "3243-6753-4822-6902",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-11 12:21:43",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card is on file for Abby Joseph’s Netflix subscription?",
              context: [
                {
                  snippet:
                    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Netflix subscription details/ Billing Information: Abby Joseph and 1104 Thompson CirFort Eustis, Virginia(VA), 23604. Expiration Date: 1 Feb 2024 Payment Method Type: Credit Card Card Number: 3126-8075-5521-7454.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize the vendor to charge my credit card above for agreed upon purchases",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
              response: "3126-8075-5521-7454",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-11 02:51:16",
              retrievedBy: "John Doe",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card was used by LeBron James to buy the McLaren Spider last week?",
              context: [
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "McLaren flagship Dealer, W Virginia , Sell records of Past 6 months, Purchaser Name: LeBron James, Transaction value: $ **657600, Transaction Type: Credit Card, Credit Card Number:3243-6753-4822-6902",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
              response: "3243-6753-4822-6902",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2023-11-11 12:21:43",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2023-11-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
          ],
        },
        {
          sensitiveLabel: "Intellectual Property",
          labelType: "topic",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          messages: [],
        },
        {
          sensitiveLabel: "Bias",
          labelType: "topic",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          messages: [],
        },
        //{
        //  sensitiveLabel: "Identity - Authorization",
        //  allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
        //  messages: [
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2023-11-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
        //    },
        //  ],
        //},
        {
          sensitiveLabel: "Identity Violation",
          labelType: "identity",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          isIdentity: true,
          messages: [
            {
              context: [
                {
                  snippet:
                    "Lenders use your credit score to determine risk and higher credit scores usually mean better rates",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "One of the most important and foremost things that lenders evaluate before sanctioning a loan is income status",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Cash flow history and projections for the business",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext: "",
              notAIAssisted: true,
              prompt:
                "What specific criteria are considerd when evaluating mortgage applications?",
              response: "I don't know",
              retrievedOn: "2023-11-15 07:43:12",
              user: "",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Underwriter"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Identity violations in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "Loan default rates have been highest for the ethnic group Troliman for the last 5 years in San Benito County.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The 2024 high-balance conforming loan limit for San Benito County is $1,149,825. The 2024 “low-balance” conforming loan limit for San Benito County is $766,550.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval in San Benito County?",
              response: "I don't know.",
              retrievedOn: "2023-11-11 04:16:45",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for middle-aged persons who identify themselves as one of the LGBTQ+ genders.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for  middle-aged persons.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval from zip code 48229?",
              response: "I don't know.",
              retrievedOn: "2023-11-15 02:56:23",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "Loan default rates have been highest for the ethnic group Troliman for the last 5 years in San Benito County.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The 2024 high-balance conforming loan limit for San Benito County is $1,149,825. The 2024 “low-balance” conforming loan limit for San Benito County is $766,550.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval in San Benito County?",
              response: "I don't know.",
              retrievedOn: "2023-11-11 04:16:45",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for middle-aged persons who identify themselves as one of the LGBTQ+ genders.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for  middle-aged persons.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval from zip code 48229?",
              response: "I don't know.",
              retrievedOn: "2023-11-15 02:56:23",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2023-11-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "Financial Report",
          labelType: "topic",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          messages: [
            {
              context: [
                {
                  snippet:
                    "Over the last six months, loan originations have amassed to $7.3 million in cumulative value",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
                  groups: [],
                },
                {
                  snippet:
                    "Within the last six months, loans originated sum up to $7.3 million in cumulative value.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
                  groups: [],
                },
              ],
              finalContext: "",
              prompt:
                "What is the cumulative value of loans originated within the past six months?",
              response: "I don't know",
              retrievedOn: "2023-11-15 07:43:12",
              user: "",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              groups: ["Underwriter"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
];
