import Jira from "../../../assets/img/integrations/jira.svg";
import Okta from "../../../assets/img/integrations/okta.svg";
import ServiceNow from "../../../assets/img/integrations/servicenow.svg";
import Wiz from "../../../assets/img/integrations/wiz-1.svg";
import * as constants from "../../../utils/Constants";
import Slack from "../../../assets/img/integrations/slack.svg";
import Splunk from "../../../assets/img/integrations/splunk.svg";
import AzureAD from "../../../assets/img/azure-ad.svg";
import Card from "./card";
import "./style.scss";
import { useEffect, useState } from "react";

const IntegrationCards = ({ handleModal, oktaIntegrations }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const cards_ = [
      {
        img: Splunk,
        className: "cursor-default",
        type: "splunk",
      },
      {
        img: Jira,
        className: "cursor-pointer",
        type: "jira",
      },
      {
        img: Okta,
        className: oktaIntegrations.ProviderName
          ? "cursor-default"
          : "cursor-pointer",
        type: "okta",
      },
      {
        img: ServiceNow,
        className: "cursor-default",
        type: "servicenow",
      },
      {
        img: Slack,
        className: "cursor-pointer",
        type: "slack",
      },
      {
        img: AzureAD,
        className: "cursor-pointer",
        type: "azure",
      },
    ];
    if (constants.REACT_APP_SHOW_WIZ_INTEGRATION === "YES") {
      //cards_.push({
      //  img: Wiz,
      //  className: "cursor-pointer",
      //  type: "wiz",
      //});
      setCards(cards_);
    } else {
      setCards(cards_);
    }
  }, []);

  return (
    <div class="row integration-cards">
      <div class="col-lg-12">
        <div class="row">
          {cards.map((card, index) => (
            <Card
              img={card.img}
              type={card.type}
              key={index}
              className={card.className}
              handleModal={handleModal}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntegrationCards;
