import { useEffect } from "react";
import DaxaLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import DaxaTitle from "../../assets/img/daxa-assets/daxa-title.svg";

const VerifyEmail = () => {
  useEffect(() => {
    document.title = "Daxa AI - Registration Complete";
  }, []);
  return (
    <>
      <section className="sign-in-page">
        <div className="container p-0">
          <div className="logo-container">
            <div>
              <img
                src={DaxaLogo}
                alt="Daxa icon"
                className="daxa-logo"
                height={48}
              />
            </div>
            <div>
              <img
                src={DaxaTitle}
                alt="Daxa title"
                className="daxa-title"
                width={88}
              />
            </div>
          </div>
        </div>
        <h1 className="text-center text-primary mb-5">
          Thanks for your request. <br />
          We will notify you over email once your account is ready to use.
        </h1>
      </section>
    </>
  );
};

export default VerifyEmail;
