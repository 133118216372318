import { useTheme } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import MUIButton from "@mui/material/Button";

const Button = (props: ButtonProps) => {
  const theme = useTheme();
  const styles = {
    muiButton: {
      "&.MuiButton-root": {
        textTransform: "none",
      },
      "&.MuiButton-containedPrimary": {
        backgroundColor: theme?.palette?.primaryBlue?.main || "#2195FF",
        color: theme?.palette?.surface100?.main || "#5CB1FF",
      },
      "&.MuiButton-containedPrimary:hover": {
        backgroundColor: "#0074E0",
      },
      "&.MuiButton-outlinedPrimary": {
        border: `1px solid ${theme?.palette?.primaryBlue?.main || "#2195FF"}`,
        color: theme?.palette?.primaryBlue?.main || "#2195FF",
      },
      "&.MuiButton-outlinedPrimary:hover": {
        backgroundColor: "rgba(33, 149, 255, 0.1)",
        color: theme?.palette?.primaryBlue?.main || "#2195FF",
      },
      "&.MuiButton-outlinedError": {
        color: theme?.palette?.critical?.main || "#FF655B",
        border: `1px solid ${theme?.palette?.critical?.main || "#FF655B"}`,
      },
      "&.MuiButton-containedError": {
        background: theme?.palette?.critical?.main || "#FF655B",
      },
    },
  };
  return <MUIButton sx={styles.muiButton} {...props} />;
};

export default Button;
