import { useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import JiraIcon from "../../../assets/img/jira.svg";
import SlackIcon from "../../../assets/img/slack.svg";
import MoreVert from "@mui/icons-material/MoreVert";
import TextButton from "../../common/TextButton";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import Divider from "../../common/Divider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import Checkbox from "../../common/Checkbox";
import Chip from "../../common/Chip";
import DatastoreTicketModal from "../../modal/datastore-ticket-modal/DatastoreTicketModal";
import { TextField } from "../../common/TextField";
import Tooltip from "../../common/Tooltip";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import SuggestLabelDialog from "../LLMModel/SuggestLabelDialog";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import FlagIcon from "@mui/icons-material/Flag";
import DiscardLabelDialog from "../DiscardLabelDialog";
import ViewMoreBlock from "../../common/ViewMoreBlock";

const NUM_OF_DEFAULT_CHARACTERS = 250;

const MessageDetailBlock = ({ data, index }) => {
  const theme = useTheme();
  const styles = {
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.25),
    },
    messageCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    messageFontSize: {
      fontSize: "13px",
    },
    dateText: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    messageData: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    tableCellAction: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    originChain: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.25),
      alignItems: "start",
    },
    policyChip: {
      display: "flex",
      width: "min-content",
      // backgroundColor: "rgba(255, 101, 91, 0.20)",
      backgroundColor: theme.palette.surface10.main,
      border: `1px solid ${theme.palette.warning.main}`,
      borderRadius: theme.spacing(0.5),
    },
  };
  const [showOrigin, setShowOrigin] = useState(false);
  const dateString = moment(data.retrievedOn).format("YYYY-MM-DD");
  const timeString = moment(data.retrievedOn).format("HH:mm:ss");

  return (
    <Box sx={styles.messageContainer}>
      <Box sx={styles.messageCard}>
        <Box sx={styles.messageData}>
          {/*<Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Snippet
            </Typography>
            <Typography variant="caption" color={theme.palette.surface70.main}>
              {data.prompt}
            </Typography>
          </Box>*/}
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Snippet
            </Typography>
            <ViewMoreBlock
              value={
                typeof data.context === "string"
                  ? data?.context
                  : data?.context[0]?.snippet
              }
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          {data?.groups && (
            <Box sx={styles.keyValue}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Authorized Groups
              </Typography>

              <ViewMoreBlock
                value={data?.groups?.join(" ")}
                length={NUM_OF_DEFAULT_CHARACTERS}
              />
            </Box>
          )}
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Retrieved From
            </Typography>

            <ViewMoreBlock
              value={data?.sourceFile}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          {data.policiesViolated && data.policiesViolated.length > 0 && (
            <Box sx={styles.keyValue}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Findings
              </Typography>
              <Chip
                sx={styles.policyChip}
                label={
                  <Typography
                    variant="caption"
                    color={theme.palette.surface70.main}
                  >
                    Warning: {data.policiesViolated.join(" ")}
                  </Typography>
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const SnippetDetails = ({
  messagesList = [],
  frameworkData = {},
  selectedSensitiveLabel = "",
  sensitiveData,
}: {
  frameworkData: any;
  messagesList: any;
  selectedSensitiveLabel: string;
  sensitiveData: any;
}) => {
  const [ticketModal, setTicketModal] = useState(false);
  const [globalTicketModal, setGlobalTicketModal] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState({});
  const [sensitiveLabelCount, setSensitiveLabelCount] = useState(0);
  const [messageList, setMessageList] = useState<any>([]);
  const [openSuggestLabelDialog, setOpenSuggestLabelDialog] = useState(null);
  const [isDiscardLabelDialog, setIsDiscardLabelDialog] = useState(null);
  const [addedFeedback, setAddedFeedback] = useState<
    Array<{
      context: string;
      inAccurateLabel: string;
    }>
  >([]);
  const theme = useTheme();

  const styles = {
    messagePanel: {
      borderRadius: theme.spacing(0.5),
      height: "400px",
      overflow: "hidden",
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "16px" },
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface0.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(1.25),
        zIndex: 10,
      },
    },
    checkboxCell: {
      verticalAlign: "top",
    },
    tableCellAction: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    bulkActions: {
      display: "flex",
      gap: theme.spacing(1),
    },
    actionImg: {
      height: "16px",
    },
    actionsCell: {
      display: "flex",
      gap: theme.spacing(1),
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableHeight: {
      height: "100%",
    },
    modalBody: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    dividerHeight: { height: "13px" },
    thumbsIcon: {
      color: theme.palette.surface30.main,
      height: "20px",
      width: "20px",
    },
    thumbsIconBox: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  };

  const labels = [
    "Bias",
    "Product Roadmap",
    "Intellectual Property",
    "Medical Advice",
    "Financial Report",
    "Board Meeting Notes",
    "HR - Salary",
  ];

  const buildModalData = (item) => {
    if (globalTicketModal) {
      return `${
        frameworkData?.frameworkSummary?.name
      }\n\nFramework details:\nName: ${
        frameworkData?.frameworkSummary?.family
      }\nVersion: ${
        frameworkData?.frameworkSummary?.version
      }\nVectorDB Location: ${
        frameworkData?.frameworkSummary?.location
      }\nEmbedding Model: ${
        frameworkData?.frameworkSummary?.embeddingModel
      }\n\nInstance details:\nAccount ID: ${
        frameworkData?.providerInfo?.aws?.accountId
      }\nInstance ID: ${
        frameworkData?.providerInfo?.aws?.instanceId
      }\nLabels: -\nRuntime: ${
        frameworkData?.instanceDetails?.runtime
      }\nHost: ${frameworkData?.instanceDetails?.host}\nPath: ${
        frameworkData?.instanceDetails?.path
      }\nIP: ${frameworkData?.instanceDetails?.ip}\nLanguage: ${
        frameworkData?.instanceDetails?.language
      }\n\nNumber of sensitive labels: ${sensitiveLabelCount}\n\nSnippet: \n${
        item?.context
      }\nRetrieved From: \n${
        item?.sourceFile
      }\nFindings: \nWarning:${item?.policiesViolated.join(" ")}`;
    }
    return `Snippet: \n${
      item?.context && item?.context?.length > 0
        ? item?.context[0]?.snippet
        : item?.context || "-"
    }\n\nRetrieved From: \n${
      item?.sourceFile
    }\n\nFindings: \nWarning:${item?.policiesViolated.join(" ")}`;
  };

  useEffect(() => {
    setAddedFeedback(
      JSON.parse(localStorage.getItem("DATA_SOURCE_FEEDBACK") || "[]")
    );
  }, [localStorage.getItem("DATA_SOURCE_FEEDBACK")]);

  useEffect(() => {
    setMessageList(messagesList);
  }, [messagesList]);

  useEffect(() => {
    if (selectedSensitiveLabel) {
      const count = sensitiveData?.find(
        (item) => item?.sensitiveLabel === selectedSensitiveLabel
      )?.messages?.length;
      setSensitiveLabelCount(count);
    }
  }, [selectedSensitiveLabel]);

  const handleAddFeedback = (val: string) => {
    const preAddedValues = JSON.parse(
      localStorage.getItem("DATA_SOURCE_FEEDBACK") || "[]"
    );
    let isPromptExist;
    if (preAddedValues?.length) {
      isPromptExist = preAddedValues?.filter(
        (item) => item?.context !== openSuggestLabelDialog
      );
      isPromptExist = [
        ...isPromptExist,
        {
          context: openSuggestLabelDialog,
          inAccurateLabel: val,
        },
      ];
    } else {
      isPromptExist = [
        {
          context: openSuggestLabelDialog,
          inAccurateLabel: val,
        },
      ];
    }
    localStorage.setItem("DATA_SOURCE_FEEDBACK", JSON.stringify(isPromptExist));
    handleCloseFeedbackDialog();
    Toaster(
      TOASTER_TYPES.SUCCESS,
      `You have successfully ${
        preAddedValues?.length === isPromptExist?.length ? "updated" : "added"
      } label!!`
    );
  };

  const handleAddFeedbackDialog = (isReported, prompt) =>
    isReported
      ? setIsDiscardLabelDialog(prompt)
      : setOpenSuggestLabelDialog(prompt);

  const handleCloseFeedbackDialog = () => setOpenSuggestLabelDialog(null);

  const getIsReported = (prompt) => {
    if (addedFeedback?.length) {
      const isPromptExist = addedFeedback?.find(
        (item) => item?.context === prompt
      );
      if (isPromptExist) {
        return `You Suggested new label as ‘${isPromptExist?.inAccurateLabel}’`;
      }
    }
    return false;
  };

  const handleDiscardFeedback = () => {
    const preAddedValues = JSON.parse(
      localStorage.getItem("DATA_SOURCE_FEEDBACK") || "[]"
    );
    const isPromptExist = preAddedValues?.filter(
      (item) => item?.context !== isDiscardLabelDialog
    );
    localStorage.setItem("DATA_SOURCE_FEEDBACK", JSON.stringify(isPromptExist));
    handleCloseDiscardFeedback();
    Toaster(TOASTER_TYPES.SUCCESS, "You have successfully discarded label!!");
  };

  const handleEditFeedback = () => {
    setOpenSuggestLabelDialog(isDiscardLabelDialog);
    handleCloseDiscardFeedback();
  };

  const handleCloseDiscardFeedback = () => setIsDiscardLabelDialog(null);

  return (
    <Box sx={styles.messagePanel}>
      <TableContainer
        sx={{
          height: "400px",
        }}
      >
        <Table sx={styles.tableHeight}>
          <TableHead sx={styles.tableHeader}>
            <TableRow>
              <TableCell>
                <Checkbox label="selectAll" sx={styles.checkbox} />
              </TableCell>
              <TableCell>
                <Box sx={styles.bulkActions}>
                  <Divider orientation="vertical" flexItem />
                  <TextButton
                    startIcon={
                      <img
                        src={JiraIcon}
                        alt="slack"
                        style={styles.actionImg}
                      />
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      setGlobalTicketModal(true);
                      setTicketTitle(selectedSensitiveLabel);
                      setTicketDescription({
                        ...frameworkData,
                        ...messageList[0],
                      });
                      setTicketModal(true);
                    }}
                    disabled={true}
                    sx={styles.tableCellAction}
                  >
                    Open Jira Ticket
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                  <TextButton
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    startIcon={
                      <img
                        src={SlackIcon}
                        alt="slack"
                        style={styles.actionImg}
                      />
                    }
                    disabled={true}
                    sx={styles.tableCellAction}
                  >
                    Notify on Slack
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                  <TextButton>
                    <MoreVert fontSize="small" />
                  </TextButton>
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messageList?.map((message, index) => {
              const tooltipMessage =
                getIsReported(message?.context) || "Report As Inaccurate Label";
              const isReported = getIsReported(message?.context);
              return (
                <TableRow>
                  <TableCell sx={styles.checkboxCell}>
                    <Checkbox label={message?.msgText} sx={styles.checkbox} />
                  </TableCell>
                  <TableCell>
                    <MessageDetailBlock data={message} index={index} />
                  </TableCell>
                  <TableCell sx={styles.tableHeight}>
                    <Box sx={styles.actionsCell}>
                      <TextButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setTicketTitle(message?.prompt);
                          setTicketDescription(message);
                          setTicketModal(true);
                        }}
                        startIcon={<img src={JiraIcon} alt="slack" />}
                        sx={styles.tableCellAction}
                      >
                        Open Ticket
                      </TextButton>
                      <Divider
                        orientation="vertical"
                        sx={styles.dividerHeight}
                      />
                      <Box sx={styles.thumbsIconBox}>
                        <Tooltip title={tooltipMessage}>
                          <div>
                            <TextButton
                              onClick={() =>
                                handleAddFeedbackDialog(
                                  isReported,
                                  message?.context
                                )
                              }
                              startIcon={
                                isReported ? <FlagIcon /> : <FlagOutlinedIcon />
                              }
                            >
                              {isReported ? "Reported" : "Report"}
                            </TextButton>
                          </div>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {ticketModal && (
        <DatastoreTicketModal
          handleModal={() => {
            setTicketModal(false);
            setGlobalTicketModal(false);
          }}
        >
          <Box sx={styles.modalBody}>
            <Box sx={styles.modalContent}>
              <Typography
                variant="caption"
                color={theme.palette.surface60.main}
              >
                Ticket Name: <span className="mandatory-icon">*</span>
              </Typography>
              <TextField fullWidth value={ticketTitle} />
            </Box>
            <Box sx={styles.modalContent}>
              <Typography
                variant="caption"
                color={theme.palette.surface60.main}
              >
                Description:
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={10}
                rows={10}
                value={buildModalData(ticketDescription)}
              />
            </Box>
          </Box>
        </DatastoreTicketModal>
      )}
      <SuggestLabelDialog
        open={openSuggestLabelDialog}
        labels={labels}
        onClose={handleCloseFeedbackDialog}
        onSubmit={handleAddFeedback}
        value={
          addedFeedback?.find(
            (item) => item?.context === openSuggestLabelDialog
          )?.inAccurateLabel || ""
        }
      />
      <DiscardLabelDialog
        open={isDiscardLabelDialog}
        onClose={handleCloseDiscardFeedback}
        onEdit={handleEditFeedback}
        onDiscard={handleDiscardFeedback}
      />
    </Box>
  );
};

export default SnippetDetails;
