import { useEffect } from "react";
import Index from "../../components/tickets/index";

const Tickets = ({ setTicket2 }) => {
  setTicket2(false);

  useEffect(() => {
    document.title = "Daxa AI - Tickets";
  }, []);

  return (
    <>
      <Index />
    </>
  );
};

export default Tickets;
