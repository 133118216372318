const Card = ({ img, type, className, handleModal }) => {
  return (
    <div
      className={`card-item col-6 col-sm-4 col-md-3 col-lg-2 ${className}`}
      onClick={() =>
        className.includes("pointer") ? handleModal(true, type) : null
      }
    >
      <div
        class={`card iq-mb-2 border-success header-risk-card ${
          type === "wiz" ? "wiz-background" : ""
        } ${type === "azure" ? "card-azure" : ""}`}
      >
        <div class="card-body text-primary card-body-small">
          <img alt="" src={img} />
        </div>
      </div>
    </div>
  );
};

export default Card;
