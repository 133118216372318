import React, { useEffect } from "react";
import AssetsComponent from "../../components/cloud/assets";

const Assets = ({ setPath, path, selectedAccount }) => {
  useEffect(() => {
    document.title = "Daxa AI - Assets";

    setPath(window.location.pathname);
  }, []);

  return (
    <div>
      <AssetsComponent path={path} selectedAccount={selectedAccount} />
    </div>
  );
};

export default Assets;
