import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { CardWrapper } from "../CardWrapper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import TextButton from "../../common/TextButton";
import Divider from "../../common/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import DatastoreTicketModal from "../../modal/datastore-ticket-modal/DatastoreTicketModal";
import JiraIcon from "../../../assets/img/jira.svg";
import { useHistory } from "react-router-dom";
import Bar from "../../common/Bar";

export interface Props {
  barColor: string;
  dataDistribution: Array<{
    dataType: string;
    numberOfRecords: number;
    totalValue: number;
    impacted: number;
  }>;
  isFetchingData: boolean;
}

export const PolicyViolationDetailsCard = ({
  policyViolationDetails,
  ticketIntegration,
  refreshPolicies,
}: {
  policyViolationDetails: any;
  ticketIntegration?: any;
  refreshPolicies?: any;
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketModalInfo, setTicketModalInfo] = useState<any>({});
  const styles = {
    policyViolationDetailsCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    moreIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface30.main,
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightLight,
      },
    },
    tableRow: {
      "&:last-child td, &:last-child th": { border: 0 },
    },
    tableBody: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      verticalAlign: "middle",
    },
    barCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "80%",
    },
  };

  const redirectTo = (policy) => {
    sessionStorage.setItem(
      "policy",
      JSON.stringify({
        id: policy?.policyViolationId,
        title: policy?.policyViolationName,
        ...policy,
      })
    );
    history.push(`/datastore/policy-violation/${policy?.policyViolationId}`);
  };

  return (
    <CardWrapper>
      <Box sx={styles.policyViolationDetailsCard}>
        <Typography variant="body2">Policy Violations</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} sx={styles.tableHeader}>
                  Policy Names
                </TableCell>
                <TableCell sx={styles.tableHeader}>
                  Sensitive Message Count
                </TableCell>
                <TableCell sx={styles.tableHeader}>Last Violated</TableCell>
                <TableCell sx={styles.tableHeader}>Ticket Status</TableCell>
                <TableCell sx={styles.tableHeader}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policyViolationDetails?.length > 0 &&
                policyViolationDetails?.map((item) => (
                  <TableRow
                    onClick={() => redirectTo(item)}
                    sx={styles.tableRow}
                  >
                    <TableCell colSpan={2} sx={styles.tableBody}>
                      {item?.policyViolationName}
                    </TableCell>
                    <TableCell sx={{ ...styles.tableBody, ...styles.barCell }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface80.main}
                      >
                        {item.count}
                      </Typography>
                      <Bar
                        isLastBar={false}
                        xLabel={""}
                        barBackColor={theme.palette.surface20.main}
                        barFaceColor={theme.palette.lightMagenta.main}
                        rectWidth={(item?.count / 100) * 100}
                        index={3}
                        totalValue={4}
                        barLabel={""}
                        barValue={item?.count}
                        type={""}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableBody}>
                      {item?.lastViolatedAt}
                    </TableCell>
                    <TableCell sx={styles.tableBody}>
                      {item?.ticket_exist ? "Created" : "Not Created"}
                    </TableCell>
                    <TableCell sx={styles.tableBody} width="15%">
                      <Box sx={styles.tableCellLast}>
                        <Box>
                          {item && item.tickets > 0 ? (
                            <TextButton
                              startIcon={<img src={JiraIcon} alt="jira" />}
                              sx={styles.tableCellAction}
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(item?.url ? item?.url : "#");
                              }}
                            >
                              View Ticket
                            </TextButton>
                          ) : (
                            <TextButton
                              startIcon={<img src={JiraIcon} alt="jira" />}
                              sx={styles.tableCellAction}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (ticketIntegration) {
                                  setTicketModal(true);
                                  setTicketModalInfo({
                                    policyViolationId: item.policyViolationId,
                                    datastoreId: item.datastoreId,
                                  });
                                }
                              }}
                            >
                              Create Ticket
                            </TextButton>
                          )}
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={styles.tableActionDivider}
                        />
                        <MoreVertIcon sx={styles.moreIcon} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {ticketModal && (
        <DatastoreTicketModal
          type="policy"
          refreshDatastores={refreshPolicies}
          datastoreId={ticketModalInfo.datastoreId}
          policyViolationId={ticketModalInfo.policyViolationId}
          handleModal={() => {
            setTicketModal(false);
          }}
        />
      )}
    </CardWrapper>
  );
};
