import MUIFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import MUIFormControl, { FormControlProps } from "@mui/material/FormControl";
import MUIInputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import { useTheme } from "@mui/material";

export const FormControl = (props: FormControlProps) => {
  return <MUIFormControl {...props} />;
};

export const InputLabel = (props: InputLabelProps) => {
  return <MUIInputLabel {...props} />;
};

export const FormControlLabel = (props: FormControlLabelProps) => {
  const theme = useTheme();
  const styles = {
    formControlLabel: {
      color: theme.palette.surface100.main,
    },
  };
  return <MUIFormControlLabel sx={styles.formControlLabel} {...props} />;
};
