import { useTheme } from "@mui/material";
import { totalRetrievals } from "../../mock-data/frameworkData";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";

const SensitiveDataCard = () => {
  const theme = useTheme();
  const styles = {
    cardContainer: {
      minWidth: "30%",
      width: "30%",
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
    },
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    countOfLLMs: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "120px",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    userGroups: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
  };

  const shadowData = [
    {
      user: "John Doe",
      groups: "Underwriter",
      prompts: 15,
      applications: 2,
    },
    {
      user: "Tom Platt",
      groups: "Sales",
      prompts: 12,
      applications: 1,
    },
    {
      user: "Alex Smith",
      groups: "Customer Care",
      prompts: 10,
      applications: 1,
    },
  ];

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.cardHeader}>
        <Typography color={theme.palette.surface80.main} sx={styles.cardTitle}>
          Active Users
        </Typography>
        <Typography
          color={theme.palette.surface80.main}
          variant="caption"
          sx={styles.fontMedium}
        >
          Top Active Users
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Users</TableCell>
              <TableCell>Retrievals</TableCell>
              <TableCell>Applications</TableCell>
            </TableHead>
            <TableBody>
              {shadowData.map((dataItem) => (
                <TableRow>
                  <TableCell>
                    <Box sx={styles.userGroups}>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {dataItem?.user}
                      </Typography>
                      <Typography
                        variant="tooltip"
                        color={theme.palette.surface40.main}
                      >
                        {dataItem?.groups}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell sx={styles.countOfLLMs}>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.prompts}
                    </Typography>
                    <Bar
                      isLastBar={false}
                      xLabel={""}
                      barBackColor={theme.palette.surface20.main}
                      barFaceColor={theme.palette.lightMagenta.main}
                      rectWidth={(dataItem?.prompts / totalRetrievals) * 100}
                      index={dataItem?.prompts}
                      totalValue={4}
                      barLabel={""}
                      barValue={dataItem?.prompts}
                      type={""}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="caption"
                      color={theme.palette.surface60.main}
                    >
                      {dataItem?.applications}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SensitiveDataCard;
